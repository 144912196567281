define('components/dossier/title/directive',[
    'angular',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Title', [
            'Twensoc.Config',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbTitle', [
        'config',
        function(config) {
            return {
                restrict: "E",
                scope: {
                    Content: '@content'
                },
                templateUrl: "app/components/dossier/title/title.html",
                link: function($scope, elem, attr) {
                    $scope.Config = config;

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    $scope.$on('$destroy', function() {
                    });
                }
            };
        }
    ]);
});
