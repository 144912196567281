define('components/company-styling/directive',[
	'angular',
	'model/dossier-resource',
	'model/company-resource'
], function (ng) {
	'use strict';

	return ng.module(
		'NabesFront.components.companyStyling', [
			'Twensoc.Config',
			'DossierResource',
			'CompanyResource'
		]
	).directive('companyStyling', [
		'config',
		'DossierResource',
		'CompanyResource',
		'$timeout',
		function (config, DossierResource, CompanyResource, $timeout) {
			return {
				restrict: "E",
				link: function ($scope, elem, attr) {
					$scope.Config = config;
					$scope.Dossier = null;
					$scope.CompanyStyleTag = document.createElement("style");
					document.body.appendChild($scope.CompanyStyleTag);

					var activeChangeWatcher = $scope.$watch(function () {
						return DossierResource.getActive();
					}, function (dossier) {
						$scope.CompanyStyleTag.innerHTML = "";
						console.log("change");
						if (dossier == null) return;

						console.log("StylingDirective");
						$scope.Dossier = dossier && dossier.dossier ? dossier.dossier : null;

						CompanyResource.getStyling($scope.Dossier.companyId).then(function (companyStyling) {
							if (companyStyling == null) return;
							$scope.CompanyStyleTag.innerHTML = companyStyling;
						}).catch(function (error) {
							console.error(error);
						});

						// $scope.CompanyStyleTag.innerHTML = "body { background: red; }";

						// $timeout(function() {
						// 	$scope.CompanyStyleTag.innerHTML = "body { background: yellow; }";
						// }, 5000);
					});


					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					$scope.$on('$destroy', function () {
						activeChangeWatcher();
						$scope.Dossier = null;
						$scope.Config = null;
					});
				}
			};
		}
	]);
});
