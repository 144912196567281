/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 27-7-2015
 * Time: 10:37
 * For Project: nabes-front
 * @version : 0.1
 */

define('components/dossier/block/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Block', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbBlock', [
        'config',
        'Authentication',
        '$uiViewScroll',
        '$rootScope',
        function(config, Authentication, $uiViewScroll, $rootScope) {
            return {
                restrict: "E",
                transclude: true,
                scope: {
                    // items: '=items',
                    State: '@state',
                    ShowIf: '=showIf',
                    EnabledIf: '=enabledIf',
                    collapsible: '@collapsible',
                    collapsed: '=collapsed',
                    title: '@title',
                    templateId: '@templateId',
                    icon: '@icon',
                    id: '@id'
                },
                templateUrl: "app/components/dossier/block/block.html",
                controller: [
                    '$scope',
                    '$element',
                    '$attrs',
                    function(scope, elem, attr) {
                        scope.Config = config;
                        scope.showIfText = attr.showIf;
                        scope.showDebug = false;
                        scope.variabeleName = attr.value;
                        scope.hideBlock = attr.collapsed;
                        scope.showDebugToggle = false;
                        if(scope.State === undefined) scope.State = 'important';
                        //scope.hideBlock = attr.collapsible === undefined ? false : true;

                        // Auto set collapsible for secondary and tertiary blocks
                        if(scope.title !== undefined && (scope.State == 'secondary' || scope.State.indexOf('tertiary')>-1)) {
                            scope.collapsible = true;
                            scope.hideBlock = true;
                        } else {
                            scope.collapsible = attr.collapsible;
                        }

                        /**
                         * Listen to required changes, specifically for the termination service when
                         * a ref is required depends on the company service to terminate.
                         * @type {any}
                         */
                        var unhideWatcher = scope.$watch("collapsed", function(value) {
                            if(scope.collapsible !== true || value == null) return;
                            // Only go from off to on. Never go back (automatically)
                            // if(scope.hideBlock != false && _.castToBoolean(value) == false)
                            scope.hideBlock = _.castToBoolean(value);
                        });

                        if(scope.id == 'more-info') {
                            $rootScope.$on('show-more-info', function() {

                                var d = angular.element(document),
                                    blocks = d.find('nb-block'),
                                    el = null;

                                for(var i=0;i<blocks.length;i++) {
                                    if(blocks[i].getAttribute('state') == 'secondary') {
                                        // Scroll to blocks[i];
                                        el = blocks[i];
                                        break;
                                    }
                                }
                                $uiViewScroll([el]);
                                scope.toggleBlock();
                            });
                        }

                        /**
                         * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                         * @param src Source scope that sends the event
                         * @param v Boolean
                         */
                        scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                            scope.showDebugToggle = v;
                        });

                        /**
                         * Show/hide the debug information for this input.
                         */
                        scope.toggleDebug = function() {
                            scope.showDebug = !scope.showDebug;
                        };

                        /**
                         * Function that toggles the state of the block to hide / show it.
                         */
                        scope.toggleBlock = function() {
                            scope.hideBlock = !scope.hideBlock;
                        };

                        /**
                         * Time for some cleaning up of listeners! this is very important!
                         */
                        scope.$on('$destroy', function() {
                            scope.showDebugToggleListener();
                            unhideWatcher();
                        });

                        /**
                         * Function that changes the state to primary, this is a util function
                         * to enable the state.
                         */
                        this.enable = function() {
                            scope.State = "primary";
                        };
                    }
                ]
                // link: function(scope, elem, attr) {
                //
                // }
            };
        }
    ]);
});
