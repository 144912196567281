/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/link/controller',[
    'angular',
    'lodash',
    'components/header/directive', //header directive
    'components/footer/directive', //footer directive
    'config'
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.LinkPageController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter',
            'angular-toasty',
            'NabesFront.collection.ToastMessagesCollection'
        ]
    ).controller(
        'LinkPageController', [
            '$state',
            '$stateParams',
            '$timeout',
            '$scope',
            '$rootScope',
            'config',
            '$http',
            'toasty',
            'toastMessages',
            function ($state, $stateParams, $timeout, $scope, $rootScope, config, $http, toasty, toastMessages) {
                $scope.Config = config;
                $rootScope.Title = "Wait... ";

                $scope.msgSend = false;
                $scope.token = $stateParams.token;

                $scope.msg = "Wait...";

                if(_.isEmpty($scope.token)) {
                    $state.go('locale.login');
                    toastMessages.add(toastMessages.T_DANGER, '', 'token.invalid', 8000, null, 500);

                } else {
                    $http.post(config.getAPIUrl('token'), {token: $scope.token}).
                        then(function(response) {
                            if(response.data) {
                                var cmd = response.data.cmd;
                                if(response.data.success === false) {
                                    $state.go('locale.login');
                                    toastMessages.add(toastMessages.T_DANGER, '', "failure."+(response.data.err||'invalidToken'), 8000, null, 500);

                                } else if(response.data.success === true) {
                                    if(cmd == 'change-email-request') {
                                        toastMessages.add(toastMessages.T_INFO, '', 'info.emailChanged', 8000, null, 500);
                                    } else {

                                        // Token handles completely

                                        toastMessages.add(toastMessages.T_INFO, '', 'info.dossierInviteAccepted', 8000, null, 500);
                                    }
                                    $state.go('locale.app.dossier');

                                } else if(response.data.cmd !== undefined) {
                                    $state.go('locale.'+response.data.cmd, {Locale: config.getUrlPrefix(), data: response.data});//token: $scope.token,
                                }
                            }
                        }, function(response) {

                        });
                }

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
