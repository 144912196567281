/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/settings/controller',[
	"angular", "lodash", "model/user", "collection/toast-messages", //toast messages collection
	"utils/authentication", // authentication
	"utils/vertx-client" // vertx client
], function (ng, _) {
	"use strict";

	return ng.module("NabesFront.controllers.AccountSettingsController", [
		"Twensoc.Config",
		"NabesFront.model.UserModel",
		"NabesFront.collection.ToastMessagesCollection",
		"DossierResource",
		"NabesFront.utils.Authentication",
		"Twensoc.utils.VertxClient"
	]).controller("AccountSettingsController", [
		"$state",
		"$stateParams",
		"$scope",
		"$rootScope",
		"config",
		"$log",
		"UserModel",
		"toastMessages",
		"DossierResource",
		"Authentication",
		"VertxClient",
		function ($state,
			$stateParams,
			$scope,
			$rootScope,
			config,
			$log,
			UserModel,
			toastMessages,
			DossierResource,
			Authentication,
			VertxClient
		) {
			$scope.Config = config;
			$rootScope.BodyClass = "settings";
			$scope.model = ng.copy(UserModel);
			$scope.model.locale = "nl_NL";
			$scope.isAdmin = UserModel.hasRole("ADMIN");

			$scope.dossiers = [];
			$scope.filter = {
				accountId: UserModel.id,
				pageNo: 1,
				totalItems: MAX_INT,
				pageSize: 100
			};

			// model.notification is an integer. Convert to string to let the select work
			$scope.model.notification = "" + $scope.model.notification;

			/**
			 * Function that checks if this input has a specific error.
			 * If this is the case the error is displayed to the user.
			 * @param {string} fieldName
			 * @param {string} errorName
			 * @returns {boolean}
			 */
			$scope.hasError = function (fieldName, errorName) {
				var value = _.getByString($scope.form[fieldName].$error, errorName);
				return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
			};

			/**
			 * User clicks on the button cancel.
			 * The user is simply send back to the overview page.
			 */
			$scope.cancel = function () {
				history.back();
			};

			/**
			 * Function that saves the records into the new model.
			 */
			$scope.ok = function (form) {
				if (form.$invalid) return;

				var newEmail = $scope.model.email;
				UserModel.set(ng.copy($scope.model));

				UserModel.save().then(function (data) {
					if (data.emailChanged === true) {
						toastMessages.add(toastMessages.T_INFO,
							"info.newEmailNeedsVerificationTitle",
							"info.newEmailNeedsVerification",
							8000,
							{email: newEmail}
						);
						delete(data.emailChanged);
					}
					UserModel.set(data);

					history.back();
					toastMessages.add(toastMessages.T_SUCCESS, "", "settingsForm.saved", 5000);
				}, function () {
					toastMessages.add(toastMessages.T_DANGER, "", "settingsForm.saveFailure", 8000);
				});
			};

			DossierResource.findAll($scope.filter, {bypassCache: true}).then(function (dossiers) {
				$scope.dossiers = dossiers;
			});

			$scope.editLink = function (dossierId, event) {
				if (event == null || event.target.closest("div.receivesNotifications") != null) return;

				if (Authentication.hasRole("BGO")) {
					$state.go("locale.app.dossier.overview.edit", {uuID: dossierId});
				} else {
					$state.go("locale.app.dossier.overview", {uuID: dossierId});
				}
			};

			$scope.changeEmailSubscription = function (dossierId, change) {
				var action = (change === 1) ? "subscribe/dossierupdate/mail"
					: "unsubscribe/dossierupdate/mail";
				VertxClient.send("/notification/settings", {
					payload: {
						data: {
							action: action,
							user: UserModel.id,
							dossier: dossierId
						}
					}
				}).then(function () {
					toastMessages.add(
						toastMessages.T_SUCCESS,
						"",
						"dossierList.sendMailChangedSuccessfully",
						4000
					);
				}, function (err) {
					toastMessages.add(toastMessages.T_DANGER, "", "failure." + err, 8000);
				});
			};

			/**
			 * Time for some cleaning up of listeners! this is very important!
			 */
			$scope.$on("$destroy", function () {
			});
		}
	]);
});
