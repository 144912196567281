/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 15:08
 * For Project: fairplay
 * @version : 0.1
 */

define('components/footer/directive',[
    'angular'
], function (ng) {
    'use strict';

    return ng.module(
        'Twensoc.components.tsFooter', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'DossierResource'
        ]
    ).directive('tsFooter', [
        'config',
        'Authentication',
        'DossierResource',
        function(config, Authentication, DossierResource) {
            return {
                restrict: "E",
                templateUrl: "app/components/footer/footer.html",
                link: function($scope, elem, attr) {
                    $scope.Config = config;
                    $scope.Dossier = null;

                    var activeChangeWatcher = $scope.$watch(function() {
                        return DossierResource.getActive();
                    }, function(v) {
                        $scope.Dossier = v && v.dossier ? v.dossier : null;
                    });

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    $scope.$on('$destroy', function() {
                        activeChangeWatcher();
                        $scope.Dossier = null;
                    });
                }
            };
        }
    ]);
});
