define('components/dossier/more-info-link/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.MoreInfoLink', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbMoreInfoLink', [
        'config',
        'Authentication',
        '$uiViewScroll',
        function(config, Authentication, $uiViewScroll) {
            return {
                restrict: "E",
                scope: {
                    dossier: '=data',
                    Name: '@name',
                    NGModelValue: '@value',
                    Label: '@label',
                    ShowIf: '=showIf'
                },
                require: '^form',
                templateUrl: "app/components/dossier/more-info-link/more-info-link.html",
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.showDebugToggle = false;

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                    });
                }
            };
        }
    ]);
});
