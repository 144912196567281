/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/event-resource',[
    'angular',
    'lodash',
    'js-data',
    'utils/vertx-client',
    'model/dossier-resource' //dossier resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'EventResource', [
            'Twensoc.Config',
            'js-data',
            'DossierResource',
            'Twensoc.utils.VertxClient'
        ]
    ).factory('EventResource', [
        '$rootScope',
        '$q',
        '$log',
        'DS',
        'DossierResource',
        '$http',
        'config',
        '$translate',
        'VertxClient',
        function($rootScope, $q, $log, DS, DossierResource, $http, config, $translate, VertxClient) {

            var EventResource = DS.defineResource({
                name:"Event",
                endpoint: '/event/',
                defaultValues: {
                    id: null,
                    dossierId: null,
                    accountId: null,
                    account_firstName: null,
                    account_infix: null,
                    account_surname: null,
                    type: '',
                    data: '',
                    state: 0,
                    selected: false
                },
                beforeInject: function (resource, obj) {
                    // Deserialize dossier.data property
                    if (_.isArray(obj)) {
                        var i = obj.length;
                        while (i--) {
                            if (_.isString(obj[i].data)) {
                                obj[i].data = JSON.parse(obj[i].data);
                            }
                        }
                    } else if (obj) {
                        if (_.isString(obj.data)) {
                            obj.data = JSON.parse(obj.data);
                        }
                    }
                },
                toState: function(dossierId, ids, targetState) {
                    var deferred = $q.defer();

                    VertxClient.send("/event/setstate", {
                        dossierId: dossierId,
                        ids: ids,
                        targetState: targetState
                    }).then(function (result) {
                        deferred.resolve(result);
                    }, function(err) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                },
                methods: {
                    /**
                     * Get the full name for this dossier.
                     * @returns {string}
                     */
                    fullName: function () {
                        return [this.firstName, this.infix, this.surname].join(" ");
                    },

                    description: function(event) {
                        event.account_fullName = [event.account_firstName, event.account_infix, event.account_surname].join(" ");
                        return $translate("EventTypes."+event.type, event);

                    }
                }
            });
            $log.info('EventResource defined');

            return EventResource;
        }
    ]);
});
