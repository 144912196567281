/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/company-resource',[
	'angular',
	'lodash',
	'js-data'
], function (ng, _) {
	'use strict';

	return ng.module(
		'CompanyResource', [
			'js-data'
		]
	).factory('CompanyResource', [
		'$rootScope',
		'$q',
		'DS',
		function ($rootScope, $q, DS) {

			var CompanyResource = DS.defineResource({
				name: "Company",
				endpoint: '/company/',
				defaultValues: {
					id: null,
					name: '',
					department: '',
					phone: '',
					email: '',
					site: '',
					v_address: '',
					v_zipcode: '',
					v_city: '',
					type: 0,
					information: '',
					siteSrc: 0,
					costPerDossier: '',
					emailLogo: '',
					styling:'',
					styling_mail:'',
					subdomain:''
				},
				hasInformation: function (companyId) {
					var company = this.get(companyId),
						deferred = $q.defer();
					if (company != null) {
						deferred.resolve(company.information != null && company.information.trim() !== '');
					} else {
						this.find(companyId).then(function (company) {
							deferred.resolve(company.information != null && company.information.trim() !== '');
						}, function (error) {
							deferred.reject(error);
						});
					}
					return deferred.promise;
				},

				getEmailLogo: function (companyId) {
					var company = this.get(companyId),
						deferred = $q.defer();
					if (company != null) {
						deferred.resolve(company.emailLogo);
					} else {
						this.find(companyId)
							.then(function (company) {

							deferred.resolve(company.emailLogo);
						}, function (error) {
							deferred.reject(error);
						});
					}
					return deferred.promise;
				},

				getStyling: function (companyId) {
					var company = this.get(companyId),
						deferred = $q.defer();
					if (company != null) {
						deferred.resolve(company.styling);
					} else {
						this.find(companyId)
							.then(function (company) {

								deferred.resolve(company.styling);
							}, function (error) {
								deferred.reject(error);
							});
					}
					return deferred.promise;
				}

			});

			return CompanyResource;
		}
	]);
});
