/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-1-2015
 * Time: 12:29
 * For Project: fairplayV2
 * @version : 0.1
 */

/**
 * The app module. This module is returned to fit with the AMD Require JS demands.
 * It could have dependencies on other modules who also all should then be returned here to.
 */
define('app',[
	'angular',
	'lodash',
	'angularSanitize',
	'angular-toasty',
	'utils/templateInterpreter',
	'config',
	'template',
	'angularAnimate',
	'angularAria',
	'collection/changes', //changes collection
	'utils/authentication', //authentication service
	'utils/websocketAdapter', //websocket adapter
	'controllers/link/controller', //link page controller
	'controllers/password-reset/controller', // Password reset controller
	'controllers/dossier-invite/controller', // Password reset controller
	'controllers/app/controller', //app page controller
	'controllers/dossier/form/controller', //app dossier form controller
	'controllers/dossier/list/controller', //dossier list controller
	'controllers/dossier/overview/controller', //dossier overview controller
	'controllers/dossier/company-info/controller', //dossier company info controller
	'controllers/dossier/eventlist/controller', //dossier overview controller
	'controllers/contact/controller',
	'controllers/dossier-template/form/controller', //app dossier template form controller
	'controllers/dossier-template/list/controller', //dossier template overview controller
	'controllers/dossier/document/controller', //dossier overview document controller
	'controllers/dossier/scratchpad/controller', //dossier scratchpad  controller
	'controllers/company/form/controller', //app member form controller
	'controllers/company/list/controller', //members overview controller
	'controllers/notificationTpl/form/controller', //app member form controller
	'controllers/notificationTpl/list/controller', //members overview controller
	'controllers/termination/form/controller',
	'controllers/termination/list/controller',
	'controllers/account/form/controller', //app member form controller
	'controllers/account/list/controller', //members overview controller
	'controllers/customer-account/list/controller', //customer overview controller
	'controllers/login/controller', //login controller
	'controllers/404/controller', //404 page controller
	'controllers/settings/controller', //account settings controller
	'controllers/messages/list/controller', //manage messages overview controller
	'components/toast-messages/directive', //toast messages directive
	'components/company-styling/directive', //company styling directive
	'controllers/terms-and-conditions/controller', //Terms and conditions controller
	'controllers/unsubscribe/controller', //unsubscribe controller
	'controllers/info/controller', //unsubscribe controller

	'model/user' //user model
], function (ng, _) {
	'use strict';

	return ng.module(
		'Twensoc', [
			'angular-toasty',
			'templates',
			'ui.router',
			'ngTouch',
			'ngAnimate',
			'ngAria',
			'ngSanitize',
			'pascalprecht.translate',
			'WebsocketAdapter',
			'TemplateInterpreter',
			'Twensoc.Config',
			'NabesFront.utils.Authentication',
			'NabesFront.model.UserModel',
			'Nabes.components.toastMessages',
			'NabesFront.components.companyStyling',
			'NabesFront.collection.ChangesCollection',
			'NabesFront.utils.Authentication',
			'NabesFront.controllers.LinkPageController',
			'NabesFront.controllers.PasswordResetController',
			'NabesFront.controllers.DossierInviteController',
			'NabesFront.controllers.ContactFormController',
			'Twensoc.controllers.AppPageController',
			'Twensoc.controllers.404PageController',
			'NabesFront.controllers.LoginController',
			'NabesFront.controllers.DossierFormController',
			'NabesFront.controllers.DossierListController',
			'NabesFront.controllers.DossierOverviewController',
			'NabesFront.controllers.DossierCompanyInfoController',
			'NabesFront.controllers.DossierEventOverviewController',
			'NabesFront.controllers.DossierTemplateFormController',
			'NabesFront.controllers.DossierTemplateOverviewController',
			'NabesFront.controllers.CompanyFormController',
			'NabesFront.controllers.CompanyOverviewController',
			'NabesFront.controllers.TerminationFormController',
			'NabesFront.controllers.TerminationOverviewController',
			'NabesFront.controllers.AccountFormController',
			'NabesFront.controllers.AccountOverviewController',
			'NabesFront.controllers.CustomerAccountOverviewController',
			'NabesFront.controllers.DossierDocumentController',
			'NabesFront.controllers.ScratchPadFormController',
			'NabesFront.controllers.AccountSettingsController',
			'NabesFront.controllers.MessagesOverviewController',
			'NabesFront.controllers.TermsAndConditionsController',
			'NabesFront.controllers.UnsubscribeController',
			'NabesFront.controllers.InfoController',
			'NabesFront.controllers.NotificationTplFormController',
			'NabesFront.controllers.NotificationTplOverviewController'
		]
	)
		.config([
			'$stateProvider',
			'$urlRouterProvider',
			'$locationProvider',
			'$translateProvider',
			'$animateProvider',
			'$httpProvider',
			// 'Authentication',
			function ($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider, $animateProvider, $httpProvider) {
				$httpProvider.defaults.withCredentials = true;

				var authentication = ['$q', '$rootScope', '$state', '$stateParams', 'Authentication',
					function ($q, $rootScope, $state, $stateParams, Authentication) {
						//user is logged in resolve immediately
						if (Authentication._isLoggedIn === true) {
							// todo check possibilities for redirection

							return $q.when(true);
						}

						// User is not logged in: send him to the login page
						if (Authentication._isLoggedIn === false || window.localStorage.token == null) return $q.reject('UNAUTHORIZED');

						var deferred = $q.defer();

						// Wait for the authentication to go through
						var handler = $rootScope.$on('Authentication', function (event, success, response, userModel) {
							if (success) {
								// todo check possibilities for redirection

								deferred.resolve(true);
							} else {
								deferred.reject('UNAUTHORIZED');
							}
							handler();
						});
						return deferred.promise;
					}
				];

				//new
				$stateProvider
					.state('locale', {
						template: "<toast-messages></toast-messages><div ui-view='locale' autoscroll='true'></div><company-styling></company-styling>",
						url: '/{Locale:(?:[a-z]{2}-[a-z]{2}|[a-z]{2})}',
						abstract: true
					})
					//404 controller
					.state('locale.404', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/404/page.html',
								controller: '404PageController'
							}
						},
						url: "/404"
					})
					// .state('locale.termsandconditions', {
					//     views: {
					//         "locale": {
					//             templateUrl: 'app/controllers/terms-and-conditions/page.html',
					//             controller: 'TermsAndConditionsController'
					//         }
					//     },
					//     url: "/algemene-voorwaarden",
					// })
					// .state('locale.disclaimer', {
					//     views: {
					//         "locale": {
					//             templateUrl: 'app/controllers/terms-and-conditions/page.html',
					//             controller: 'TermsAndConditionsController'
					//         }
					//     },
					//     url: "/disclaimer",
					// })
					// .state('locale.privacystatement', {
					//     views: {
					//         "locale": {
					//             templateUrl: 'app/controllers/terms-and-conditions/page.html',
					//             controller: 'TermsAndConditionsController'
					//         }
					//     },
					//     url: "/privacy-statement",
					// })
					// .state('locale.update', {
					//     views: {
					//         "locale": {
					//             templateUrl: 'app/controllers/terms-and-conditions/page.html',
					//             controller: 'TermsAndConditionsController'
					//         }
					//     },
					//     url: "/update",
					// })
					//login controller
					.state('locale.login', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/login/page.html',
								controller: 'LoginController'
							}
						},
						url: "/login",
						params: {
							toState: null,
							toParams: null
						}
					})
					//link page controller
					.state('locale.link', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/link/page.html',
								controller: 'LinkPageController'
							}
						},
						url: "/link/{token}",
						params: {
							token: {value: null, squash: true}
						}
					})
					//password reset controller
					.state('locale.reset-password-request', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/password-reset/page.html',
								controller: 'PasswordResetController'
							}
						},
						url: "/reset-password",
						params: {
							//token: { value: null, squash: true },
							data: {value: {token: null, formInfo: 'reset-password-request'}, squash: true}
						}
					})
					//dossier invite controller
					.state('locale.dossier-invite', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/dossier-invite/page.html',
								controller: 'DossierInviteController'
							}
						},
						url: "/dossier-invite",
						params: {
							data: {value: {token: null}, squash: true}
						}
					})
					.state('locale.app', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/app/page.html',
								controller: 'AppPageController'
							}
						},
						url: "",
						abstract: true,
						resolve: {
							authentication: authentication
						}
					})
					//account settings page
					.state('locale.app.settings', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/settings/page.html',
								controller: 'AccountSettingsController'
							}
						},
						url: "/settings"
					})
					//dossier list controller
					.state('locale.app.dossier', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/list/page.html',
								controller: 'DossierListController'
							}
						},
						url: "/?{pageNo:int}&{filter:string}&{eventState:int}&{hist:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true},
							eventState: {value: 0, squash: true},
							hist: {value: "", squash: true}
						}
					})
					//dossier overview controller
					.state('locale.app.dossier.overview', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/overview/page.html',
								controller: 'DossierOverviewController'
							}
						},
						url: "{uuID:int}"
					})
					//dossier company info controller
					.state('locale.app.dossier.overview.companyInfo', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/company-info/page.html',
								controller: 'DossierCompanyInfoController'
							}
						},
						url: "/extra-info"
					})
					//dossier overview document controller
					.state('locale.app.dossier.overview.document', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/document/page.html',
								controller: 'DossierDocumentController'
							}
						},
						url: "/document"
					})
					//dossier overview events
					.state('locale.app.dossier.overview.events', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/eventlist/page.html',
								controller: 'DossierEventOverviewController'
							}
						},
						url: "/events/"
					})
					.state('locale.app.dossier.overview.account_settings', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/settings/page.html',
								controller: 'AccountSettingsController'
							}
						},
						url: "/settings"
					})
					.state('locale.app.dossier.overview.contact', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/contact/page.html',
								controller: 'ContactFormController'
							}
						},
						url: "/contact"
					})
					.state('locale.app.dossier.overview.scratchpad', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/scratchpad/page.html',
								controller: 'ScratchPadFormController'
							}
						},
						url: "/scratchpad"
					})

					//dossier overview edit form.
					.state('locale.app.dossier.overview.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier/form/page.html',
								controller: 'DossierFormController'
							}
						},//(?!:type1).*
						url: "/{chapterSlug}/{pageSlug}",
						params: {
							chapterSlug: {squash: true},
							pageSlug: {value: null, squash: true}
						}
					})
					//dossier contact form controller
					.state('locale.app.contact', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/contact/page.html',
								controller: 'ContactFormController'
							}
						},
						url: "/contact"
					})
					//dossier template list
					.state('locale.app.dossiertemplate', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier-template/list/page.html',
								controller: 'DossierTemplateOverviewController'
							}
						},
						url: "/dossiertemplate/?{pageNo:int}&{filter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise('ADMIN');
							}]
						}
					})
					//dossier template edit
					.state('locale.app.dossiertemplate.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/dossier-template/form/page.html',
								controller: 'DossierTemplateFormController'
							}
						},
						url: "{uuID:int}"
					})
					.state('locale.app.termination', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/termination/list/page.html',
								controller: 'TerminationOverviewController'
							}
						},
						url: "/termination/?{pageNo:int}&{filter:string}&{hist:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true},
							hist: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN', 'BGO']);
							}]
						}
					})
					//company edit
					.state('locale.app.termination.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/termination/form/page.html',
								controller: 'TerminationFormController'
							}
						},
						url: "{uuID:int}"
					})
					//company list
					.state('locale.app.company', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/company/list/page.html',
								controller: 'CompanyOverviewController'
							}
						},
						url: "/company/?{pageNo:int}&{filter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN', 'BGO']);
							}]
						}
					})
					//company edit
					.state('locale.app.company.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/company/form/page.html',
								controller: 'CompanyFormController'
							}
						},
						url: "{companyId:int}"
					})
					//company account list
					.state('locale.app.company.edit.account', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/account/list/page.html',
								controller: 'AccountOverviewController'
							}
						},
						url: "/account/?{accountPageNo:int}&{accountFilter:string}",
						params: {
							accountPageNo: {value: 1, squash: true},
							accountFilter: {value: "", squash: true}
						}
					})
					//company accounts edit
					.state('locale.app.company.edit.account.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/account/form/page.html',
								controller: 'AccountFormController'
							}
						},
						url: "{accountId:int}"
					})
					//accounts list
					.state('locale.app.account', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/account/list/page.html',
								controller: 'AccountOverviewController'
							}
						},
						url: "/account/?{accountPageNo:int}&{accountFilter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN', 'BGO']);
							}]
						}
					})
					//useraccounts list
					.state('locale.app.customeraccount', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/customer-account/list/page.html',
								controller: 'CustomerAccountOverviewController'
							}
						},
						url: "/customeraccount/?{accountPageNo:int}&{accountFilter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN', 'BGO']);
							}]
						}
					})
					//account edit form
					.state('locale.app.account.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/account/form/page.html',
								controller: 'AccountFormController'
							}
						},
						url: "{accountId:int}"
					})
					// notificationTpl list
					.state('locale.app.notificationTpl', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/notificationTpl/list/page.html',
								controller: 'NotificationTplOverviewController'
							}
						},
						url: "/notificationTpl/?{pageNo:int}&{filter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN']);
							}]
						}
					})
					//notificationTpl edit
					.state('locale.app.notificationTpl.edit', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/notificationTpl/form/page.html',
								controller: 'NotificationTplFormController'
							}
						},
						url: "{uuID:int}"
					})
					//messages list
					.state('locale.app.messages', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/messages/list/page.html',
								controller: 'MessagesOverviewController'
							}
						},
						url: "/messages/?{pageNo:int}&{filter:string}",
						params: {
							pageNo: {value: 1, squash: true},
							filter: {value: "", squash: true}
						},
						resolve: {
							isAuthorized: ['Authentication', function (Authentication) {
								return Authentication.hasRolePromise(['ADMIN', 'BGO']);
							}]
						}
					})
					.state('locale.unsubscribe', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/unsubscribe/page.html',
								controller: 'UnsubscribeController'
							}
						},
						url: "/unsubscribe/{userId:string}/{dossierId:string}/{token:string}",
						params: {
							userId: {value: "", squash: false},
							dossierId: {value: "", squash: false},
							token: {value: "", squash: false}
						}
					})
					.state('locale.app.info', {
						views: {
							"main@locale.app": {
								templateUrl: 'app/controllers/info/page.html',
								controller: 'InfoController'
							}
						},
						url: "/info"
						// ,
						// resolve: {
						// 	isAuthorized: ['Authentication', function (Authentication) {
						// 		return Authentication.hasRolePromise('ADMIN','BGO');
						// 	}]
						// }
					})
					.state('locale.content', {
						views: {
							"locale": {
								templateUrl: 'app/controllers/terms-and-conditions/page.html',
								controller: 'TermsAndConditionsController'
							}
						},
						url: "/*path"
					});


				//enable html5 mode
				$locationProvider.html5Mode(true);
				//redirect / to /Locale/
				$urlRouterProvider.when('/', '/en/');
				//else go to 404
				$urlRouterProvider.otherwise('/nl/404');

				// configures staticFilesLoader
				var prefix = (templateConfig != null && templateConfig.environment !== 'dev') ? '/dist/' + templateConfig.version + '/dummy-data/' : '/dummy-data/';
				$translateProvider.useStaticFilesLoader({
					prefix: prefix,
					suffix: '.json'
				});
				$translateProvider.preferredLanguage('nl_NL');
				$translateProvider.fallbackLanguage('nl_NL');
				$translateProvider.useSanitizeValueStrategy('sanitize');
			}
		])
		.run([
			'$state',
			'$rootScope',
			'$sanitize',
			'config',
			'$translate',
			'$window',
			'ChangesCollection',
			'Authentication',
			'UserModel',
			'WebsocketAdapter',
			function ($state, $rootScope, $sanitize, config, $translate, $window, ChangesCollection, Authentication, UserModel, WebsocketAdapter) {
				$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
					if (!toParams.Locale || toParams.Locale === undefined) return;
					config.changeLanguage(config.getLanguageInDefaultFormat(toParams.Locale).DefaultFormat);

					// only reload state if the locale is not equal to the user model locale
					// or if there is a saved state to redirect to after login
					UserModel.isLoaded().then(function () {
						var chapterSlug = toParams.chapterSlug;
						var pageSlug = toParams.pageSlug;
						var locale = toParams.Locale;
						var uuID = toParams.uuID;

						var data = {
							url: chapterSlug ?
								"/" + locale + "/" + uuID + "/" + chapterSlug + (pageSlug == null ? "" : "/" + pageSlug) :
								$state.href(toState.name, toParams, {absolute: false})
						};


						console.log(data.url);

						if (data.url != null) {
							//send to the socket.
							WebsocketAdapter.send("/event/page-visit", data).then(
								function (result) {
									console.info("[send]:event/page-visit", data,result);
								},
								function (e) {
									console.error("[send]:event/page-visit", e);
								});
						}

						if (config.getLanguageInDefaultFormat(toParams.Locale).DefaultFormat === UserModel.locale.replace('_', '-')) return;
						ng.extend(toParams, {Locale: UserModel.locale.substring(0, 2)});
						console.log(toState.name);
						$state.go(toState, toParams, {reload: true});
					});
				});

				$rootScope.$on('$stateChangeError', function (e, toState, toParams, fromState, fromParams, error) {
					// If the user doesn't have access to a particular route redirect the user to the login page.
					if (error === "UNAUTHORIZED") return void $state.go('locale.login', {
						Locale: toParams.Locale,
						toState: toState,
						toParams: toParams
					}, {location: 'replace'});
				});

				$rootScope.$on('$routeChangeStart', function (evt, absNewUrl, absOldUrl) {
					$window.scrollTo(0, 0); //scroll to top of page after each route change
				});

				$rootScope.$on('$viewContentLoaded', function () {
					$window.scrollTo(0, 0);
				});
			}
		]);
});

