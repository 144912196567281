define('components/dossier/checklist/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Checklist', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbChecklist', [
        'config',
        'Authentication',
        function(config, Authentication) {
            return {
                restrict: "E",
                scope: {
                    dossier: '=data',
                    Name: '@name',
                    NGModelValue: '@value',
                    Label: '@label',
                    PlaceHolder: '@placeholder',
                    Required: '=required',
                    Explanation: '@explanation',
                    ShowIf: '=showIf',
                    RelevantIf: '=relevantIf',
                    Title: '@title',
                    ChapterSlug: '@chapterSlug',
                    PageSlug: '@pageSlug',
                    IsChecklistPage: '=',
                    alertId: '@alertId'
                },
                require: ['^form', '^nbBlock'],
                templateUrl: "app/components/dossier/checklist/checklist.html",
                link: function(scope, elem, attr, requiredCtrls) {
                    var formCtrl = requiredCtrls[0];
                    var blockCtrl = requiredCtrls[1];
                    if(scope.ShowIf === true) blockCtrl.enable();

                    scope.Config = config;
                    scope.showExplanation = false;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Show/hide an optional explanation
                     */
                    scope.toggleExplanation = function() {
                        scope.showExplanation = !scope.showExplanation;
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                    });
                }
            };
        }
    ]);
});
