/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/404/controller',[
    'angular',
    'lodash',
    'components/header/directive', //header directive
    'components/footer/directive' //footer directive
], function (ng, _) {
    'use strict';

    return ng.module(
        'Twensoc.controllers.404PageController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter'
        ]
    ).controller(
        '404PageController', [
            '$scope',
            '$rootScope',
            'config',
            function ($scope, $rootScope, config) {
                $scope.Config = config;
                $rootScope.Title = "404";
                $rootScope.BodyClass = "four-zero-four";

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
