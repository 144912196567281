/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 28-7-2015
 * Time: 15:17
 * For Project: nabes-front
 * @version : 0.1
 */

define('components/dossier/page/directive',[
    'angular',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Page', [
            'Twensoc.Config',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbPage', [
        'config',
        function(config) {
            return {
                restrict: "E",
                transclude: true,
                scope: {
                    State: '@state',
                    ShowIf: '=showIf',
                    templateId: '@templateId'
                },
                templateUrl: "app/components/dossier/page/page.html",
                link: function(scope, elem, attr) {
                    scope.Config = config;
                    scope.showIfText = attr.showIf;
                    scope.showDebug = false;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                    });
                }
            };
        }
    ]);
});

