/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/company/list/controller',[
    'angular',
    'lodash',
    'angularUIBootstrap',
    'model/company-resource' //company resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.CompanyOverviewController', [
            'Twensoc.Config',
            'CompanyResource',
            'NabesFront.model.UserModel',
            'ui.bootstrap.pagination',
            'DossierResource'
        ]
    ).controller(
        'CompanyOverviewController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            '$log',
            'UserModel',
            'CompanyResource',
            'DossierResource',
            function ($state, $stateParams, $scope, $rootScope, config, $log, UserModel, CompanyResource, DossierResource) {
                $scope.Config = config;
                $rootScope.Title = "Company overview pagina";
                $rootScope.BodyClass = "company";

                $scope.companies = [];

                $scope.paging = {
                    filter: $state.params.filter,
                    pageNo: $state.params.pageNo,
                    totalItems: MAX_INT,
                    pageSize: 10,
                    termination: false
                };

                DossierResource.unsetActive();

                /**
                 * Changes the page based on the search, pageNo etc.
                 * This changes the state so its reflected within the URL
                 */
                $scope.changePage = function() {
                    //reset page number if filter is modified as we do not know how many pages the query will result in
                    if($state.params.filter !== $scope.paging.filter) $scope.paging.pageNo = 1;
                    $state.go($state.current, {pageNo: $scope.paging.pageNo, filter: $scope.paging.filter});
                };

                CompanyResource.findAll($scope.paging, {bypassCache: true}).then(function(companies) {
                    $scope.companies = companies;
                });

                $scope.showAddCompanyBtn = function() {
                    return UserModel.hasRole('ADMIN');
                };
                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {

                });
            }
        ]
    );
});
