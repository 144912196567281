define('components/dossier/date-input/directive',[
	'angular',
	'lodash',
	'utils/authentication',
	'components/dossier/base'
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.components.dossier.DateInput', [
			'Twensoc.Config',
			'NabesFront.utils.Authentication',
			'NabesFront.components.dossier.BaseComponent'
		]
	).directive('nbDateInput', [
		'config',
		'Authentication',
		function (config, Authentication) {
			return {
				restrict: "E",
				scope: {
					dossier: '=data',
					Name: '@name',
					Data: '=value',
					Label: '@label',
					PlaceHolder: '@placeholder',
					Required: '@required',
					Explanation: '@explanation',
					ShowIf: '=showIf',
					templateId: '@templateId',
					defaultDate: '@defaultDate',
					startSelection: '@startSelection',
					includeNextYears: '@includeNextYears',
					onlyFutureDates: '@onlyFutureDates'
				},
				require: '^form',
				templateUrl: "app/components/dossier/date-input/date-input.html",
				link: function (scope, elem, attr, formCtrl) {
					scope.Config = config;
					scope.Required = _.castToBoolean(scope.Required);
					scope.showIfText = attr.showIf;
					scope.showDebug = false;
					scope.showExplanation = false;
					scope.includeNextYears = scope.includeNextYears == null ? 0 : parseInt(scope.includeNextYears);
					if (scope.Data === "NaN-0NaN-0NaN") {
						console.warn("Illegal value for date:" + scope.Data + ", reset to null");
						scope.Data = null;
					}

					var now = new Date();
					var monthNames = [
						"Januari", "Februari", "Maart", "April", "Mei", "Juni",
						"Juli", "Augustus", "September", "Oktober", "November", "December"
					];
					var daysPerMonth = [
						31, 29, 31, 30, 31, 30,
						31, 30, 31, 30, 31, 30
					];

					if (_.isEmpty(scope.Data)) {
						if (scope.defaultDate && scope.defaultDate !== null) {
							if (scope.defaultDate !== 'today') {
								scope.Data = scope.defaultDate;
							}
							scope.date = _.getDateFromFormat(scope.Data);
						}
						// Empty default date should leave date empty
					} else {
						scope.date = _.getDateFromFormat(scope.Data);
					}

					scope.opened = false;
					scope.variabeleName = attr.value;
					scope.showDebugToggle = false;

					if (scope.date) {
						scope.day = {value: scope.date.getDate()};
						scope.month = {value: scope.date.getMonth()};
						scope.year = {value: scope.date.getFullYear()};
					}

					/**
					 * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
					 * @param src Source scope that sends the event
					 * @param v Boolean
					 */
					scope.showDebugToggleListener = scope.$on('showDebugToggle', function (src, v) {
						scope.showDebugToggle = v;
					});


					/**
					 * @param month
					 * @param year
					 */
					scope.setDate = function (value, type) {
						switch (type) {
							case 'day':
								scope.day = {label: value, value: value};
								break;
							case 'month':
								scope.month = {value: value, label: monthNames[value]};
								break;
							case 'year':
								scope.year = {label: value, value: value};
								break;
						}

						// Check the day if all values are set
						if (scope.day && scope.month && scope.year) {
							var max = new Date(scope.year.value, scope.month.value + 1, 0).getDate();
							if (max < scope.day.value) {
								scope.day = {label: max, value: max};
							}
						}
					};

					scope.getDays = function () {
						// Check the day if all values are set
						var max = 31;
						if (scope.month && scope.year) {
							max = new Date(scope.year.value, scope.month.value + 1, 0).getDate();
						} else if (scope.month) {
							max = daysPerMonth[scope.month.value];
						}

						var days = [];
						var i = 1;
						while (i <= max) {
							days.push({value: i, label: i});
							i++;
						}
						return days;
					};

					scope.getMonths = function () {
						var months = [];

						var i = 0;
						while (i <= 11) {
							months.push({value: i, label: monthNames[i]});
							i++;
						}
						return months;
					};

					scope.getYears = function () {
						var years = [];
						var max = new Date().getFullYear();

						var i;
						if (scope.startSelection && scope.startSelection !== null) {
							 i = scope.startSelection;
						} else {
							 i = 1900;
						}


						if (_.castToBoolean(scope.onlyFutureDates) === true) {
							i = now.getFullYear();

							if (scope.date && scope.date.getFullYear() < i)
								i = scope.date.getFullYear();
							else
								i = max;
						}

						if (scope.includeNextYears != null) {
							max += parseInt(scope.includeNextYears);
						}

						while (i <= max) {
							years.push({value: i, label: i});
							i++;
						}

						return years;
					};

					/**
					 * If the internal date attribute changes update the Model value accordingly.
					 */
					var dateChange = scope.$watch(function () {

						if (scope.day && scope.month && scope.year) {
							scope.date = new Date(scope.year.value, scope.month.value, scope.day.value);
							return scope.date.getTime();
						}

						scope.date = null;
						return null;

					}, function (newValue, oldValue) {

						console.log('setDate: ' + scope.day + "-" + scope.month + "-" + scope.year);

						scope.date = new Date(newValue);
						var v = newValue == null ? '' : _.formatDate(scope.date);
						document.getElementById('date-' + scope.Name).value = v;
						scope.Data = v;

						console.log(scope.Data);

					});

					if (scope.onlyFutureDates != null) {
						formCtrl[scope.Name].$validators.mustBeFutureDate = function (modelValue, viewValue) {
							if (scope.day && scope.month && scope.year) {
								return scope.date.getTime() >= now.getTime();
							}
							return false;
						};
					}

					/**
					 * Function that checks if this input has a specific error.
					 * If this is the case the error is displayed to the user.
					 * @param {string} errorName
					 * @returns {boolean}
					 */
					scope.hasError = function (errorName) {
						var value = _.getByString(formCtrl.daySelect.$error, errorName) ||
							_.getByString(formCtrl.monthSelect.$error, errorName) ||
							_.getByString(formCtrl.yearSelect.$error, errorName) ||
							_.getByString(formCtrl[scope.Name].$error, errorName);

						return ((formCtrl.$submitted === true || formCtrl.daySelect.$dirty === true || formCtrl.monthSelect.$dirty === true || formCtrl.yearSelect.$dirty === true || formCtrl[scope.Name].$dirty === true) && value);
					};

					/**
					 * Show/hide the debug information for this input.
					 */
					scope.toggleDebug = function () {
						scope.showDebug = !scope.showDebug;
					};

					/**
					 * Show/hide an optional explanation
					 */
					scope.toggleExplanation = function () {
						scope.showExplanation = !scope.showExplanation;
					};

					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					scope.$on('$destroy', function () {
						scope.showDebugToggleListener();
						dateChange();
					});
				}
			};
		}
	]);
});
