/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 23-7-2015
 * Time: 10:38
 * For Project: nabes-front
 * @version : 0.1
 */

define('utils/websocketAdapter',[
    'angular',
    'lodash',
    'js-data',
    'utils/vertx-client'
], function (ng, _) {
    'use strict';

    return ng.module(
        'WebsocketAdapter', [
            'js-data',
            'Twensoc.utils.VertxClient'
        ]
    ).factory('WebsocketAdapter', [
            '$log',
            '$q',
            '$rootScope',
            'DS',
            'VertxClient',
            function($log, $q, $rootScope, DS, VertxClient) {

                //var eb = new EventBus('http://api.nabes.local:8080/ws');
                //
                //eb.onopen = function() {
                //
                //    // set a handler to receive a message
                //    eb.registerHandler('some-address', function(message) {
                //        console.log('received a message: ' + JSON.stringify(message));
                //    });
                //
                //    // send a message
                //    eb.send('/auth', {name: 'tim', age: 587});
                //
                //};

                function WebsocketAdapter() {
                    this.init();
                }

                ng.extend(WebsocketAdapter.prototype, {
                    init: function() {
                        DS.registerAdapter('ws', this, { default: true });
                        this.socket = VertxClient;
                    },

                    send: function (address, data) {
                        var me = this,
                            deferred = $q.defer();

                        me.socket.send(address, data).then(function (data) {
                            me.handleResponse(address, deferred, data, false);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },


                    create: function (definition, attrs, options) {
                        var me = this,
                            deferred = $q.defer(),
                            address = definition.endpoint+'create';

                        me.socket.send(address, attrs).then(function (data) {
                            me.handleResponse(address, deferred, data, false);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },

                    find: function (definition, id, options) {
                        var me = this,
                            deferred = $q.defer(),
                            address = definition.endpoint+'get';

                        options = _.cloneDeep(options || {});

                        options.id = id;
                        //options.where = { id: id };
                        me.socket.send(address, options).then(function (data) {
                            me.handleResponse(address, deferred, data, false);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },

                    findAll: function (definition, params, options) {
                        var me = this,
                            deferred = $q.defer(),
                            address = definition.endpoint+'list',
                            p = angular.copy(params, angular.copy({}, options||{}));

                        me.socket.send(address, p).then(function (data) {
                            me.handleResponse(address, deferred, data, true, params);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },

                    update: function (definition, id, attrs, options) {
                        var me = this,
                            deferred = $q.defer(),
                            address = definition.endpoint+'update';

                        //attrs.clientId = attrs.id;

                        me.socket.send(address, attrs).then(function (data) {
                            me.handleResponse(address, deferred, data, false);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },

                    updateAll: function (definition, attrs, params, options) {
                        // Must resolve the promise with the updated items
                    },

                    destroy: function (definition, id, options) {
                        var me = this,
                            deferred = $q.defer(),
                            address = definition.endpoint+'delete';

                        options = _.cloneDeep(options || {});

                        options.id = id;
                        //options.where = { id: id };
                        me.socket.send(address, options).then(function (data) {
                            me.handleResponse(address, deferred, data, false);
                        }, function (err) {
                            deferred.reject(err);
                        });
                        return deferred.promise;
                    },

                    destroyAll: function (definition, params, options) {
                        // Must return a promise
                    },

                    /**
                     * Handle the response of a message.
                     * Either:
                     * { <object } or { items : [{ ... }] }
                     *
                     * Or a failure containing:
                     * failureCode = 1 System failure
                     * failureCode = 2 Business logic failure
                     * failureMessage = Error description
                     *
                     * @param address
                     * @param deferred
                     * @param data
                     * @param isArray
                     * @param originalParams
                     */
                    handleResponse: function(address, deferred, data, isArray, originalParams) {
                        if(data.failureCode != null || data.message != null) {
                            deferred.reject(data.message.toLowerCase().replace(" ","-"));
                            return;
                        }

                        if (data.error) {
                            $log.error(address + ': ' + data.error);
                            deferred.reject(data.error);
                            return;
                        }
                        if (data.errors) {
                            $log.error(address + ": " + JSON.stringify(data.errors));
                            deferred.reject(data.errors);
                            return;
                        }

                        if (isArray) {
                            //TODO WONDERFUL JSDATA PLUGIN HACK
                            if(data.count != null) originalParams.totalItems = parseInt(data.count);
                            deferred.resolve(data.items);
                            return;
                        }

                        if(data.items && data.items.length == 1) {
                            deferred.resolve(data.items[0]);
                            return;
                        }

                        deferred.resolve(data);

                    }
                });

                return new WebsocketAdapter();
            }
        ]
    );
});
