/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/overview/controller',[
	"angular", "lodash", "collection/toast-messages", //toast messages collection
	"model/company-resource", //company resource
	"model/dossier-resource" //dossier resource
], function (ng, _) {
	"use strict";

	return ng.module("NabesFront.controllers.DossierOverviewController", [
		"Twensoc.Config",
		"ui.bootstrap",
		"DossierResource",
		"CompanyResource",
		"NabesFront.collection.ToastMessagesCollection",
		"NabesFront.model.UserModel",
		'NabesFront.utils.Authentication'
	]).controller("DossierOverviewController", [
		"$state",
		"$stateParams",
		"$scope",
		"$rootScope",
		"config",
		"DossierResource",
		"CompanyResource",
		"toastMessages",
		"UserModel",
		"$parse",
		"VertxClient",
		"Authentication",
		function ($state,
			$stateParams,
			$scope,
			$rootScope,
			config,
			DossierResource,
			CompanyResource,
			toastMessages,
			UserModel,
			$parse,
			VertxClient,
			Authentication
		) {
			$scope.Config = config;
			$rootScope.BodyClass = "dossier";

			$scope.isAdmin = UserModel.hasRole("ADMIN");
			$scope.uuid = $stateParams.uuID;
			$scope.chapters = [];
			$scope.showFullDossier = UserModel.hasRole("ADMIN") || UserModel.hasRole("USER");
			$scope.isHistorical = 0;
			/**
			 * Function that navigates the user back to the list view this can happen for various reasons.
			 * Generally a toast message should be added before the navigation to display the reason.
			 */
			$scope.navigateToList = function () {
				$state.go("locale.app.dossier");
			};

			if (UserModel.hasRole("BGO")) {
				$scope.navigateToList();
				return;
			}

			$scope.handleFailure = function (err) {
				if (err == null) err = "unknown-error";
				if (err.message) err = err.message.toLowerCase().replace(" ", "-");
				toastMessages.add(toastMessages.T_DANGER, "", "failure." + err, 4000);
			};
			$scope.failureHandler = $scope.handleFailure.bind($scope);

			/**
			 * Create a new Dossier instance if no UUID is specified.
			 * Else retrieve it through the find function.
			 */
			if ($scope.uuid === 0) {
				$scope.model = DossierResource.createInstance();
				DossierResource.setActive($scope.model, "overview"); // No chapters and pages in sidebar on dossier overview.
				//$scope.getChapters();
			} else {
				// Load method loads all relevant data and returns the dossier, the dossierTpl and the dossierTplDef
				DossierResource.load($stateParams.uuID).then(function (data) {
					$scope.model = data.dossier;
					$scope.dossier = $scope.model;      // We want to work with 'dossier' in the template
					$scope.dd = $scope.dossier.data;

					$scope.template = data.dossierTpl;
					$scope.templateDef = data.dossierTplDef;

					$scope.chapters = $scope.templateDef.getChapters();

					DossierResource.setActive($scope.model, "overview");

					if ($scope.dossier.isHist === true) {
						console.log(" IS HISTORICAL ");
						$scope.showFullDossier = false;
						$scope.isHistorical = true;
					}

					CompanyResource.find($scope.dossier.companyId).then(function (company) {
						$scope.informationText = company.information;
					});
				}, function (err) {
					$scope.handleFailure(err);
					$scope.navigateToList();
				});
			}

			$scope.chapterVisible = function (chapter) {
				var showIf = chapter.properties.showIf;
				return showIf === true || $parse(showIf)($scope);
			};

			$scope.ok = function (form) {
				if (form.$invalid) {
					return;
				}
				//if(!$scope.hasChanges()) {
				//   $scope.navigateToList();
				//   return;
				//}

				if ($scope.model.id == null) {
					return DossierResource.create($scope.model).then(function (data) {
						DossierResource.inject(data);
						$scope.navigateToList();
					}, $scope.failureHandler);

				} else {
					DossierResource.update($scope.model.id, $scope.model).then(function (data) {
						//angular.merge($scope.origModel, data);
						$scope.navigateToList();
					}, $scope.failureHandler);
				}
			};

			$scope.chapterEnabled = function (chapter) {
				var enabledIf = chapter.properties.enabledIf;
				return enabledIf === true || $parse(enabledIf)($scope);
			};

			$scope.isChapterCompleted = function (chapterSlug) {
				var chapter = $scope.templateDef.getChapter(chapterSlug);
				return chapter && $scope.dossier.chapterProgress(chapter).progress == 100;
			};

			$scope.navigateToChapter = function (chapter, dossierId) {
				if ($scope.chapterEnabled(chapter)) {
					$state.go("locale.app.dossier.overview.edit", {
						chapterSlug: chapter.properties.slug,
						uuID: dossierId
					});
				}
			};

			$scope.cancel = function () {
				$scope.navigateToList();
			};

			$scope.dossierProgress = function () {
				if (this.dossier == null || this.dossier.id == null) return 0;

				var transform_styles = ["-webkit-transform", "-ms-transform", "transform"],
					p = this.dossier.dossierProgress(this.templateDef),
					rotation = p.progress * 100 / 180,//Math.floor(Math.random() * 180);
					fill_rotation = rotation,
					fix_rotation = rotation * 2;
				for (var i in transform_styles) {
					if (transform_styles.hasOwnProperty(i)) {
						var els = document.querySelectorAll(".circle .fill, .circle .mask.full");
						els[0].style[transform_styles[i]] = "rotate(" + fill_rotation + "deg)";
						els[1].style[transform_styles[i]] = "rotate(" + fill_rotation + "deg)";
						els[2].style[transform_styles[i]] = "rotate(" + fill_rotation + "deg)";

						els = document.querySelectorAll(".circle .fill.fix");
						els[0].style[transform_styles[i]] = "rotate(" + fix_rotation + "deg)";
					}
					//$('.circle .fill, .circle .mask.full').css(transform_styles[i], 'rotate(' + fill_rotation + 'deg)');
					//$('.circle .fill.fix').css(transform_styles[i], 'rotate(' + fix_rotation + 'deg)');
				}
				return p.progress;

			};

			/**
			 * Method which closes / retires the dossier by sending a call to the backend.
			 *
			 * @param {number} dossierId
			 */
			$scope.retireDossier = function (dossierId) {
				var confirmationResponse = confirm(
					"U staat op het punt dit Nabes dossier te sluiten. Het sluiten van een dossier is" +
					" definitief en kan niet meer ongedaan gemaakt worden. U sluit het dossier voor uzelf en " +
					"tevens voor eventuele andere personen die voor dit dossier waren uitgenodigd. " +
					"\n\n Weet u zeker dat u dit dossier wilt sluiten?");
				if (confirmationResponse !== true) {
					return;
				}

				console.debug("Sending anonymization request to the backend", {
					dossierId: dossierId
				});

				var messageData = {dossierId: dossierId};
				var payload = {data: messageData};
				VertxClient.send("/dossier/anonymize", {
					payload: payload
				}).then(function (result) {
					console.debug("Dossier has been closed", {
						response: result
					});
					toastMessages.add(toastMessages.T_SUCCESS, "", "info.dossierHasBeenClosed", 4000);
					$scope.navigateToList();

					if(UserModel.hasRole("USER")) {
						Authentication.doLogout();
					}
				}, function (error) {
					console.error("Couldn't close dossier", {
						error: error
					});
					toastMessages.add(toastMessages.T_DANGER, "", "failure.unableToAnonymizeDossier", 4000);
				});
			};
			/**
			 * Time for some cleaning up of listeners! this is very important!
			 */
			$scope.$on("$destroy", function () {
				//DossierResource.unsetActive();
				$scope.model = null;
				$scope.chapters = null;
				$scope.failureHandler = null;
			});

		}
	]);
});

