/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 13:47
 * For Project: Nabes-Front
 * @version : 0.1
 */

define('controllers/app/controller',[
    'angular',
    'lodash',
    'components/header/directive', //header directive
    'components/footer/directive', //footer directive
    'components/sidebar/directive', //sidebar directive
    'collection/toast-messages', //toast messages collection
    'utils/authentication', //authentication service
    'model/user' //user model
], function (ng, _) {
    'use strict';

    return ng.module(
        'Twensoc.controllers.AppPageController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter',
            'Twensoc.components.tsSidebar',
            'NabesFront.model.UserModel',
            'NabesFront.collection.ToastMessagesCollection',
            'NabesFront.utils.Authentication'
        ]
    ).controller(
        'AppPageController', [
            '$scope',
            'config',
            'UserModel',
            'toastMessages',
            'Authentication',
            function ($scope, config, UserModel, toastMessages, Authentication) {
                $scope.Config = config;
                $scope.User = UserModel;
                $scope.Authentication = Authentication;

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
