define('components/dossier/autocomplete/directive',[
	"angular", "lodash", "components/dossier/base"
], function (ng, _) {
	"use strict";

	return ng.module("NabesFront.components.dossier.AutoComplete", [
		"Twensoc.Config", "NabesFront.components.dossier.BaseComponent"
	]).directive("nbAutoComplete", [
		"config", function (config) {
			return {
				restrict: "E",
				scope: {
					dossier: "=data",
					Name: "@name",
					Data: "=value",
					Label: "@label",
					PlaceHolder: "@placeholder",
					Required: "=required",
					Explanation: "@explanation",
					ShowIf: "=showIf",
					type: "@type",
					ReadOnlyIf: "=readOnlyIf",
					templateId: "@templateId",
					loadDataAddress: "@address",
					autoCompleteLabelField: "@autocompleteLabel",
					autoCompleteIdField: "@autocompleteId"
				},
				require: "^form",
				templateUrl: "app/components/dossier/autocomplete/autocomplete.html",
				link: function (scope, elem, attr, formCtrl) {
					scope.Config = config;
					scope.Required = _.castToBoolean(scope.Required);
					scope.showExplanation = false;
					scope.showDebug = false;
					scope.showIfText = attr.showIf;
					scope.variabeleName = attr.value;
					scope.showDebugToggle = false;

					if (scope.autoCompleteLabelField == null) scope.autoCompleteLabelField = "label";
					if (scope.autoCompleteIdField == null) scope.autoCompleteIdField = "id";
					if (scope.type == null) scope.type = 1;
					scope.autoCompleteValue = _.getByString(scope.Data, scope.autoCompleteLabelField);
					scope.autoCompleteArray = [];
					scope.activeLi = -1;

					scope.getInputId = function () {
						if (scope.inputId != null) return scope.inputId;

						scope.inputId = _.guid();
						return scope.inputId;
					};

					/**
					 * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
					 * @param src Source scope that sends the event
					 * @param v Boolean
					 */
					scope.showDebugToggleListener = scope.$on("showDebugToggle", function (src, v) {
						scope.showDebugToggle = v;
					});

					/**
					 * Function that is called onKeyUp by the angular ngKeyUp directive.
					 * This passes along the value of the input so a call is made to the backend.
					 * @param {string} value
					 */
					scope.search = function (value) {
						if (value == null || value.length <= 0) {
							delete(scope.Data[scope.autoCompleteLabelField]);
							delete(scope.Data[scope.autoCompleteIdField]);
							scope.autoCompleteArray = [];
							scope.$emit("autoComplete.selected", {id: 0});
							return;
						}
						scope.getAutoCompleteData(value);
					};

					/**
					 * Function with which the value that is shown by the autocomplete can be selected easily.
					 * @param {number|string} id
					 * @param {string} label
					 */
					scope.select = function (item) {
						scope.autoCompleteValue = item.label;
						scope.Data[scope.autoCompleteLabelField] = item.label;
						scope.Data[scope.autoCompleteIdField] = item.id;
						scope.Data.terminationItem = item;
						scope.Data.type = parseInt(scope.type);
						scope.autoCompleteArray.splice(0);
						scope.$emit("autoComplete.selected", {id: item.id});
					};

					/**
					 * Function that retrieves the autocomplete data by calling the specified loadDataAddress on the dossier's sendMessage function(model)
					 * It passes along the search value (within a message)
					 * @param {string} searchValue
					 */
					scope.getAutoCompleteData = function (searchValue) {
						scope.dossier.sendMessage(scope.loadDataAddress,
							{
								search: searchValue,
								type: scope.type
							}
						).then(function (data) {
							scope.autoCompleteArray.splice(0);
							if (data == null || data.items == null || data.items.length <= 0) return;
							_.forEach(data.items, function (object) {
								var label = object[scope.autoCompleteLabelField];
								var id = object[scope.autoCompleteIdField];
								scope.autoCompleteArray.push({
									id: id,
									label: label,
									department: object.department,
									v_city: object.v_city,
									refRequired: object.refRequired,
									emailTermination: object.emailTermination,
									refLabel: object.refLabel
								});
							});
							console.log(scope.autoCompleteArray);
						}).catch(function () {
							scope.autoCompleteArray = [];
						});
					};

					/**
					 * Function that checks if this input has a specific error.
					 * If this is the case the error is displayed to the user.
					 * @param {string} errorName
					 * @returns {boolean}
					 */
					scope.hasError = function (errorName) {
						if (formCtrl[scope.Name] === undefined) {
							// Skip problem with bank . iban field
							return false;
						}
						var value = _.getByString(formCtrl[scope.Name].$error, errorName);
						return ((formCtrl.$submitted === true || formCtrl[scope.Name].$dirty === true) && value);
					};

					/**
					 * Show/hide an optional explanation
					 */
					scope.toggleExplanation = function () {
						scope.showExplanation = !scope.showExplanation;
					};

					/**
					 * Show/hide the debug information for this input.
					 */
					scope.toggleDebug = function () {
						scope.showDebug = !scope.showDebug;
					};

					elem.bind("keydown", function (event) {
						var keyCode = event.keyCode;
						if(keyCode != 32 && keyCode != 38 && keyCode != 40) return;

						var listItems = elem[0].getElementsByTagName('li');
						var selectedItem = elem[0].querySelector('li.active');
						var selectedItemIndex = _.indexOf(listItems, selectedItem);
						var nextSelectedIndex;

						// space bar selection
						if(keyCode == 32){
							selectedItem.click();
						}

						// up arrow key
						if (keyCode == 38) {
							nextSelectedIndex = ((selectedItemIndex - 1) >= 0) ? (selectedItemIndex - 1) : 0;
							listItems[nextSelectedIndex].classList.add('active');
						}

						// down arrow key
						if (keyCode == 40) {
							nextSelectedIndex = ((selectedItemIndex + 1) >= 0) ? (selectedItemIndex + 1) : 0;
							listItems[nextSelectedIndex].classList.add('active');
						}

						if(selectedItem != null) selectedItem.classList.remove('active');
						event.preventDefault();
						return false;
					});

					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					scope.$on("$destroy", function () {
						scope.showDebugToggleListener();
					});
				}
			};
		}
	]);
});
