/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 23-7-2015
 * Time: 10:38
 * For Project: nabes-front
 * @version : 0.1
 */

define('utils/templateInterpreter',[
    'angular',
    'lodash',
    'config'
], function (ng, _) {
    'use strict';

    return ng.module(
        'TemplateInterpreter', [
            'NabesFront.model.TemplateItem'
        ]
    ).factory('TemplateInterpreter', [
            '$log',
            '$q',
            '$rootScope',
            'TemplateItem',
            function($log, $q, $rootScope, TemplateItem) {

                function TemplateInterpreter(attrs) {
                    ng.extend(this, attrs);
                    this.init();
                }

                ng.extend(TemplateInterpreter.prototype, {

                    items: [],
                    pos: 0,
                    tpl: null,
                    len: 0,
	                map: {
		                slug: 'slug',
		                title: 'title',
		                if: 'showIf',
		                //text: 'label',
		                name: 'name',
		                var: 'value',
		                placeholder: 'placeholder',
		                required: 'required',
		                locale: 'locale',
		                style: 'state',
		                explanation: 'explanation'
	                },

                    init: function() {

                    },

                    parse: function (txt) {
                        if(txt == null ) {
                            return {
                                errors: [],
                                items: []
                            };
                        }

                        var me = this;

                        me.tpl = txt;
                        me.len = txt.length;
                        me.pos = 0;
                        me.line = 0;
                        me.tokens = [];
                        //console.log("tokenize");

                        var result = me.tokenize();
                        //console.log("tokenize ready");

	                    if(result.errors.length !== 0) {
		                    console.error(result.errors);
		                    return result;
	                    }

	                    me.tokens = result.tokens;
	                    me.items = [];

                        me.pos = 0;
                        me.len = me.tokens.length;
                        //console.log("interpretTokens");

                        me.interpretTokens(null);
                        //console.log("interpretTokens ready");

                        //console.log(me.items);

	                    result = {
		                    errors: [],
		                    items: me.items
	                    };

	                    // Clean up large structures
	                    me.tokens = null;
	                    me.items = null;
	                    me.tpl = null;

                        return result;
                    },

                    interpretTokens: function(parent) {
                        var me = this,
                            tplItem = null;

                        while(me.pos < me.len) {
                            var token = me.tokens[me.pos++],
                                type = token.type.toLowerCase();

                            if (type.charAt(0) === '/') {
                                type = type.substring(1);

	                            if(type.match(/(chapter|page|block|row|grid)/)) {
		                            return;
	                            }

                            } else {
                                if(token.type == 'PlainText') {
                                    if(tplItem && tplItem.type == 'Text') {
                                        // Plaintext belongs to previous Text token - if that label is empty
	                                    tplItem.properties.label = token.properties.label;
                                    } else {
                                        // Convert PlainText to Text
                                        tplItem = me.getTemplateItem(token);
                                        tplItem.type = 'Text';
                                        parent.items.push(tplItem);
                                    }
                                } else {
                                    tplItem = me.getTemplateItem(token);
                                    if (parent) {
                                        parent.items.push(tplItem);
                                    } else {
                                        me.items.push(tplItem);
                                    }
                                }

                                if(type.match(/(chapter|page|block|row|grid)/)) {
                                    me.interpretTokens(tplItem);
                                }
                            }
                        }
                    },

                    getTemplateItem: function(token) {
                        var map = this.map,
                            tplItem = {
                                type: token.type,
                                properties: {},
                                items: []
                            };
                        for (var p in token.properties) {
                            if (token.properties.hasOwnProperty(p)) {
	                            if((p == 'columns' || p == 'buttons') && token.properties[p] !== null && _.isString(token.properties[p])) {

		                            var s = token.properties[p].split(']');
		                            token.properties[p] = [];
		                            for(var i=0;i<s.length;i++) {
			                            if(!_.isEmpty(s[i])) {
				                            var part = s[i].trim().replace("[",'').split(' '),
					                            propertyName = null, propertyValue = [], btn = {};

				                            for(var j=0; j<part.length;j++) {
					                           if(part[j] !== '') {
						                           if (part[j].charAt(part[j].length-1) == ":") {
							                           if (propertyName) {
								                           btn[propertyName] = propertyValue.join(' ');
							                           }
							                           propertyName = part[j].substring(0, part[j].length-1);
							                           propertyValue = [];
						                           } else {
							                           propertyValue.push(part[j]);
						                           }
					                           }
				                            }
				                            if(propertyName) {
					                            btn[propertyName] = propertyValue.join(' ');
				                            }
				                            token.properties[p].push(btn);
			                            }
		                            }
		                            tplItem.properties[p] = token.properties[p];

	                            } else if(p == 'options') {
		                            var o = token.properties.options,
			                            opts = [];
		                            if(_.isString(o)) {
			                            opts = o;
		                            } else {
			                            for (var q in o) {
				                            if (o.hasOwnProperty(q)) {
					                            var v = q;
					                            if (v === 'false') v = false;
					                            else if (v === 'true') v = true;
					                            else if (!isNaN(parseInt(v))) v = parseInt(v);

					                            if (token.type == 'SelectInput') {
						                            opts.push({value: v, label: o[q]});
					                            } else if (token.type == 'RadioInput') {
						                            opts.push({value: v, text: o[q]});
					                            } else {
						                            opts.push({name: v, text: o[q]});
					                            }
				                            }
			                            }
		                            }
		                            tplItem.properties.options = opts;

	                            } else if(map[p] === undefined) {
                                    tplItem.properties[p] = token.properties[p];
                                } else {
                                    tplItem.properties[map[p]] = token.properties[p];
                                }
                            }
                        }

                        if(tplItem.type == 'Grid') {
                            console.log(tplItem);
                        }
                        return tplItem;
                    },

                    tokenize: function() {

                        var me = this,
                            token = null,
                            c = '',
                            tpl = (me.tpl || "").replace(/(\r\n|\r)/g,"\n").replace(/\t/g," "),
                            len = tpl.length,
                            p = 0,
                            lineNo = 1,
                            startPos = 0,
	                        isEndTag = false,
                            errors = [],
                            hasErrors = false,
	                        tokens = [];

                        var availableTagsRegex = /(DynamicText|Chapter|NumberInput|Page|Block|Button|AutoComplete|Text|Select|RadioInput|TextInput|TextAreaInput|DateInput|SelectInput|Text|Row|CheckboxInput|Checklist|Title|Grid|SendAccessInviteInput|MoreInfoLink|Upload|Spacer)/i;

                        var skipSpace = function() {
                            while(p < len) {
	                            c = tpl[p];
                                if(c == '\n') {
                                    lineNo++;
                                } else if (tpl.charCodeAt(p) !== 32) {
                                    return;
                                }
                                p++;
                            }
                        };

                        var readWord = function() {
                            var startPos = p;

                            while (p < len && tpl.charAt(p).match(/([a-zA-Z0-9]|\.|\?|-|_)/)) p++;
                            return tpl.substring(startPos, p);
                        };

                        var readUntilRegex = function(regex, allowBrackets) {
                            var startPos = p,
	                            bracketCount = 0;

	                        allowBrackets = allowBrackets === undefined ? false : allowBrackets;

                            while (p < len) {
                                c = tpl[p];
                                if(c == '\n') {
	                                lineNo++;
                                } else if(allowBrackets && c == '[') {
	                                bracketCount++;
                                } else if(allowBrackets && c == ']' && bracketCount > 0) {
	                                bracketCount--;
                                } else if(tpl.charAt(p).match(regex)) {
                                    break;
                                }
                                p++;
                            }
                            return tpl.substring(startPos, p);
                        };

                        var backToLastWordStart = function() {
                            while(p > 0) {
                                c = tpl[p];
                                if(c == '\n') {
                                    lineNo--;
                                } else if(c != ' ') {
                                    break;
                                }
                                p--;
                            }

                            while(p > 0) {
                                c = tpl[p];
	                            if(c == '\n') {
		                            lineNo--;
	                            } else if(tpl.charCodeAt(p) == 32) {
                                    break;
                                }
                                p--;
                            }
                        };

                        var addError = function(pos, msg) {
                            // Find position in line; pos is position in complete text
                            // Loop back untill eol charachter is found
                            var count = 0, absPos = pos;
                            while(absPos > 0 && tpl[absPos--] !== '\n') count++;

                            errors.push({
                                lineNo: lineNo,
                                linePos: count,
                                absPos: pos,
                                msg: msg,
	                            near: tpl.substring(pos - 40 > 0 ? pos - 40 : 0, pos + 40 < len ? pos + 40 : len)
                            });
                            hasErrors = true;
                        };


                        while (p < len && hasErrors === false) {
                            c = tpl[p];
                            //console.log(lineNo+" p:"+p+" ["+c+"]");
                            if(c == '\n') {
                                // End of line.
                                p++;

                                lineNo++;
                            } else if(c == '[') {
                                // Start a start tag or an end tag
                                p++;
                                startPos = p;
                                isEndTag = false;
                                if(tpl[p] == "/") {
                                    p++;
                                    isEndTag = true;
                                }
                                var tagName = readWord();
                                if(!tagName.match(availableTagsRegex)) {
                                    addError(startPos, "Unknown tag found '"+tagName+"'");
                                    break;
                                }
                                token = {
                                    type: isEndTag ? '/' + tagName : tagName,
                                    pos: startPos,
                                    lineNo: lineNo,
                                    properties: {}
                                };

                                // Read properties. slug: some-slug if: <condition> options: 1:true 2:false]
                                // Pattern: word semicolon text
                                // Read until ']' or EOF
                                skipSpace();
                                var target = token.properties;
                                while(p < len) {
                                    c = tpl[p];
                                    if(c == ']') {
                                        tokens.push(token);
                                        p++;
                                        break;
                                    }

	                                if(isEndTag) {
		                                addError(p, "']' expected");
		                                break;
	                                }

                                    var property = readWord().trim();
                                    if(tpl[p] !== ':') {
                                        skipSpace();
                                        if(tpl[p] === ']') {
                                            // Options is string
                                            token.properties.options = property;
                                            continue;
                                        }

                                        addError(p, "Semicolon expected after tag property '"+property+"'");
                                        break;
                                    }
	                                p++;
                                    skipSpace();
                                    startPos = p;

                                    // Read the value. Nested brackets allowed. If in nested brackets, semicolon allowed
                                    var bracketCount = 0;
                                    c = tpl[p];
                                    while( (bracketCount === 0 && c != ':' && c != ']') || bracketCount > 0 ) {
                                        if(c == '[') {
                                            bracketCount++;
                                        } else if (c == ']') {
                                            if(bracketCount > 0) {
                                                bracketCount--;
                                            } else {
                                                break;
                                            }
                                        }
                                        if(p< len) {
                                            p++;
                                            c = tpl[p];
                                        } else {
                                            break;
                                        }
                                    }
                                    var value = tpl.substring(startPos, p);

                                    if(p >= len) {
                                        addError(p, "End tag (']') missing");
                                        break;
                                    }

                                    if(property == 'options') {
                                        target.options = [];
                                        target = target.options;
                                        p--; // p did refer to ':', we want to start one character back
                                        backToLastWordStart();
                                    } else {
                                        // Remove last word except if the next character is a ']'
                                        if (tpl[p] !== ']') {
                                            p--; // p did refer to ':', we want to start one character back
                                            backToLastWordStart();
                                            target[property] = value.substring(0, p - startPos).trim();
                                        } else {
                                            target[property] = value.trim();
                                        }
                                    }
                                    skipSpace();
                                }

                            } else {

                                // Plain text block
                                var txt = readUntilRegex(/\[/).trim();
                                if(txt !== "") {
                                    if(token && token.type == 'Text') {
                                        // Add text to previous token
                                        token.properties.content = txt;
                                    } else {
                                        tokens.push({type: 'PlainText', properties: {content: txt}});
                                    }
                                }
                            }
                        }
	                    return {
		                    tokens: tokens,
		                    errors: errors
	                    };
                    },

                    skipSpace: function() {
                        var me = this,
                            p = me.pos,
                            c = '',
                            tpl = me.tpl,
                            len = me.len;

                        while (p < len) {
                            c = tpl.charAt(p++);
                            if (c === ' ');
                            else if (c.match(/[\u0009\u000A\u000D\u2028\u2029]/));
                            else break;
                        }
                        me.pos = --p;
                    },

                    readWord: function() {
                        this.skipSpace();

                        var me = this,
                            startPos = me.pos,
                            p = me.pos,
                            tpl = me.tpl, len = me.len;

                        while (p < len && tpl.charAt(p++).match(/[a-zA-Z0-9_\.]/));
                        me.pos = --p;
                        return tpl.substring(startPos, me.pos).trim();
                    },

                    readUntil: function(regex) {
                        var me = this,
                            startPos = me.pos,
                            p = me.pos,
                            tpl = me.tpl, len = me.len;

                        while (p < len && !tpl.charAt(p++).match(regex));
                        me.pos = --p;
                        return tpl.substring(startPos, me.pos).trim();
                    },


                    /**
                     *
                     * @param tpl
                     */
                    createTemplateItems: function(data) {
                        var items = [];

                        _.each(data, function (item) {
                            items.push(new TemplateItem(item));
                        });
                        return items;
                    },

                    /**
                     * Gather all checklist items in all chapters and add them in blocks to the checklist chapter
                     * where each chapter becomes a block containing all checklist items.
                     *
                     * @param data
                     */
                    findChecklistItems: function (data) {
                        if(!data.items || data.items.length === 0) {
                            return;
                        }

                        // Checklist chapter must be the last chapter
                        var checklistChapter = data.items[data.items.length - 1],
                            item, i,
                            items = [],
	                        checkListBlocks = [];

                        if (checklistChapter.properties.slug !== 'checklist') {
                            // No checklist page available
                            return;
                        }

                        // Loop through all chapters
                        for (i = 0; i < data.items.length; i++) {
                            item = data.items[i];
                            if (item.properties.slug !== 'checklist') {

                                var d = this.extractChecklistItems(item);
                                if (d.length > 0) {
                                    items = items.concat(d);
                                }
                                // Save number of checklist items in this chapter to calculate progress
                                item.properties.checklistItemCount = d.length;
                            }
                        }

                        var chapterSlug = '',
                            blockItems = [],
                            checklistStateItems = [];

                        // TODO: Init here?
                        checklistChapter.watchedChecklistItems = [];

                        for (i = 0; i < items.length; i++) {
                            item = items[i];

                            // Create a separate flat list for all checklist items that
                            // require an email alert if not handled within a certain time.
                            if(item.properties.alertId != null) {
                                checklistChapter.watchedChecklistItems.push(item);
                            }

                            // Create the checklist page structure ( Page -> Block -> Checklist item)
                            if (item.chapterSlug !== chapterSlug) {
                                // Add a block for this chapter
                                var blockItem = {
                                    type: "Block",
                                    properties: {
                                        state: "disabled",
                                        title: item.chapterTitle,
                                        collapsible: true,
                                        collapsed: true,
                                        icon: item.chapterIcon,
                                        if: item.if,
                                        enabledIf: item.enabledIf
                                    },
                                    items: []
                                };
                                // Let chapter refer to this block
                                var chapter = _.findWhere(data.items, {"type": "Chapter", "properties": {"slug": item.chapterSlug}});
                                chapter.properties.checkListBlock = blockItem;

	                            checkListBlocks.push(blockItem);
                                blockItems = blockItem.items;
                                chapterSlug = item.chapterSlug;
                            }

                            var cl = {
                                type: 'Checklist',
                                properties: _.clone(item.properties)
                            };
                            cl.properties.chapterSlug = item.chapterSlug;
                            cl.properties.pageSlug = item.pageSlug;
                            blockItems.push(cl);
                        }

	                    for(i=0;i<checkListBlocks.length;i++) {
		                    items = checkListBlocks[i].items;
		                    checkListBlocks[i] = this.getTemplateItem(checkListBlocks[i]);
		                    for(var j=0;j<items.length;j++) {
			                    checkListBlocks[i].items.push(this.getTemplateItem(items[j]));
		                    }
	                    }

                        checklistChapter.items[0].items = checklistChapter.items[0].items.concat(checkListBlocks);
                    },
                    /**
                     * Recursively extract all (nested) checklist items from a given item.
                     * @param item
                     * @return {*}
                     */
                    extractChecklistItems: function (item) {
                        var list = [],
                            itemProps = item.properties || {};

                        if (item.type == 'Checklist') {
                            return [{
                                type: 'Checklist',
                                properties: {
                                    name: itemProps.name,
                                    label: itemProps.label,
                                    showIf: '(' + itemProps.showIf + ')',
                                    relevantIf: undefined,
                                    alertId: itemProps.alertId
                                }
                            }];
                        }

                        if (item.items && item.items.length > 0) {
                            for (var i = 0; i < item.items.length; i++) {
                                var data = this.extractChecklistItems(item.items[i]);
                                if (data.length > 0) {
                                    for (var j = 0; j < data.length; j++) {
                                        var d = data[j];

                                        if (itemProps.showIf !== undefined) {
                                            d.properties.showIf = '(' + itemProps.showIf + ') && ' + d.properties.showIf;
                                            if(itemProps.type !== 'Checklist') {
                                                d.properties.relevantIf = '(' + itemProps.showIf + ')' + (d.properties.relevantIf == null ? '' : ('&& ' + d.properties.relevantIf));
                                            }
                                        }

                                        if (item.type == 'Chapter') {
                                            d.chapterTitle = itemProps.title;
                                            d.chapterSubTitle = itemProps.subtitle;
                                            d.chapterSlug = itemProps.slug;
                                            d.chapterIcon = itemProps.icon;
                                            d.if = itemProps.showIf;
                                            d.enabledIf = itemProps.enabledIf;

                                        } else if (item.type == 'Page') {
                                            d.pageTitle = itemProps.title;
                                            d.pageSubTitle = itemProps.subtitle;
                                            d.pageSlug = itemProps.slug;
                                        }

                                        list.push(d);
                                    }
                                }
                            }
                        }

                        return list;
                    }



                });

                return new TemplateInterpreter();
            }
        ]
    );
});
