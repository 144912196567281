define('components/dossier/row/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base',
    'utils/transclude-inject'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Row', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent',
            'Twensoc.utils.TranscludeInject'
        ]
    ).directive('nbRow', [
        'config',
        '$parse',
        'Authentication',
        function (config, $parse, Authentication) {
            return {
                restrict: "E",
                transclude: true,
                scope: {
                    dossier: '=data',
                    Data: '=value',
                    Explanation: '@explanation',
                    ShowIf: '=showIf',
                    templateId: '@templateId',
                    addBtnLabel: '@addBtnLabel',
                    buttons: '=buttons',
                    enabledIf: '=enabledIf',
                    filter: '@filter',
                    type: '@type',
                    addIfEmpty: '@addIfEmpty'

                },
                require: '^form',
                templateUrl: "app/components/dossier/row/row.html",
                link: function (scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.showExplanation = false;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.addBtnText = attr.addBtnText;
                    scope.itemIndex = 0;
                    scope.addIfEmpty = attr.addIfEmpty === 'true';

                    /**
                     * Function that adds a new empty object to the array which can be filled with values from the
                     * input elements later.
                     * @param {Event} $event
                     */
                    scope.add = function ($event) {
                        if($event) $event.preventDefault();
                        if (scope.type != null) {
                            scope.Data.push({
                                type: parseInt(scope.type)
                            });
                        }
                        else {
                            scope.Data.push({});
                        }
                    };

                    /**
                     * Click handler when a row button is called
                     * @param {number}index  data item index
                     * @param {Event} $event
                     * @param action Name of the action defined on the button
                     */
                    scope.buttonClick = function (item, $event, button) {
                        $event.preventDefault();
                        formCtrl.$submitted = true;

                        if (!scope.buttonEnabled(item, button)) return;

                        if (button.action == 'delete') {
                            var idx = scope.Data.indexOf(item);
                            scope.remove(idx, $event);
                        } else {
                            if(formCtrl.$valid !== true) return;
                            scope.$emit('dossierAction', {action: button.action, success: true, item: item});
                        }
                        formCtrl.$submitted = false;
                    };


                    /**
                     * Function that stores the index of the item in the Data array.
                     * Kinda hack, we have 2 loops, outer = items, inner = buttons in the html
                     *
                     * @param v index of the item being rendered
                     * @return {boolean} true - Using ng-show is a hack to get this method called
                     */
                    scope.setItemIndex = function (v) {
                        scope.itemIndex = v;
                        return true;
                    };


                    /**
                     * Remove the element from the array of items once its saved the elements are also removed on the server.
                     * @param {number} index
                     * @param {Event} $event
                     */
                    scope.remove = function (index, $event) {
                        $event.preventDefault();
                        scope.Data.splice(index, 1);
                    };

                    //scope.filterTypes = function(value, index, arr) {
                    //    if(scope.type != null) {
                    //        return parseInt(value.type) === parseInt(scope.type);
                    //    }
                    //    return true;
                    //};

                    /**
                     * Show/hide an optional explanation
                     */
                    scope.toggleExplanation = function () {
                        scope.showExplanation = !scope.showExplanation;
                    };

                    /**
                     * Show the debug toggle only to the ADMIN
                     */
                    scope.showDebugToggle = function () {
                        return Authentication.hasRole('ADMIN');
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function () {
                        scope.showDebug = !scope.showDebug;
                    };

                    //make sure Data is an empty array this has to be called here!
                    if (!_.isArray(scope.Data) || scope.Data.length === 0) {
                        scope.Data = [];
                    }

                    // Add an item if there are none
                    if(scope.addIfEmpty && scope.Data.length === 0) scope.add(null);


                    scope.buttonEnabled = function (item, btn) {
                        return btn.enabledIf === undefined || $parse(btn.enabledIf)(item) === true;
                    };

                    scope.buttonVisible = function (item, btn) {
                        return btn.if === undefined || $parse(btn.if)(item) === true;
                    };


                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function () {
                    });
                }
            };
        }
    ])
        ;
})
;
