define('components/dossier/next-btn/directive',[
    'angular',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.NextBtn', [
            'Twensoc.Config',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbNextBtn', [
        'config',
        function(config) {
            return {
                restrict: "E",
                scope: {
                    Dossier: '=data',
                    nextPage: '&',
                    prevPage: '&',
                    direction: '@direction'
                },
                templateUrl: "app/components/dossier/next-btn/next-btn.html",
                link: function(scope, elem, attr) {
                    scope.Config = config;

                    scope.content = scope.direction === 'next' ? 'Volgende' : 'Vorige';

                    scope.click = function() {
                        if(this.direction === 'next')
                            scope.nextPage();
                        else
                            scope.prevPage();
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        dataChange();
                    });


                }
            };
        }
    ]);
});
