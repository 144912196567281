define('components/dossier/text-input/directive',[
    'angular',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.TextInput', [
            'Twensoc.Config',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbTextInput', [
        'config',
        function(config) {
            return {
                restrict: "E",
                scope: {
                    dossier: '=data',
                    Name: '@name',
                    Data: '=value',
                    Label: '@label',
                    DynamicLabel: '=dynamicLabel',
                    PlaceHolder: '@placeholder',
                    Required: '=required',
                    MaxLength: '=maxLength',
                    MinLength: '=minLength',
                    Pattern: '@pattern',
                    ReadOnlyIf: '=readOnlyIf',
                    Explanation: '@explanation',
                    ShowIf: '=showIf',
                    templateId: '@templateId'
                },
                require: '^form',
                templateUrl: "app/components/dossier/text-input/text-input.html",
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.Required = _.castToBoolean(scope.Required);
                    scope.MaxLength = (scope.MaxLength > 0) ? scope.MaxLength : -1;
                    scope.MinLength = (scope.MinLength > 0) ? scope.MinLength : 0;
                    scope.Pattern = (scope.Pattern != null && scope.Pattern.length > 0) ? scope.Pattern : "";
                    scope.showExplanation = false;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;
                    scope.inputName = scope.Name + "-" + _.guid();

                    /**
                     * Listen to required changes, specifically for the termination service when
                     * a ref is required depends on the company service to terminate.
                     * @type {any}
                     */
                    var requiredWatcher = scope.$watch("Required", function(value) {
                        if(value == null) return;
                        scope.Required = _.castToBoolean(value);
                    });

                    /**
                     * Listen to required changes, specifically for the termination service when
                     * a ref is required depends on the company service to terminate.
                     * @type {any}
                     */
                    var dynamicLabelWatcher = scope.$watch("DynamicLabel", function(value) {
                        scope.DynamicLabel = value;
                    });

                    scope.getLabel = function() {
                        return scope.DynamicLabel == null ? scope.Label : scope.DynamicLabel;
                    };

                    scope.getPlaceHolder = function() {
                        return scope.DynamicLabel == null ? scope.PlaceHolder : scope.DynamicLabel;
                    };


                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Function that checks if this input has a specific error.
                     * If this is the case the error is displayed to the user.
                     * @param {string} errorName
                     * @returns {boolean}
                     */
                    scope.hasError = function(errorName) {
                        if(formCtrl[scope.inputName] === undefined) {
                            // Skip problem with bank . iban field
                            return false;
                        }
                        var value = _.getByString(formCtrl[scope.inputName].$error, errorName);
                        return ((formCtrl.$submitted === true || formCtrl[scope.inputName].$dirty === true) && value);
                    };

                    /**
                     * Show/hide an optional explanation
                     */
                    scope.toggleExplanation = function() {
                        scope.showExplanation = !scope.showExplanation;
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                        requiredWatcher();
                        dynamicLabelWatcher();
                    });
                }
            };
        }
    ]);
});
