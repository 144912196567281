/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/notificationTpl-resource',[
	'angular',
	'lodash',
	'js-data'
], function (ng, _) {
	'use strict';

	return ng.module(
		'NotificationTplResource', [
			'js-data'
		]
	).factory('NotificationTplResource', [
		'$rootScope',
		'$q',
		'DS',
		function ($rootScope, $q, DS) {

			var NotificationTplResource = DS.defineResource({
				name: "NotificationTpl",
				endpoint: '/notificationTpl/',
				defaultValues: {
					id: null,
					name: '',
					systemType: 0,
					monthsAfter: 0,
					daysAfter: 0,
					cond: '',
					testWithDossier: 0
				}
			});

			return NotificationTplResource;
		}
	]);
});
