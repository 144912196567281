/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 15:08
 * For Project: fairplay
 * @version : 0.1
 */

define('components/header/directive',[
    'angular',
    'collection/toast-messages'
], function (ng) {
    'use strict';

    return ng.module(
        'Twensoc.components.tsHeader', [
            'NabesFront.utils.Authentication',
            'NabesFront.collection.ToastMessagesCollection',
            'Twensoc.Config',
            'DossierResource'
        ]
    ).directive('tsHeader', [
        'Authentication',
        'config',
		'$state',
		'$rootScope',
        '$timeout',
        'toastMessages',
        'DossierResource',
        function(Authentication, config, $state, $rootScope, $timeout, toastMessages, DossierResource) {
            return {
                restrict: "E",
                templateUrl: "app/components/header/header.html",
                scope: {
                    User: '=user'
                },
                link: function(scope, elem, attr) {
                    scope.Authentication = Authentication;
                    scope.Config = config;
                    scope.showToggleSwitch = (window.innerWidth < 992);
                    scope.Dossier = null;

                    /**
                     * Function that sends an event to toggle the visibility state of the sidebar menu.
                     * This event is handled by the sidebar.
                     */
                    scope.toggleMenu = function() {
                        $rootScope.$emit('Nabes.sidebar.showMenu');
                    };

                    /**
                     * Function that causes the showToggleSwitch boolean to be toggled.
                     * Causing the element to hide/show on the page. This function can also be called with a boolean value
                     * this then forces a particular state.
                     * @param {boolean|undefined} [boolean]
                     */
                    scope.toggleToggleSwitch = function(boolean) {
                        $timeout(function() {
                            if(boolean !== undefined) {
                                scope.showToggleSwitch = boolean;
                                return;
                            }
                            scope.showToggleSwitch = !scope.showToggleSwitch;
                        });
                    };

                    var toggleMenuEvent = $rootScope.$on('Nabes.header.showToggleSwitch', function(event, boolean) {
                        scope.toggleToggleSwitch(boolean);
                    });

                    var activeChangeWatcher = scope.$watch(function() {
                        return DossierResource.getActive();
                    }, function(v) {
                        scope.Dossier = v && v.dossier ? v.dossier : null;
                    });


					scope.goToContact = function (dossierId) {
						if (dossierId === 0 || dossierId == null) {
							$state.go('locale.app.contact', {});
						} else {
							$state.go('locale.app.dossier.overview.contact', {uuid: dossierId});
						}
					};

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        toggleMenuEvent();
                        activeChangeWatcher();
                        scope.Dossier = null;
                    });
                }
            };
        }
    ]);
});
