/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/password-reset/controller',[
    'angular',
    'lodash',
    'components/header/directive', //header directive
    'components/footer/directive', //footer directive
    'utils/authentication', //authentication service
    'config',
    'collection/toast-messages' //toast messages collection
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.PasswordResetController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter',
            'NabesFront.utils.Authentication',
            'NabesFront.collection.ToastMessagesCollection'
        ]
    ).controller(
        'PasswordResetController', [
            '$state',
            '$stateParams',
            '$timeout',
            '$scope',
            '$rootScope',
            'config',
            'Authentication',
            '$http',
            'toastMessages',
            function ($state, $stateParams, $timeout, $scope, $rootScope, config, Authentication, $http, toastMessages) {
                $scope.Config = config;
                $rootScope.Title = "Nabes - Wachtwoord vergeten";
                $rootScope.BodyClass = "password-reset";

                $scope.msgSend = false;

                $scope.token = $stateParams.data.token;
                $scope.formInfo = $stateParams.data.formInfo;


                $scope.hasToken = function() {
                    return !_.isEmpty($scope.token);
                };

                $scope.submit = function (formData) {
                    var me = this, data = {}, form = me.PasswordResetForm;

                    //check if the form is valid or is already being submitted if so return and do not do anything
                    if(form.$valid === false || form.submitting === true) return;

                    //start submitting the form showing the loader to the user.
                    form.submitting = true;

                    if(!me.hasToken()) {
                        if(_.isEmpty(form.email.$viewValue)) {
                            form.email.$error.required = true;
                            form.$valid = false;
                            form.submitting = false;
                        }
                        data = { email: form.email.$viewValue };
                    } else {
                        if(_.isEmpty(form.password.$viewValue )) {
                            form.email.$error.required = true;
                            form.$valid = false;
                        }
                        if(_.isEmpty(form.password2.$viewValue )) {
                            form.email.$error.required = true;
                            form.$valid = false;
                        }
                        if(form.password.$viewValue !== form.password2.$viewValue) {
                           form.password.$error.nonEqual = true;
                            form.$valid = false;
                        }
                        data = {
                            token: me.token,
                            password: form.password.$viewValue
                        };
                    }

                    if(form.$valid) {
                        $http.post(config.getAPIUrl(me.hasToken() ? 'token' : 'reset-password'), data).
                            then(function(response) {
                                if(response.data) {
                                    if(response.data.success === false) {
                                        form.submitting = false;
                                        me.msgSend = true;
                                        $state.go('locale.app');

                                    } else if(response.data.success === true) {
                                        form.submitting = false;
                                        me.msgSend = true;
                                        toastMessages.add(toastMessages.T_INFO, '', 'pwresetForm.'+$scope.formInfo+'.successMsg', 8000);
                                        $state.go('locale.login');
                                    }
                                }
                            }, function(response) {
                               alert('failed. do something');
                            });
                    }
                };



                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
