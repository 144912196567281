/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/login/controller',[
	'angular',
	'lodash',
	'components/header/directive', //header directive
	'components/footer/directive', //footer directive
	'utils/authentication', //authentication service
	'config'
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.controllers.LoginController', [
			'Twensoc.Config',
			'Twensoc.components.tsHeader',
			'Twensoc.components.tsFooter',
			'NabesFront.utils.Authentication'
		]
	).controller(
		'LoginController', [
			'$log',
			'$state',
			'$timeout',
			'$scope',
			'$rootScope',
			'$stateParams',
			'config',
			'Authentication',
			function ($log, $state, $timeout, $scope, $rootScope, $stateParams, config, Authentication) {
				$scope.Config = config;
				$rootScope.Title = "Login";
				$rootScope.BodyClass = "login";




				/**
				 * Function that submits the login details to the authentication service.
				 * This service then has to validate the details and if they are correct this controller redirects the user to the overview controller.
				 * @param {{Email: String, Password: String}} LoginDetails
				 */
				$scope.submit = function (LoginDetails) {
					var me = this;

					//reset properties on LoginForm
					me.LoginForm.InvalidCredentials = false;

					//check if the form is valid or is already being submitted if so return and do not do anything
					if (me.LoginForm.$valid === false || me.LoginForm.submitting === true) return;

					//start submitting the form showing the loader to the user.
					me.LoginForm.submitting = true;

					Authentication.doLogin(LoginDetails.Email, LoginDetails.Password, LoginDetails.Remember).then(function () {
						//credentials are correct stop the loader and redirect the user
						if (me.LoginForm) {
							me.LoginForm.submitting = false;
						}

						if ($stateParams.toState !== null && $stateParams.toParams !== null) {
							return void $state.go($stateParams.toState.name, $stateParams.toParams, {location: 'replace'});
						}

						$state.go('locale.app.dossier', {Locale: config.getUrlPrefix()});
					}, function () {
						//credentials are incorrect stop the loader and show invalid credentials error.
						me.LoginForm.submitting = false;
						me.LoginForm.InvalidCredentials = true;
					});
				};

				$scope.getCookie = function (name) {
					var value = "; " + document.cookie;
					var parts = value.split("; " + name + "=");
					if (parts.length === 2) {
						return parts.pop().split(";").shift();
					}
					else {
						return "";
					}
				};



				$scope.logo = $scope.getCookie("emailLogo");
				$scope.logoExists = function(){
						return $scope.logo!=="";
				};

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
				});
			}
		]
	);
});
