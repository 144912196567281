/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 15:08
 * For Project: fairplay
 * @version : 0.1
 */

define('components/sidebar/directive',[
	'angular',
	'model/dossier-resource' //dossier resource
], function (ng) {
	'use strict';

	return ng.module(
		'Twensoc.components.tsSidebar', [
			'Twensoc.Config',
			'DossierResource',
			'CompanyResource',
			'NabesFront.utils.Authentication',
			'NabesFront.collection.ToastMessagesCollection',
			'NabesFront.model.UserModel'
		]
	).directive('tsSidebar', [
		'config',
		'$state',
		'$location',
		'$window',
		'$timeout',
		'$rootScope',
		'DossierResource',
		'CompanyResource',
		'$parse',
		'Authentication',
		'toastMessages',
		'UserModel',
		function (config, $state, $location, $window, $timeout, $rootScope, DossierResource, CompanyResource, $parse, Authentication, toastMessages, UserModel) {
			return {
				restrict: "E",
				templateUrl: "app/components/sidebar/sidebar.html",
				link: function (scope, elem, attr) {
					scope.Config = config;

					scope.showMenu = (window.innerWidth > 992);
					scope.showLogoutButton = (window.innerWidth < 992);

					scope.chapters = [];
					scope.dossier = {};
					scope.isHist = false;

					scope.isAdmin = Authentication.hasRole('ADMIN');
					scope.pageSlug = '';
					scope.chapterSlug = '';
					scope.companyInfoAvailable = false;
					scope.companyEmailLogo = false;

					/**
					 * Watch the DossierResource.getActive() to check if the currently active is set / changed
					 * If this happens we check if its not undefined and if this is not the case the chapters are shown within the sidebar.
					 * @type {*|function()}
					 */
					var activeChangeWatcher = scope.$watch(function () {
						return DossierResource.getActive();
					}, function (newValue) {
						//if its not set reset the chapters to an empty array and return
						if (newValue === undefined) {
							//we add a timeout here to prevent it from blinking between page changes within a dossier.
							$timeout(function () {
								if (DossierResource.getActive() !== undefined) return;
								scope.chapters = [];
								scope.dossier = {};

								scope.pageSlug = '';
								scope.__chapterSlug = '';
							});
							return;
						}

						//else get the chapters
						var active = DossierResource.getActive();
						scope.dossier = active.dossier;
						scope.pageSlug = active.pageSlug;
						scope.chapterSlug = active.chapterSlug;
						scope.isHist = scope.dossier.isHist;

						console.log("checking company info");

						if (scope.dossier.companyId !== 0 && scope.dossier.companyId != null) {
							console.log("hoi "+scope.dossier.companyId);
							// Load company info to determine whether to show the 'More info' menu item or not

							CompanyResource.getEmailLogo(scope.dossier.companyId).then(function (emailLogo) {
								if (emailLogo == null) {
									scope.companyEmailLogo = "";
								} else {
									scope.companyEmailLogo = emailLogo;
								}

								console.log("emailLogo for company = " + scope.companyEmailLogo);

								document.cookie = "emailLogo="+scope.companyEmailLogo+"; path=/";

							}).catch(function (error) {
								console.error(error);
							});

							CompanyResource.hasInformation(scope.dossier.companyId).then(function (infoAvailable) {
								scope.companyInfoAvailable = infoAvailable;
								console.log("company info avalable");
							}).catch(function (error) {
								console.error(error);
							});

						}else{
							console.log("company info NOT avalable");
						}

						// Shortcut for dossier.data = dd to simplify formula's in template
						scope.dd = scope.dossier.data;

						// TODO: Cache dossierTplDef for admins somehow - It's loaded on every page change now
						DossierResource.load(scope.dossier.id).then(function (data) {
							scope.chapters = data.dossierTplDef.getChapters();

							var chapter = _.find(scope.chapters, {properties: {slug: scope.chapterSlug}});

							if (!scope.chapterEnabled(chapter)) {
								toastMessages.add(toastMessages.T_DANGER, '', 'failure.chapter-disabled', 8000);
								scope.navigateToList();
							}

						});

						//scope.dossier.getTemplate().then(function(result) {
						//    scope.chapters = result.templateDef.getChapters();
						//});
					});

					if(UserModel.hasRole('BGO')){
						CompanyResource.hasInformation(UserModel.companyId).then(function (infoAvailable) {
							scope.companyInfoAvailable = infoAvailable;
							console.log("company info avalable");});
					}

					scope.gotoCompanyPage = function () {
						if (Authentication.hasRole('ADMIN')) {
							$state.go('locale.app.company');
						} else {
							$state.go('locale.app.company.edit', {companyId: UserModel.companyId});
						}
					};



					scope.gotoCompanyInfo = function () {
							$state.go('locale.app.dossier.overview.companyInfo', {uuID: UserModel.companyId});
					};

					scope.gotoInfoPage = function () {

						$state.go('locale.app.info');

					};


					scope.navigateToList = function (dossierId) {
						$state.go('locale.app.dossier.overview', {uuid: dossierId});
					};

					scope.goToSettings = function (dossierId) {
						if (dossierId === 0 || dossierId == null) {
							$state.go('locale.app.settings', {});
						} else {
							$state.go('locale.app.dossier.overview.account_settings', {uuid: dossierId});
						}
					};

					scope.goToContact = function (dossierId) {
						if (dossierId === 0 || dossierId == null) {
							$state.go('locale.app.contact', {});
						} else {
							$state.go('locale.app.dossier.overview.contact', {uuid: dossierId});
						}
					};

					scope.goToInformation = function (dossierId) {
						if (dossierId === 0 || dossierId == null) {
							$state.go('locale.app.contact', {});
						} else {
							$state.go('locale.app.dossier.overview.contact', {uuid: dossierId});
						}
					};

					/**
					 * Function that redirects the user to the specific chapter without a pageSlug specified.
					 * This fix is implemented to allow the state to appear active no matter the page slug (see ui-sref-active + ui-sref) while allowing the user to
					 * go to the state with an unspecified pageSlug state param to prevent problems within the states controller.
					 * @param {Event} $event
					 * @param {string} dossierId
					 * @param {String} chapterSlug
					 */
					scope.goToChapter = function ($event, dossierId, chapter) {
						$event.preventDefault();
						if (scope.chapterEnabled(chapter)) {
							$state.go('locale.app.dossier.overview.edit', {
								uuID: dossierId,
								chapterSlug: chapter.properties.slug,
								pageSlug: null
							});
						}
					};

					/**
					 * Function that redirects the user to the specific chapter without a pageSlug specified.
					 * This fix is implemented to allow the state to appear active no matter the page slug (see ui-sref-active + ui-sref) while allowing the user to
					 * go to the state with an unspecified pageSlug state param to prevent problems within the states controller.
					 * @param {Event} $event
					 * @param {string} dossierId
					 * @param {String} chapterSlug
					 */
					scope.goToPage = function ($event, dossierId, chapter, pageSlug) {
						$event.preventDefault();
						if (chapter == null) return;
						if (scope.chapterEnabled(chapter)) {
							$state.go('locale.app.dossier.overview.edit', {
								uuID: dossierId,
								chapterSlug: chapter.properties.slug,
								pageSlug: pageSlug
							});
							return;
						}

						if(chapter === 'opzeg-service') {
							toastMessages.add(toastMessages.T_WARNING, '', 'Sidebar.ServiceChapterDisabledToast', 4000);
						}
					};

					/**
					 * Returns a boolean whether or not the page is visible, based on the condition given in the page.
					 * A non-visible page should not be visible in the sidebaar (or grayed-out for example)
					 *
					 * @param page The page
					 * @return {boolean} True if the page's condition is true, false if not
					 */
					scope.showPage = function (page) {
						if (page.properties.showIf === true) {
							return true;
						}
						return $parse(page.properties.showIf)(scope);
					};

					/**
					 * Returns a boolean whether or not the chapter is visible, based on the condition given in the page.
					 * A non-visible chapter should not be visible in the sidebaar (or grayed-out for example)
					 *
					 * @param chapter The chapter
					 * @return {boolean} True if the chapter's condition is true, false if not
					 */
					scope.chapterVisible = function (chapter) {
						var showIf = chapter.properties.showIf;
						return showIf === true || $parse(showIf)(scope);
					};

					scope.chapterEnabled = function (chapter) {
						if (!chapter || !chapter.properties) return true;
						var enabledIf = chapter.properties.enabledIf;
						return enabledIf === true || $parse(enabledIf)(scope);
					};

					scope.isChapterCompleted = function (chapterSlug) {
						if (scope.chapters.length === 0) return false;
						var chapter = _.find(this.chapters, {"properties": {"slug": chapterSlug}});
						return chapter && scope.dossier.chapterProgress(chapter).progress == 100;
					};

					scope.getChapter = function (chapterSlug) {
						if (scope.chapters != null) {
							return _.find(scope.chapters, {properties: {slug: chapterSlug}});
						}
						return null;
					};

					/**
					 * Bind an event listener to the window resize event.
					 * Each time the resize event is triggered check if we still need to show a hamburger menu switch and menu dropdown style.
					 */
					ng.element($window).bind('resize', function () {
						scope.showLogoutButton = (window.innerWidth < 992);
						scope.toggleToggleSwitch((window.innerWidth < 992));
						scope.showMenu = (window.innerWidth > 992);
						scope.$apply();
					});

					/**
					 * Function that toggles the toggle switch.
					 * Meaning an event is send to the header directive, telling it to show / hide (based on the previous state or specified boolean)
					 * the toggle switch.
					 * @param boolean
					 */
					scope.toggleToggleSwitch = function (boolean) {
						$rootScope.$emit('Nabes.header.showToggleSwitch', boolean);
					};

					/**
					 * Function that toggles the menu showing / hiding it.
					 * This is always called by the Nabes.sidebar.showMenu event listener.
					 * You can also pass a boolean to force a particular state.
					 * @param {boolean|undefined} [boolean]
					 */
					scope.toggleMenu = function (boolean) {
						if (boolean !== undefined) {
							scope.showMenu = boolean;
							return;
						}
						scope.showMenu = !scope.showMenu;
					};

					/**
					 * Function that causes the sidebar menu to be shown.
					 * This is triggered by the specified event.
					 * @type {*|function()}
					 */
					var showMenuEvent = $rootScope.$on('Nabes.sidebar.showMenu', function (event) {
						scope.toggleMenu();
					});

					/**
					 * When the UI router state changes its best to hide the menu since the user probably has clicked on it.
					 * @type {function()}
					 */
					var stateChange = $rootScope.$on('$stateChangeStart', function (event) {
						scope.toggleMenu((window.innerWidth > 992));
					});


					// if ( UserModel.companyId >0 ) {
					// 	// Load company info to determine whether to show the 'More info' menu item or not
					// 	CompanyResource.hasInformation(UserModel.companyId).then(function (infoAvailable) {
					// 		scope.companyInfoAvailable = infoAvailable;
					// 	});
					// }

					scope.toDocuments = function (dossierId) {
						$state.go('locale.app.dossier.overview.document', {uuID: dossierId, pageID: 1});
					};

					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					scope.$on('$destroy', function () {
						showMenuEvent();
						stateChange();
						activeChangeWatcher();
					});
				}
			};
		}
	]);
});
