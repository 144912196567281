/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/account-resource',[
    'angular',
    'lodash',
    'js-data'
], function (ng, _) {
    'use strict';

    return ng.module(
        'AccountResource', [
            'js-data'
        ]
    ).factory('AccountResource', [
            '$rootScope',
            '$q',
            '$log',
            'DS',
            function($rootScope, $q, $log, DS) {
                var AccountResource = DS.defineResource({
                    name:"Account",
                    endpoint: '/account/',
                    defaultValues: {
                        id: null,
                        firstName: '',
                        gender: '',
                        infix: '',
                        surname: '',
                        email: '',
                        companyId: 0,
                        role: 'USER',
                        locale: 'nl_NL',
                        phone: ''

                    },
                    /**
                     * Filter the accounts by company ID
                     * This returns a promise that can be resolved later on.
                     * @param companyId
                     * @returns {*}
                     */
                    findAllByCompany: function(params, options) {
                        return this.findAll(params, options);
                    },
                    methods: {
                        fullName: function () {
                            return [this.firstName, this.infix, this.surname].join(" ");
                        }
                    }
                });

                $log.info("AccountResource defined");
                return AccountResource;
            }
        ]);
});
