/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/termination-resource',[
    'angular',
    'lodash',
    'js-data'
], function (ng, _) {
    'use strict';

    return ng.module(
        'TerminationResource', [
            'js-data'
        ]
    ).factory('TerminationResource', [
            '$rootScope',
            '$q',
            'DS',
            function($rootScope, $q, DS) {

                var TerminationResource = DS.defineResource({
                    name:"Termination",
                    endpoint: '/termination/',
                    defaultValues: {
                        id: null,
                        name: '',
                        department: '',
                        phone: '',
                        email: '',
                        site: '',
                        v_address: '',
                        v_zipcode: '',
                        v_city: '',
                        plainText: '',
                        htmlText: '',
                        type: 0,
                        information: '',
                        refRequired: false,
                        emailTermination: false,
                        refLabel: null,
						isHist: 0
					},

                    getInformation: function(id) {
                        var record = this.get(id);

                        if (record == null) {
                            return this.find(id, {bypassCache: true});
                        }

                        var deferred = $q.defer();
                        deferred.resolve(record);
                        return deferred.promise;
                    }
                });

                return TerminationResource;
            }
        ]);
});
