/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/terms-and-conditions/controller',[
    'angular',
    'lodash',
    'model/dossier-resource',
    'components/header/directive', //header directive
    'model/user', //user model
    'components/footer/directive' //footer directive
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.TermsAndConditionsController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter',
            'NabesFront.model.UserModel',
            'DossierResource'
        ]
    ).controller(
        'TermsAndConditionsController', [
            '$state',
            '$scope',
            '$rootScope',
            '$location',
            '$q',
            '$http',
            'config',
            'UserModel',
            'DossierResource',
            function ($state, $scope, $rootScope, $location, $q, $http, config, UserModel, DossierResource) {
                $scope.Config = config;
                $scope.User = UserModel;
                $rootScope.Title = "Algemene voorwaarden";
                $rootScope.BodyClass = "page-content-html";
                $scope.pageContent = "Loading...";

                // Do not display dossier's sidemnu if clicked from within dossier
                DossierResource.unsetActive();

                // Load requested document
                $scope.loadContent = function(path) {
                    var me = this;
                    var deferred = $q.defer();

                    $http.get(config.getAPIUrl('content'), {params: {path: path}})
                        .success(function (data, status, headers, config) {
                            deferred.resolve(data);
                        })
                        .error(function (data, status, headers, config, statusText) {
                            $state.go('locale.404', {Locale: $scope.Config.getUrlPrefix()});
                        });
                    return deferred.promise;
                };

                console.log($location.path());
                $scope.loadContent($location.path()).then(function(content) {
                    $scope.pageContent = content;
                    console.log(content);
                }, function(error) {
                    alert(error);
                });

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});

