/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-7-2015
 * Time: 15:05
 * For Project: nabes-front
 * @version : 0.1
 */

define('model/user',[
    'angular',
    'lodash',
    'utils/vertx-client' //vertx client
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.model.UserModel', [
            'Twensoc.utils.VertxClient'
        ]
    ).factory('UserModel', [
        '$q',
        '$rootScope',
        '$filter',
        'config',
        'VertxClient',
        function($q, $rootScope, $filter, config, VertxClient) {
            function User(attrs) {
                ng.extend(this, attrs);
                this.init();
            }

            ng.extend(User.prototype, {
                firstName: '',
                infix: '',
                email: '',
                surname: '',
                role: 'GUEST',
                gender: '',
                locale: 'nl_NL',
                companyId: 0,
                notification: 2,
                lastLoginDateTime: '',
                _loaded: false,
                init: function() {
                    var me = this;
                    $rootScope.$on('User.apply.change', function(event, applyChange, change) {
                        applyChange.call(me, change);
                    });
                },
                /**
                 * Function that retrieves the full name for the frontend.
                 * @returns {string}
                 */
                getFullName: function() {
                    return this.firstName + (_.isEmpty(this.infix) ? "" : " " + this.infix) + " " + this.surname;
                },

                /**
                 * Returns the last login date and time
                 */
                getLastLoginDateTime: function() {
                    return $filter('date')(Date.parse(this.lastLoginDateTime), 'dd-MM-yyyy HH:mm', 0);
                },
                /**
                 * Function that sets the data on this model.
                 * @param {{}} data
                 */
                set: function(data) {
                    ng.copy(data, this);
                    this._loaded = true;
                    $rootScope.$emit('User.Loaded');
	                //var me = this;
	                //ng.copy(data, me);
	                //me._loaded = true;
	                //$rootScope.$emit('User.Loaded');
                },
                /**
                 * Function with which you can wait for the user model to be loaded.
                 * This is useful if you early on in the applications life cycle want to talk with the user object and have to be sure
                 * values from the backend have already been set yes or no.
                 * @returns {deferred.promise|{then, always}}
                 */
                isLoaded: function() {
                    var me = this;
                    var deferred = $q.defer();

                    //if its already loaded resolve immediately
                    if(me._loaded === true) {
                        deferred.resolve();
                        return deferred.promise;
                    }

                    var handler = $rootScope.$on('User.Loaded', function() {
                        deferred.resolve();
                        handler();
                    });
                    return deferred.promise;
                },
                /**
                 * Function that writes the current state of the USER model to the backend
                 * Saving any modifications that might have taken place. 
                 * @returns {*|promise}
                 */
                save: function() {
                    var deferred = $q.defer();
                    VertxClient.send('/account/update', this).then(function (data) {
                        deferred.resolve(data);
                    }, function (err) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                },
                /**
                 * Function that determines if the user has the specified role.
                 * If he/she does not false is returned else true is returned.
                 * @param {string|string[]} role
                 * @returns {boolean}
                 */
                hasRole: function(role) {
                    if(_.isArray(role)) {
                        for(var i = 0; i<role.length; i++) {
                            if(role[i] === this.role) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return (role === this.role);
                }
            });

            return new User();
        }
    ]);
});
