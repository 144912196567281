define('components/dossier/send-access-invite/directive',[
	'angular',
	'lodash',
	'components/dossier/base',
	'collection/toast-messages' //toast messages collection
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.components.dossier.SendAccessInviteInput', [
			'Twensoc.Config',
			'NabesFront.collection.ToastMessagesCollection',
			'NabesFront.components.dossier.BaseComponent'
		]
	).directive('nbSendAccessInvite', [
		'config',
		'$timeout',
		'toastMessages',
		function (config, $timeout, toastMessages) {
			return {
				restrict: "E",
				scope: {
					dossier: '=data'
				},
				require: '^form',
				templateUrl: "app/components/dossier/send-access-invite/send-access-invite.html",
				link: function (scope, elem, attr, dossierFormCtrl) {
					scope.Config = config;
					scope.Data = {gender: "", firstName: "", infix: "", surname: "", email: ""};

					scope.MaxEmailLength = 60;
					scope.MaxFirstNameLength = 40;
					scope.MaxInfixLength = 10;
					scope.MaxSurnameLength = 60;
					scope.MaxPhoneLength = 25;

					//remove this form from the parent form to prevent the validation of this form to matter for the next button.
					dossierFormCtrl.$removeControl(scope.accessInviteForm);
					var _handler = scope.accessInviteForm.$setValidity;
					scope.accessInviteForm.$setValidity = function (validationErrorKey, isValid, cntrl) {
						_handler(validationErrorKey, isValid, cntrl);
						dossierFormCtrl.$setValidity(validationErrorKey, true, this);
					};

					/**
					 * Function that invites the user to gain access to this dossier.
					 * @param {{email: string, firstName: string: surName: string, infix: string|undefined}} data
					 */
					scope.inviteUser = function (data) {
						if (!scope.accessInviteForm.$valid) return;
						scope.dossier.sendInvite(data.email, data.firstName, data.infix, data.surname, data.gender, data.phone).then(function () {
							var toastData = {};
							ng.copy(data, toastData);
							toastMessages.add(toastMessages.T_SUCCESS, 'SendAccessInviteInput.ToastMessages.InviteSendTitle', 'SendAccessInviteInput.ToastMessages.InviteSendContent', 8000, {data: toastData});
							scope.Data = {gender: "", firstName: "", infix: "", surname: "", email: "", phone: ""};
							scope.accessInviteForm.$submitted = false;
							scope.accessInviteForm.$setPristine();
							scope.accessInviteForm.$setUntouched();
						}, function (reason) {
							scope.inviteSend = false;
							switch (reason) {
								//already exists
								case 'exists':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.AlreadyExistsTitle', 'SendAccessInviteInput.ToastMessages.AlreadyExistsContent', 8000, {email: data.email});
									break;
								//server error
								case 'server':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.ServerErrorTitle', 'SendAccessInviteInput.ToastMessages.ServerErrorContent', 8000);
									break;
								//model does not exist yet first the dossier has to be saved once to be able to invite people to it requires an id!
								case 'does-not-exist':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.DoesntExistTitle', 'SendAccessInviteInput.ToastMessages.DoesntExistContent', 8000);
									break;
								//generic message
								default:
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.GenericErrorTitle', 'SendAccessInviteInput.ToastMessages.GenericErrorContent', 8000);
							}
						});
					};

					/**
					 * Function that checks if this input has a specific error.
					 * If this is the case the error is displayed to the user.
					 * @param {string} fieldName
					 * @param {string} errorName
					 * @returns {boolean}
					 */
					scope.hasError = function (fieldName, errorName) {
						var value = _.getByString(scope.accessInviteForm[fieldName].$error, errorName);
						return ((scope.accessInviteForm.$submitted === true || scope.accessInviteForm[fieldName].$dirty === true) && value);
					};

					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					scope.$on('$destroy', function () {
					});
				}
			};
		}
	]);
});
