/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/termination/form/controller',[
    'angular',
    'lodash',
    'codemirror',
    'codemirror/addon/edit/matchbrackets',
    'codemirror/addon/edit/closetag',
    'codemirror/addon/display/fullscreen',
    'codemirror/addon/dialog/dialog',
    'codemirror/addon/search/searchcursor',
    'codemirror/addon/search/search',
    'codemirror/addon/lint/lint',
    'codemirror/mode/htmlmixed/htmlmixed',
    'uiCodemirror',
    'model/termination-resource', //termination resource
    'model/notificationTpl-resource' //notificationTpl resource
], function (ng, _, codeMirror) {
    'use strict';

    window.CodeMirror = codeMirror;

    return ng.module(
        'NabesFront.controllers.TerminationFormController', [
            'Twensoc.Config',
            'NabesFront.collection.ToastMessagesCollection',
            'TerminationResource',
            'NabesFront.model.UserModel',
            'ui.codemirror'
        ]
    ).controller(
        'TerminationFormController', [
            '$log',
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            'TerminationResource',
            'UserModel',
            'toastMessages',
            function ($log, $state, $stateParams, $scope, $rootScope, config, TerminationResource, UserModel, toastMessages) {
                $scope.Config = config;
                $rootScope.Title = "Termination form pagina";
                $rootScope.BodyClass = "termination";

                $scope.model = {};

                $scope.editorOptions = {

                    // parserfile: "lib/parsefreemarker.js",
                    // stylesheet: "lib/freemarkercolors.css",
                    // // path: "../../js/",
                    // continuousScanning: 500,
                    // autoMatchParens: true,
                    // markParen: function(node, ok) {
                    //     node.style.backgroundColor = ok ? "#CCF" : "#FCC#";
                    //     if(!ok) {
                    //         node.style.color = "red";
                    //     }
                    // },
                    // unmarkParen: function(node) {
                    //     node.style.backgroundColor = "";
                    //     node.style.color = "";
                    // },
                    // indentUnit: 4,

                    lineWrapping: true,
                    lineNumbers: true,
                    matchBrackets: true,
                    autoCloseTags: true,
                    mode: 'htmlmixed',
                    theme: 'eclipse',
                    height: 200,
                    extraKeys: {
                        "F11": function (cm) {
                            cm.setOption("fullScreen", !cm.getOption("fullScreen"));
                        },
                        "Esc": function (cm) {
                            if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);

                        },
                        "Ctrl-S": function (cm) {
                            $scope.ok($scope.form);
                            return false;
                        },
                        "Cmd-S": function (cm) {
                            $scope.ok($scope.form);
                            return false;
                        }
                    }
                };

                /**
                 * Function that checks if this input has a specific error.
                 * If this is the case the error is displayed to the user.
                 * @param {string} fieldName
                 * @param {string} errorName
                 * @returns {boolean}
                 */
                $scope.hasError = function(fieldName, errorName) {
                    var value = _.getByString($scope.form[fieldName].$error, errorName);
					return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
				};


                $scope.hasChanges = function() {
                    return true;//!angular.equals($scope.model, $scope.origModel);
                };

                $scope.isValid = function(form) {
                    return !form.$invalid;
                };

                $scope.ok = function(form) {
                    if(form.$invalid) {
                        return;
                    }

                    if(!$scope.hasChanges()) {
                        $scope.navigateToList();
                        return;
                    }

                    if($scope.model.id == null) {
                        return TerminationResource.create($scope.model).then(function (data) {
                            TerminationResource.inject(data);
                            $scope.navigateToList();
                        }, $scope.failureHandler);

                    } else {
                        TerminationResource.update($scope.model.id, $scope.model).then(function(data) {
                            angular.merge($scope.model, data);
                            $scope.navigateToList();
                        }, $scope.failureHandler);
                    }

                };

                $scope.handleFailure = function(err) {
                    if(err == null) err = 'unknown-error';
                    if(err.message) err = err.message.toLowerCase().replace(" ", "-");
                    toastMessages.add(toastMessages.T_DANGER, '', 'failure.'+err, 4000);
                };
                $scope.failureHandler = $scope.handleFailure.bind($scope);


                $scope.cancel = function() {
                    $scope.navigateToList();
                };

                $scope.navigateToList = function() {
                    $state.go('locale.app.termination', {Locale: config.getUrlPrefix()});
                };

                if($stateParams.uuID === 0) {
                    $scope.model = TerminationResource.createInstance();
                    $scope.header = 'Nieuwe service';

                } else {
                    TerminationResource.find($stateParams.uuID).then(function (termination) {
                        $scope.model = termination;
                        $scope.model.type = ''+$scope.model.type;
                    }, function (msg) {
                        $scope.navigateToList();
                        $scope.handleFailure(msg);
                    });
                }


                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                    $scope.failureHandler = null;
                });
            }
        ]
    );
});
