/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/dossier-resource',[
	'angular',
	'lodash',
	'js-data',
	'model/dossier-template-resource', //dossier template resource
	'utils/vertx-client',
	'collection/toast-messages' //toast messages collection
], function (ng, _) {
	'use strict';

	return ng.module(
		'DossierResource', [
			'Twensoc.Config',
			'Twensoc.utils.VertxClient',
			'NabesFront.model.UserModel',
			'js-data',
			'DossierTemplateResource',
			'DossierTemplateDefinitionResource',
			'DossierDocumentResource',
			'TemplateInterpreter',
			'NabesFront.utils.Authentication',
			'NabesFront.collection.ToastMessagesCollection'
		]
	).factory('DossierResource', [
			'$rootScope',
			'$state',
			'$q',
			'$log',
			'$parse',
			'DS',
			'VertxClient',
			'DossierTemplateResource',
			'DossierTemplateDefinitionResource',
			'DossierDocumentResource',
			'$http',
			'config',
			'TemplateInterpreter',
			'UserModel',
			'Authentication',
			'toastMessages',
			function ($rootScope, $state, $q, $log, $parse, DS, VertxClient, DossierTemplateResource, DossierTemplateDefinitionResource, DossierDocumentResource, $http, config, templateInterpreter, UserModel, Authentication, toastMessages) {

				var clientGuid = _.guid();

				var DossierResource = DS.defineResource({
					_active: undefined,
					name: "Dossier",
					endpoint: '/dossier/',
					handler: null,
					defaultValues: {
						id: 0,
						rev: 0,
						firstName: '',
						infix: '',
						surname: '',
						country: '',
						deceasedCity: '',
						deceasedDate: '',
						dossierTplId: 0,
						dossierTplDefId: 0,
                        dossierTplDefRev: 0,
						emailLogo: null,
						hasDossierAccount: 0,
						data: null,
						scratchPad: null,
                        siteSrc: 0,
						sendMail: false,
						isHist: 0
					},
					afterCreateInstance: function(resource, dossier) {
						if(dossier.data == null) dossier.data = {};
					},
					beforeInject: function (resource, obj) {
						// Deserialize dossier.data property
						if (_.isArray(obj)) {
							var i = obj.length;
							while (i--) {
								if (_.isString(obj[i].data)) {
									obj[i].data = JSON.parse(obj[i].data);
								}

								this.registerDossierListener(obj[i].id);
							}
						} else if (obj) {
							if (_.isString(obj.data)) {
								obj.data = JSON.parse(obj.data);
							}

							this.registerDossierListener(obj.id);
						}

					},
					beforeEject: function(resource, obj) {
						if (_.isArray(obj)) {
							var i = obj.length;
							while (i--) {
								this.unregisterDossierListener(obj[i].id);
							}
						} else if (obj) {
							this.unregisterDossierListener(obj.id);
						}
					},
					registerDossierListener: function(id) {
						if(!this.handler) {
							this.handler = this.onEvent.bind(this);
						}

						if(id != null) {
							//VertxClient._eventBus.registerHandler("/dossier-event/" + id, this.handler);
						}
					},
					unregisterDossierListener: function(id) {
						if(id != null && this.handler) {
							//VertxClient._eventBus.unregisterHandler("/dossier-event/" + id, this.handler);
						}
					},
					/**
					 * Handle a dossier-event that contains changes from this or other users.
					 * A dossier-event typically contains these fields:
					 * {
					 *  accountId: 1            (AccountId that caused the change)
					 *  data: { ... }           (Json structure containing the changed dossier fields or data)
					 *  dossierId: 1            (Id of the dossier)
					 *  dossierRev: 804         (Revision for the change)
					 *  type: "dossier-changed" (Type of change)
					 * }
					 * @param err
					 * @param msg
					 */
					onEvent: function(err, msg) {
						if(err != null) {
							console.log("Err: "+err);
							return;
						}
						// Prevent following now
						if(1==1) return;

						try {
							var data = msg.body,
								dossierChanges = data.data,
								newRev = data.dossierRev,
								type = data.type,
								dossierId = data.dossierId,
								model;

							// Do not show events generated from this client
							if(data.client == clientGuid) return;

							if(!dossierId) {
								var m = msg.address.match(/\/dossier-event\/(\d*)/);
								if(m) dossierId = parseInt(m[1]);
							}
							model = this.get(dossierId);

							switch (type) {
								case 'dossier-opened':
									break;
								case 'dossier-closed':
									break;
								case 'page-changed':
									if (model != null) {
										var page = data.chapterSlug;
										if(page == 'overview') {
											$state.go('locale.app.dossier.overview', {uuID: dossierId});
										} else if(page == 'document') {
											$state.go('locale.app.dossier.overview.document', {uuID: dossierId});
										} else if(page == 'events') {
											$state.go('locale.app.dossier.overview.events', {uuID: dossierId});
										} else {
											$state.go('locale.app.dossier.overview.edit', {uuID: dossierId, chapterSlug: data.chapterSlug, pageSlug: data.pageSlug});
										}
									}
									break;
								case 'dossier-changed':
									if (model != null) {
										if (model.rev == newRev) {
											// We're up to date. Probably send the change ourselves
										} else if (model.rev == newRev - 1) {
											// Accept the new change and we're up to date again
											angular.merge(model, dossierChanges);
											model.rev = newRev;
											this.notifyUser(model, data);
										} else {
											// TODO: We're several changes behind. Sync!
										}
									}
									break;
								case 'dossier-invited':
									// TODO: refresh access list
									//accountId: 1
									//accountName: "Owner ats Twensoc"
									//data: Object
									//email: "rvraaphorst@gmail.com"
									//dossierId: 1
									//dossierRev: 829
									//type: "dossier-invited"
									this.notifyUser(model, data);
									break;
								case 'dossier-invitation-accepted':
									// TODO: refresh access list
									this.notifyUser(model, data);
									break;
								default:

									break;
							}


						} catch (err) {
							console.info("Illegal data format: "+msg);
						}

					},
					notifyUser: function(model, data) {
						if (this._active && this._active.dossier.id == model.id) {
							// TODO: Name user who updated the dossier
							toastMessages.add(toastMessages.T_INFO, '', 'eventType.'+data.type, 600000, data);
						}
					},

					/**
					 * Set the currently active (viewed) dossier to the specified dossier model.
					 * Used by the sidemenu to determine which menuitems are available.
					 * This method is also used to detect selecting pages and notifying followers of
					 * these page changes
					 *
					 * @param model
					 */
					setActive: function (dossier, chapterSlug, pageSlug) {
						var me = this,
							prevActive = me._active ? _.clone(me._active) : null,
							newActive = {
								dossier: dossier,
								chapterSlug: chapterSlug,
								pageSlug: pageSlug
							};

						// If there's a change, emit the change so followers can follow
						// if(prevActive == null && newActive == null) {
						// 	console.error("ERROR??");
						// } else if(prevActive == null && newActive != null) {
						// 	VertxClient._eventBus.publish("/dossier-event/" + newActive.dossier.id, {
						// 		type: 'dossier-opened',
						// 		chapterSlug: newActive.chapterSlug,
						// 		pageSlug: newActive.pageSlug,
						// 		accountId: UserModel.id,
						// 		accountName: UserModel.getFullName(),
						// 		client: clientGuid
						// 	});
						// } else if(prevActive != null && newActive == null) {
						// 	VertxClient._eventBus.publish("/dossier-event/" + prevActive.dossier.id, {
						// 		type: 'dossier-closed',
						// 		accountId: UserModel.id,
						// 		accountName: UserModel.getFullName(),
						// 		client: clientGuid
						// 	});
						// } else {
						// 	VertxClient._eventBus.publish("/dossier-event/" + newActive.dossier.id, {
						// 		type: 'page-changed',
						// 		chapterSlug: newActive.chapterSlug,
						// 		pageSlug: newActive.pageSlug,
						// 		accountid: UserModel.id,
						// 		accountName: UserModel.getFullName(),
						// 		client: clientGuid
						// 	});
						// }

						me._active = newActive;
					},
					/**
					 * Unset the currently active (viewed) dossier
					 */
					unsetActive: function () {
						this._active = undefined;
					},
					/**
					 * Retrieve the currently active (viewed) dossier
					 * @returns {*}
					 */
					getActive: function () {
						return this._active;
					},
					/**
					 * Completely fetches the dossier and the template if they're not cached.
					 *
					 * 1) If the dossier is not in the cache, load it.
					 * 2) If the dossier is in the cache, check if the data property is availaible, and load it otherwise
					 * 3) Get the template. If the latest template is required, load it, set the dossier to that template and save the dossier.
					 *
					 * @param dossierId The id of the dossier to be loaded
					 */
					load: function (dossierId) {

						var deferred = $q.defer(),
							loadDossier = function (dossierId) {
								var dossier;
								if(!dossierId) {
									dossier = DossierResource.createInstance();
								} else {
									dossier = DossierResource.get(dossierId);
								}

								if (dossier == null || dossier.data == null) {
									// Force bypass cache, dossier may be loaded but dossier.data not (lazy loaded field)
									// when dossier is loaded earlier in the list
									return DossierResource.find(dossierId, {bypassCache: true});
								}
								var deferred = $q.defer();
								deferred.resolve(dossier);
								return deferred.promise;
							},
							loadDocuments = function(dossier) {
								var deferred = $q.defer();

								if(UserModel.hasRole('BGO')) {
									// BGO's can't see the documents
									deferred.resolve(dossier);
								} else {
									DossierDocumentResource.findAllForDossier(dossier.id, true).then(function (data) {
										deferred.resolve(dossier);
									}, function (err) {
										deferred.reject(err);
									});
								}

								return deferred.promise;
							},
							loadDossierTpl = function (dossier) {
								var deferred = $q.defer(),
									dossierTplDefId = dossier.dossierTplDefId;

								if(Authentication.hasRole('ADMIN') === true && UserModel.companyId == dossier.companyId) {
									dossierTplDefId = 0; // Fetch latest
								}

								DossierTemplateResource.load(dossier.dossierTplId, dossierTplDefId).then(function (data) {
									data.dossier = dossier;
									dossier.dossierTplDefRev = data.dossierTplDef.revision;
									deferred.resolve(data);
								}, function (err) {
									deferred.reject(err);
								});
								return deferred.promise;
							};

						loadDossier(dossierId).
							then(loadDocuments).
							then(loadDossierTpl).
							then(function (data) {
								data.dossier.getChangedChecklistStates(data.dossierTplDef);
								deferred.resolve(data);
							}).
							catch(function (err) {
								deferred.reject(err);
							});

						return deferred.promise;

					},
					methods: {

						adminView: function() {
							return Authentication.hasRole('ADMIN');
						},

						bgoView: function() {
							return Authentication.hasRole('BGO') || this.adminView();
						},

						userView: function() {
							return Authentication.hasRole('USER') || this.adminView();
						},

						/**
						 * Get the full name for this dossier.
						 * @returns {string}
						 */
						getFullName: function () {
							return [this.firstName, this.infix, this.surname].join(" ");
						},
						/**
						 * Lazily loads the template and returns a DossierTemplate if found
						 * @returns {{}} DossierTemplate
						 */
						getTemplate: function () {
							var deferred = $q.defer(), me = this;

							// If me.dossierTplId == 0, the server will return the template in the user's locale
							// AND return an extra dossierTplDefId field with the latest revision for that template
							DossierTemplateResource.find(me.dossierTplId).then(function (dossierTemplate) {
								if (me.dossierTplId === 0) {
									me.dossierTplId = dossierTemplate.id;
									me.dossierTplDefId = dossierTemplate.dossierTplDefId;
									delete(dossierTemplate.dossierTplDefId);
								}

								// Now fetch the revision
								// If the user is admin and the dossier is for the company of the admin, then load the latest revision
								// Otherwise, always load the revision of the dossier
								var latest = Authentication.hasRole('ADMIN') === true && UserModel.companyId == me.companyId;

								DossierTemplateDefinitionResource.load(me.dossierTplId, me.dossierTplDefId, latest).then(function (dossierTemplateDef) {
									me.dossierTplDefId = dossierTemplateDef.id;
									DossierTemplateDefinitionResource.interpretDefinition(dossierTemplateDef);

									// And fetch all dossier documents on the way
									DossierDocumentResource.findAllForDossier(me.id).then(function (documents) {
										deferred.resolve({
											template: dossierTemplate,
											templateDef: dossierTemplateDef
										});
									}, function (err) {
										deferred.reject();
									});
								}, function () {
									//toastMessages.add(toastMessages.T_DANGER, 'Page.DossierTemplateDef.Form.ToastMessages.NotFoundTitle', 'Page.DossierTemplateDef.Form.ToastMessages.NotFoundContent', 8000);
									deferred.reject();
								});

							}, function () {
								//toastMessages.add(toastMessages.T_DANGER, 'Page.DossierTemplate.Form.ToastMessages.NotFoundTitle', 'Page.DossierTemplate.Form.ToastMessages.NotFoundContent', 8000);
								deferred.reject();
							});

							return deferred.promise;
						},
						/**
						 * Function that sends an invite to a member to gain access to this dossier.
						 * @param {string} email
						 * @param {string} firstName
						 * @param {string} surName
						 * @param {string} [infix]
						 * @returns {deferred.promise|{then, always}}
						 */
						sendInvite: function (email, firstName, infix, surname, gender, phone) {
							var me = this;
							var deferred = $q.defer();

							//does not exist yet so you cannot save it
							if (me.id <= 0) {
								deferred.reject('does-not-exist');
								return deferred.promise;
							}

							//check if the email already exists if so reject immediately
							// if (_.findWhere(me.data.access, {email: email}) !== undefined) {
							// 	deferred.reject('exists');
							// 	return deferred.promise;
							// }

							var data = {
								email: email,
								firstName: firstName,
								infix: infix || '',
								surname: surname,
								gender: gender,
								phone: phone,
								dossierId: me.id,
								rev: me.rev
							};

							//send to the socket.
							VertxClient.send("/dossier/invite", data).then(function (result) {
								//add email to access array.
								if (!me.data.access) me.data.access = [];
								me.data.access.splice(0);
								me.data.access = me.data.access.concat(result.access);

								if(result.rev != null) {
									me.rev = result.rev;
								}

								deferred.resolve();

							}, function () {
								deferred.reject('server');
							});

							return deferred.promise;
						},
						cancelInvite: function (email) {
							var me = this;
							var deferred = $q.defer();

							//does not exist yet so you cannot save it
							if (me.id <= 0) {
								deferred.reject('does-not-exist');
								return deferred.promise;
							}

							var data = {
								email: email,
								dossierId: me.id,
								rev: me.rev
							};

							//send to the socket.
							VertxClient.send("/dossier/remove-user", data).then(function (result) {
								//add email to access array.
								if (!me.data.access) me.data.access = [];
								me.data.access.splice(0);
								me.data.access = me.data.access.concat(result.access);

								if(result.rev != null) {
									me.rev = result.rev;
								}

								deferred.resolve();

							}, function () {
								deferred.reject('server');
							});

							return deferred.promise;
						},
						terminateService: function(service) {
							return this.terminateServiceRequest(service, "terminate-service");
						},
						confirmServiceTermination: function(service) {
							return this.terminateServiceRequest(service, "confirm-service-termination");
						},

						terminateServiceRequest: function(service, type) {
							var me = this;
							var deferred = $q.defer();

							//does not exist yet so you cannot save it
							if (me.id <= 0) {
								deferred.reject('does-not-exist');
								return deferred.promise;
							}

							var data = {
								terminationId: service.id,
								name: service.name,
								ref: service.ref,
								dossierId: me.id,
								rev: me.rev,
								refLabel: service.terminationItem != null ? service.terminationItem.refLabel : null
							};
							if(service.type === 2) {
								// Bank (ref = iban bank account)
								data.accountType = service.accountType;
								data.type = service.type;
							}

							//send to the socket.
							VertxClient.send("/dossier/"+type, data).then(function (result) {
								if(result.rev != null) {
									me.rev = result.rev;
								}
								angular.copy(result.termination, service);
								deferred.resolve();
							}, function () {
								deferred.reject('server');
							});
							return deferred.promise;
						},

						suggestService: function(service) {
							var me = this;
							var deferred = $q.defer();


							var data = {
								name: service.name||'',
								info: service.info||'',
								dossierId: me.id,
								rev: me.rev
							};

							//send to the socket.
							VertxClient.send("/termination/suggest", data).then(function (result) {
								deferred.resolve();
							}, function () {
								deferred.reject('server');
							});
							return deferred.promise;
						},

						/**
						 * Function that sends data to an websocket address.
						 * This can be anything from search functionality to just updating some information.
						 * @param {string} address
						 * @param {{}} data
						 * @returns {Promise}
						 */
						sendMessage: function(address, data) {
							return VertxClient.send(address, data);
						},

						/**
						 * Saves the given changes
						 */
						saveChanges: function (changes) {
							var me = this,
								deferred = $q.defer();

							if(!changes || !changes.hasChanges) {
								deferred.resolve();
								return deferred.promise;
							}

							delete changes.hasChanges;
							changes.id = me.id;
							changes.rev = me.rev;

							VertxClient.send("/dossier/"+(!me.id ? "create" : "update"), changes).then(function (result) {
								if(result.rev != null) {
									me.rev = result.rev;
								}

								if(changes.deceasedDate === undefined) {
									deferred.resolve(result);
									return;
								}

								// Force reload, template may have changed due to changed deceased Date
								// (Each year has its own template
								DossierResource.eject(changes.id);
								DossierResource.load(changes.id).then(function() {
									deferred.resolve(result);
								}, function(err) {
									deferred.reject(err);
								});


							}, function(err) {
								deferred.reject(err);
							});
							return deferred.promise;
						},

						/**
						 * Function that updates the current model. Saving it to the backend.
						 * New models should use the create method instead !
						 * @returns {deferred.promise|{then, always}}
						 */
						save: function () {
							var me = this;
							var deferred = $q.defer();

							//save only updates existing models.
							if (me.id <= 0) {
								deferred.reject('does-not-exist');
								return deferred.promise;
							}

							DossierResource.update(me.id, me).then(function (dossier) {
								// Upon a successful save, the data property is returned as serialized json (a string).
								// Deserisalize the data property
								// dossier.data = JSON.parse(dossier.data);
								deferred.resolve(dossier);
							}, function (dossier) {
								deferred.reject(dossier);
							});

							return deferred.promise;
						},
						/**
						 * Function that creates the current model in the backend.
						 * This function is not meant to be used for models that already exist.
						 * @returns {deferred.promise|{then, always}}
						 */
						create: function () {
							var me = this;
							var deferred = $q.defer();

							//create only creates new models and not update existing.
							if (me.id > 0) {
								deferred.reject('already-exists');
								return deferred.promise;
							}

							// The angular app needs an id (=0 for new dossiers), but the js-data store
							//
							me.id = null;
							DossierResource.create(me).then(function (dossier) {
								// Upon a successful create, the data property is returned as serialized json (a string).
								// Deserisalize the data property
								// dossier.data = JSON.parse(dossier.data);

								DossierResource.inject(dossier);
								deferred.resolve(dossier);
							}, function (errors) {
								deferred.reject(errors);
							});

							return deferred.promise;
						},
						contains: function (obj, property, value) {
							var o = {};
							o[property] = value;
							return _.findWhere(obj, o) !== undefined;

						},
						/**
						 * Call signature: dossier.allEqual(true, 'dossier.property_1','dossier.property_2','dd.A')
						 * This will return true if dossier.property_1 === true &&  dossier.property_2 === true && dossie.data.A === true
						 *
						 * @param v {string|boolean} Value to compare to
						 * @return {boolean}
						 */
						allEqual: function (v) {
							var s;
							for (var i = 1; i < arguments.length; i++) {
								s = arguments[i];
								if (_.startsWith(s, 'dd.')) {
									if (_.getByString(this, s.replace(/dd\./, 'data.')) !== v)
										return false;
								} else if (_.startsWith(s, 'dossier.')) {
									if (_.getByString(this, s.substr(8)) !== v)
										return false;
								} else {
									$log.error("Formula must start with 'dd.' or with 'dossier.'. Ignored: " + s);
								}
							}
							return true;
						},
						/**
						 * Returns true if the array has at least one entry and at least one property is filled out.
						 *
						 * @param {obj|string}
						 * @returns {boolean}
						 */
						hasEntry: function (obj) {
							var o, p, i;
							if (_.isArray(obj) && obj.length > 0) {
								for (i = 0; i < obj.length; i++) {
									o = obj[i];
									for (p in o) {
										if (p !== '$$hashKey' && p!=='geboorteDatumKind' && o.hasOwnProperty(p) && o[p] !== undefined && (_.isString(o[p]) && !_.isEmpty(o[p].trim()))) {
											return true;
										}
									}
								}
							} else {
								o = obj;
								for (p in o) {
									if (p !== '$$hashKey' && p!=='geboorteDatumKind' && o.hasOwnProperty(p) && o[p] !== undefined && (_.isString(o[p]) && !_.isEmpty(o[p].trim()))) {
										return true;
									}
								}
							}
							return false;
						},
						activeUser: function(){
							var user = UserModel;
							return user;
						},
						hasChildWithProperty: function(variableName, value) {
							var me = this;
							if (variableName === null || me.data === null || !_.isArray(me.data.kinderen) || value == null) return false;

							var i = (me.data.kinderen || []).length,
								child;

							while (i--) {
								child = me.data.kinderen[i];
								if(child[variableName] == value) return true;
							}
							return false;
						},
                        /**
						 * dossier.hasChildWithProperties( ['kindinleven', '=', true], ['geboorteDatumKind', '<', 18, 'age'])
						 *
                         * @returns {boolean}
                         */
                        hasChildWithProperties: function() {
                            var me = this;
                            if ( me.data === null || !_.isArray(me.data.kinderen) || arguments.length === 0) return false;

                            var i = (me.data.kinderen || []).length,
                                child;


                            while(i--) {
                                child = me.data.kinderen[i];

                                var j = arguments.length,
									r = true;

                                while(j-- && r === true) {
                                    var condition = arguments[j];
                                    var field, comparator = '=', value, fn = null, dossierValue = null;
                                    if(condition.length < 3) return false;

                                    field = condition[0];
                                    comparator = condition[1];
                                    value = condition[2];
                                    if (condition.length == 3) {
                                        dossierValue = child[field];

                                    } else if (condition.length == 4) {
                                        fn = condition[3];
                                        if (fn === 'age') {
                                        	if(child[field] === undefined) { r = false; continue; }
                                            var birthDate = _.getDateFromFormat(child[field]);
                                            dossierValue = _.dateDiff(birthDate, new Date(this.deceasedDate)).years;
                                        }
                                    }

                                    switch (comparator) {
                                        case '<':
                                            r = dossierValue < value;
                                            break;
                                        case '<=':
                                            r = dossierValue <= value;
                                            break;
                                        case '=':
                                            r = dossierValue === value;
                                            break;
                                        case '>':
                                            r = dossierValue > value;
                                            break;
                                        case '>=':
                                            r = dossierValue > value;
                                            break;
                                        default:
                                            r = false;
                                    }
                                }
								if(r === true) return true;
                            }
                            return false;
                        },
						/**
						 * This function determines if the child is over age eighteen
						 * At the time of deceased. If not this function returns false else it returns true.
						 * @param {String} variableName
						 * @returns {boolean}
						 */
						hasChildrenOverEighteen: function (variableName) {
							var me = this;
							if (variableName === null || me.data === null || !_.isArray(me.data.kinderen) || me.deceasedDate === null) return false;
							var deceasedDate = _.getDateFromFormat(me.deceasedDate),
								i = (me.data.kinderen || []).length,
								child,
								childUnderEighteenFound = false;

							while(i-- && !childUnderEighteenFound) {
								child = me.data.kinderen[i];
								if (child[variableName] != null) {
									var birthDate = _.getDateFromFormat(child[variableName]),
										diff = _.dateDiff(birthDate, deceasedDate);

									childUnderEighteenFound = diff.years >= 18;
								}
							}
							return childUnderEighteenFound;
						},
						/**
						 * This function determines if the child is over age eighteen
						 * At the time of deceased. If not this function returns false else it returns true.
						 * @param {String} variableName
						 * @returns {boolean}
						 */
						hasChildrenUnderEighteen: function (variableName) {
							var me = this;
							if (variableName === null || me.data === null || !_.isArray(me.data.kinderen) || me.deceasedDate === null) return false;
							var deceasedDate = _.getDateFromFormat(me.deceasedDate),
								i = (me.data.kinderen || []).length,
								child,
								childUnderEighteenFound = false;

							while(i-- && !childUnderEighteenFound) {
								child = me.data.kinderen[i];
								if (child[variableName] != null) {
									var birthDate = _.getDateFromFormat(child[variableName]),
										diff = _.dateDiff(birthDate, deceasedDate);

									childUnderEighteenFound = diff.years < 18;
								}
							}
							return childUnderEighteenFound;
						},
						/**
						 * This function determines if the child is under a certain age
						 * At the time of deceased. If not this function returns false else it returns true.
						 * @param {String} variableName
						 * @returns {boolean}
						 */
						hasChildUnderAge: function (age, propName, value) {
							var me = this, p = 'geboorteDatumKind';
							if (me.data === null || !_.isArray(me.data.kinderen) || me.deceasedDate === null) return false;
							var deceasedDate = _.getDateFromFormat(me.deceasedDate),
								i = (me.data.kinderen || []).length,
								child,
								childUnderAgeFound = false;

							while(i-- && !childUnderAgeFound) {
								child = me.data.kinderen[i];
								if (child[p] != null) {
									var birthDate = _.getDateFromFormat(child[p]),
										diff = _.dateDiff(birthDate, deceasedDate);

									childUnderAgeFound = diff.years < age;
									if(propName != null) {
										childUnderAgeFound = childUnderAgeFound && child[propName] == value;
									}
								}
							}
							return childUnderAgeFound;
						},
						/**
						 * This function determines if the child is under a certain age
						 * At the time of deceased. If not this function returns false else it returns true.
						 * @param {String} variableName
						 * @returns {boolean}
						 */
						hasChildWithAge: function (variableName, lower, upper) {
							var me = this;
							if (variableName === null || me.data === null || !_.isArray(me.data.kinderen) || me.deceasedDate === null) return false;
							var deceasedDate = _.getDateFromFormat(me.deceasedDate),
								i = (me.data.kinderen || []).length,
								child,
								childUnderAgeFound = false;

							while(i-- && !childUnderAgeFound) {
								child = me.data.kinderen[i];
								if (child[variableName] != null) {
									var birthDate = _.getDateFromFormat(child[variableName]),
										diff = _.dateDiff(birthDate, deceasedDate);

									childUnderAgeFound = (diff.years >= lower && diff.years < upper);
								}
							}
							return childUnderAgeFound;
						},
						/**
						 * Compares the age of the partner against a given year and month at the date of death.
						 * Example:
						 * partnerAge('deceasedDate', '<', 63, 3) returns true if the partner is younger than 63 years and 3 months
						 * at the deceasedDate.
						 *
						 * @param birthday {String} The partner birthday
						 * @param comparator {String} Comparator, '<', '<=', '=', '>=' or '>'
						 * @param years {integer} Difference in years
						 * @param months {integer} Difference in months (optional)
						 * @returns {boolean}
						 */
						partnerAge: function (birthday, comparator, years, months) {
							var me = this,
								deceasedDate = _.getDateFromFormat(me.deceasedDate),
								bDay = _.getDateFromFormat(birthday),
								d = _.dateDiff(bDay, deceasedDate),
								v = years * 12 + months,
								diff = d.years * 12 + d.months;

							switch(comparator) {
								case '<': return diff < v;
								case '<=': return diff <= v;
								case '=': return diff == v;
								case '>=': return diff >= v;
								case '>': return diff > v;
							}
							console.error('partnerAge: Unknown comparator '+comparator);
							return false;
						},
						timeDiffGreaterThan: function (date, years, months) {
							var deceasedDate = _.getDateFromFormat(me.deceasedDate),
								date2 = _.getDateFromFormat(date);

							_.dateDiffInYears();


							return false;
						},
						allFilledOut: function() {
							var i = arguments.length;
							while(i--) {
								if(_.getByString(this, arguments[i].replace('dd.', 'data.')) === undefined) return false;
							}
							return true;
						},
						dossierProgress: function(templateDef) {
							if (!templateDef) {
								return {
									relevantCount: 0,
									toDoCount: 0,
									progress: 0
								};
							}

							var i = templateDef.items.length,
								relevantCount = 0,
								toDoCount = 0,
								p;

							while(i--) {
								p = this.chapterProgress(templateDef.items[i]);
								relevantCount += p.relevantCount;
								toDoCount += p.toDoCount;
							}

							return {
								relevantCount: relevantCount,
								toDoCount: toDoCount,
								progress: relevantCount === 0 ? 100 : Math.round( (relevantCount - toDoCount) / relevantCount * 100)
							};
						},

						/**
						 * Calculates the progress for a given chapterSlug
						 *
						 * @param chapterSlug
						 */
						chapterProgress: function(chapter) {
							if(chapter == null || chapter.properties.slug == 'checklist') return {
								relevantCount: 0,
								toDoCount: 0,
								progress: 0
							};

							var checkListBlock = chapter.properties.checkListBlock;
							if(checkListBlock == null) return {
								relevantCount: 0,
								toDoCount: 0,
								progress: 0
							};

							var	items = checkListBlock.items,
								itemCount = items.length,
								i = itemCount,
								scope = {
									dossier: this,
									dd: this.data
								},
								relevantCount = 0, // Counts the number of relevant checklist items (Checklist items may be skipped based on a chapter/page/block condition
								isRelevant,
								toDoCount = 0,     // Counts the number of relevant checklist items that still have to be done
								item;

							while(i--) {
								item = items[i];
								isRelevant = item.properties.relevantIf == null || $parse(item.properties.relevantIf)(scope) === true;
								if(isRelevant) {
									relevantCount++;
									toDoCount += $parse(items[i].properties.showIf)(scope) === true ? 1 : 0;
								}
							}

							if(relevantCount === 0) return {
								relevantCount: 0,
								toDoCount: 0,
								progress: 100
							};

							return {
								relevantCount: relevantCount,
								toDoCount: toDoCount,
								progress: Math.round( (relevantCount - toDoCount) / relevantCount * 100)
							};
						},


					/**
						 * Function that determines the total estimate of the properties of the deceased possessions.
						 * This function looks in the data.estimates array and loops over it.
						 * If it finds a nested array it looks within that array as well going over its nested objects.
						 * If the object contains a property estimate this is grabbed + validated as a number.
						 * If the key of the parent is "schulden" the estimate is subtracted instead of increased
						 * @returns {string}
						 */
						getTotalEstimate: function () {
							var me = this;
							if (me.data === null || me.data.estimates === null) return (0).toFixed(2);

							var totalAmount = 0;
							_.each(me.data.estimates, function (estimates, key) {
								if (_.isArray(estimates)) {
									_.each(estimates, function (estimate) {
										if (estimate.estimate !== null && _.isNumber(estimate.estimate)) {
											if (key === 'schulden') {
												totalAmount -= estimate.estimate;
												return;
											}
											totalAmount += estimate.estimate;
										}
									});
									return;
								}
								if (estimate.estimate !== null && _.isNumber(estimates)) {
									if (key === 'schulden') {
										totalAmount -= estimate.estimate;
										return;
									}
									totalAmount += estimates.estimate;
								}
							});
							return totalAmount.toFixed(2);
						},
						hasDocumentDocType: function (docType) {
							var documents = DossierDocumentResource.getAll();
							for (var i = 0; i < documents.length; i++) {
								if (documents[i].dossierId == this.id && documents[i].docType == docType) {
									return true;
								}
							}
							return false;
						},

						/**
						 * Evaluate each checklist item.
						 * 
						 * @param dossier
                         * @param dossierTplDef
                         */
						getChangedChecklistStates: function(dossierTplDef) {

							var list = UserModel.hasRole('BGO') ? [] : dossierTplDef.getChecklistChapter().watchedChecklistItems,
								i = list.length,
								evalScope = {
									dossier: this,
									dd: this.data || {}
								},
								changes = {};

							if(this.alertState === undefined) {
								this.alertState = {};
							}

							while(i--) {
								var alertId = list[i].properties.alertId,
									v = $parse(list[i].properties.showIf)(evalScope);

								if(this.id === 0) {
									this.alertState[alertId] = v;
									changes[alertId] = v;

								} else if(this.alertState[alertId] === undefined) {
									// Add a new alert state
									this.alertState[alertId] = true;
									changes[alertId] = true;

								} else if (v !== this.alertState[alertId]) {
									// Alert state changed
									this.alertState[alertId] = v;
									changes[alertId] = v;
								}
							}

							console.log("State changes "+JSON.stringify(changes, false, 4));

							return changes;
						}
					}
				});

				return DossierResource;
			}
		]);
});
