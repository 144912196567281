define('components/dossier/grid/directive',[
	'angular',
	'components/dossier/base'
], function (ng) {
	'use strict';

	return ng.module(
		'NabesFront.components.dossier.Grid', [
			'Twensoc.Config',
			'NabesFront.utils.Authentication',
			'NabesFront.collection.ToastMessagesCollection',
			'NabesFront.components.dossier.BaseComponent'
		]
	).directive('nbGrid', [
		'config',
		'Authentication',
		'$timeout',
		'toastMessages',
		function (config, Authentication, $timeout, toastMessages) {
			return {
				restrict: "E",
				scope: {
					dossier: '=data',
					class: "@class",
					resource: '=resource',
					columns: '=columns',
					ShowIf: '=showIf',
					templateId: '@templateId',
					buttons: '=buttons'
				},
				transclude: true,
				require: '^form',
				templateUrl: "app/components/dossier/grid/grid.html",
				link: function (scope, elem, attr, formCtrl) {
					scope.Config = config;
					scope.showIfText = attr.showIf;
					scope.showDebug = false;

					/**
					 * Function that retrieves the value from within the object by specified string.
					 * @param {{}} object
					 * @param {string} key
					 * @returns {*}
					 */
					scope.getValue = function (object, key) {
						return _.getByString(object, key);
					};

					/**
					 * Function that invites the user to gain access to this dossier.
					 * @param {{email: string, firstName: string: surName: string, infix: string|undefined}} data
					 */
					scope.inviteUser = function (data) {
						scope.dossier.sendInvite(data.email, data.firstName, data.infix, data.surname, data.gender, data.phone).then(function () {
							var toastData = {};
							angular.copy(data, toastData);
							toastMessages.add(toastMessages.T_SUCCESS, 'SendAccessInviteInput.ToastMessages.InviteSendTitle', 'SendAccessInviteInput.ToastMessages.InviteSendContent', 8000, {data: toastData});
						}, function (reason) {
							scope.inviteSend = false;
							switch (reason) {
								//already exists
								case 'exists':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.AlreadyExistsTitle', 'SendAccessInviteInput.ToastMessages.AlreadyExistsContent', 8000, {email: data.email});
									break;
								//server error
								case 'server':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.ServerErrorTitle', 'SendAccessInviteInput.ToastMessages.ServerErrorContent', 8000);
									break;
								//model does not exist yet first the dossier has to be saved once to be able to invite people to it requires an id!
								case 'does-not-exist':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.DoesntExistTitle', 'SendAccessInviteInput.ToastMessages.DoesntExistContent', 8000);
									break;
								//generic message
								default:
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessInviteInput.ToastMessages.GenericErrorTitle', 'SendAccessInviteInput.ToastMessages.GenericErrorContent', 8000);
							}
						});
					};

					/**
					 * Show the debug toggle only to the ADMIN
					 */
					scope.showDebugToggle = function () {
						return Authentication.hasRole('ADMIN');
					};

					scope.showCanselInvite = function () {
						return Authentication.hasRole(['ADMIN', 'BGO']);
					};


					scope.cancelInviteUser = function (data) {
						console.log("cinv",data);
						scope.dossier.cancelInvite(data.email).then(function () {
							var toastData = {};
							angular.copy(data, toastData);
							toastMessages.add(toastMessages.T_SUCCESS, 'SendAccessCancelInput.ToastMessages.InviteCancelTitle', 'SendAccessCancelInput.ToastMessages.InviteCancelContent', 8000, {data: toastData});
						}, function (reason) {
							scope.inviteSend = false;
							switch (reason) {
								//server error
								case 'server':
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessCancelInput.ToastMessages.ServerErrorTitle', 'SendAccessCancelInput.ToastMessages.ServerErrorContent', 8000);
									break;
								//generic message
								default:
									toastMessages.add(toastMessages.T_DANGER, 'SendAccessCancelInput.ToastMessages.GenericErrorTitle', 'SendAccessCancelInput.ToastMessages.GenericErrorContent', 8000);
							}
						});
					};





					/**
					 * Show/hide the debug information for this input.
					 */
					scope.toggleDebug = function () {
						scope.showDebug = !scope.showDebug;
					};

					/**
					 * Time for some cleaning up of listeners! this is very important!
					 */
					scope.$on('$destroy', function () {
					});
				}
			};
		}
	]);
});
