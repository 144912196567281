define('components/dossier/select-input/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.SelectInput', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbSelectInput', [
        'config',
        'Authentication',
        function(config, Authentication) {
            return {
                restrict: "E",
                scope: {
                    dossier: '=data',
                    Name: '@name',
                    Data: '=value',
                    Label: '@label',
                    PlaceHolder: '@placeholder',
                    Required: '=required',
                    options: '=options',
                    Explanation: '@explanation',
                    ShowIf: '=showIf',
                    templateId: '@templateId'
                },
                require: '^form',
                templateUrl: "app/components/dossier/select-input/select-input.html",
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.Required = _.castToBoolean(scope.Required);
                    scope.showExplanation = false;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    scope.getValue = function(obj) {
                        if(_.isFunction(obj.getValue)) return obj.getValue();
                        return obj.value;
                    };

                    scope.getLabel = function(obj) {
                        if(_.isFunction(obj.getLabel)) return obj.getLabel();
                        return obj.label;
                    };

                    scope.isEmpty = function() {
                        return (scope.Data == null || scope.Data === 0);
                    };

					/**
					 * Function that checks if this input has a specific error.
					 * If this is the case the error is displayed to the user.
					 * @param {string} errorName
					 * @returns {boolean}
					 */
					scope.hasError = function(errorName) {
						if(formCtrl[scope.Name] === undefined) return false;
						var value = _.getByString(formCtrl[scope.Name].$error, errorName);
						return ((formCtrl.$submitted === true || formCtrl[scope.Name].$dirty === true) && value);
					};

                    /**
                     * Show/hide an optional explanation
                     */
                    scope.toggleExplanation = function() {
                        scope.showExplanation = !scope.showExplanation;
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                    });
                }
            };
        }
    ]);
});
