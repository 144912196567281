/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 6-2-2015
 * Time: 10:07
 * For Project: fairplay
 * @version : 0.1
 */

define('model/base',[
    'angular',
    'lodash'
], function (ng, _) {
    'use strict';

    return ng.module(
        'Twensoc.model.BaseModel', []
    ).factory('BaseModel', [
        '$q',
        '$rootScope',
        function($q, $rootScope) {
            function BaseModel(attrs) {
                ng.extend(this, attrs);
            }

            ng.extend(BaseModel.prototype, {
                _UUID: "",
                /**
                 * Initialization function.
                 * You can use this function to set up some basic information about the model
                 */
                init: function() {
                    var me = this;
                    me._UUID = _.uniqueId();
                },
                /**
                 * Base set functionality it should be implemented on inherited models.
                 * This has as purpose to populate the models data.
                 * @param {{}} data
                 */
                set: function(data) {
                    throw new Error('set() method has to be implemented on inherited model');
                }
            });
            return BaseModel;
        }
    ]);
});
