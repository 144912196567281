/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/unsubscribe/controller',[
	'angular',
	'lodash',
	'model/dossier-resource',
	'components/header/directive', //header directive
	'model/user', //user model
	'components/footer/directive' //footer directive
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.controllers.UnsubscribeController', [
			'Twensoc.Config',
			'Twensoc.components.tsHeader',
			'Twensoc.components.tsFooter',
			'NabesFront.model.UserModel',
			'DossierResource'
		]
	).controller(
		'UnsubscribeController', [
			'$state',
			'$stateParams',
			'$scope',
			'$rootScope',
			'$location',
			'$q',
			'$http',
			'config',
			'UserModel',
			'DossierResource',
			function ($state, $stateParams, $scope, $rootScope, $location, $q, $http, config, UserModel, DossierResource) {
				$scope.Config = config;
				$scope.User = UserModel;
				$rootScope.Title = "Unsubscribe from email";
				$rootScope.BodyClass = "page-content-html";
				$scope.pageContent = "Loading...";

				// Do not display dossier's sidemnu if clicked from within dossier
				DossierResource.unsetActive();

				// Load requested document
				$scope.loadContent = function (path) {
					var me = this;
					var deferred = $q.defer();
					var params = $stateParams;
					params.path = path;
					console.log(params);
					$http.get(config.getAPIUrl('unsubscribe'), {params: params})
						.success(function (data, status, headers, config) {
							if (data === "null -> 1") {
								data = "U bent succesvol uitgeschreven voor alle mails betreffende dit dossier.";
							}
							deferred.resolve(data);
						})
						.error(function (data, status, headers, config, statusText) {
							// $state.go('locale.404', {Locale: $scope.Config.getUrlPrefix()});
						});
					return deferred.promise;
				};

				console.log($location.path());
				$scope.loadContent($location.path()).then(function (content) {
					$scope.pageContent = content;
					console.log(content);
				}, function (error) {
					alert(error);
				});

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
				});
			}
		]
	);
});

