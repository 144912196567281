/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7/30/2015
 * Time: 2:29 PM
 * For Project: nabes-front
 * @version : 0.1
 */

define('utils/change-appliers/change-applier',[
    'angular',
    'lodash'
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.utils.ChangeApplier', []
    ).factory('ChangeApplier', [
        '$q',
        '$rootScope',
        function($q, $rootScope) {
            function ChangeApplier(attrs) {
                ng.extend(this, attrs);
            }

            ng.extend(ChangeApplier.prototype, {
                applyChange: function(change) {
                    var me = this;
                    _.setByString(me, change.name, change.value);
                    //todo expand
                },
                /**
                 * Function that processes the changes emitting it which is than handled by the Service Model or Collection.
                 * The collection then calls the callback me.applyChange with the thisArg set to the user model.
                 * This allows the change applier to directly modify the object without ever knowing its internal structure or its existence.
                 *
                 * Example:
                 *
                 *  $rootScope.$on('User.apply.change', function(event, applyChange, change) {
                 *      applyChange.call(me, change);
                 *  });
                 *
                 * @param {{}} change
                 */
                processChange: function(change) {
                    var me = this;
                    var address = change.type + '.apply.change';
                    $rootScope.$emit(address, me.applyChange, change);
                }
            });

            return new ChangeApplier();
        }
    ]);
});
