/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 15:08
 * For Project: fairplay
 * @version : 0.1
 */

define('components/toast-messages/directive',[
    'angular',
    'collection/toast-messages' //toast messages collection
], function (ng) {
    'use strict';

    return ng.module(
        'Nabes.components.toastMessages', [
            'Twensoc.Config',
            'NabesFront.collection.ToastMessagesCollection'
        ]
    ).directive('toastMessages', [
        'config',
        'toastMessages',
		function(config, toastMessages) {
            return {
                restrict: "E",
                templateUrl: "app/components/toast-messages/toast-messages.html",
                link: function(scope, elem, attr) {
                    scope.collection = toastMessages;

                    // send a toast message if the device is a phone
                    if(config.isPhone) {
                        toastMessages.add("alert-warning", "info.MobileDeviceWarningTitle", "info.MobileDeviceWarning");
                    }

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                    });
                }
            };
        }
    ]);
});
