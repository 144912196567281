/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 15-7-2015
 * Time: 13:57
 * For Project: nabes-front
 * @version : 0.1
 */


define('utils/authentication',[
	'angular',
	'lodash',
	'angularCookie',
	'config', //config
	'utils/vertx-client', //SockJS wrapper
	'collection/toast-messages', //toast messages collection
	'model/user', //user model
	'js-data'
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.utils.Authentication', [
			'ipCookie',
			'Twensoc.utils.VertxClient',
			'NabesFront.model.UserModel',
			'NabesFront.collection.ToastMessagesCollection',
			'js-data'
		]
	).factory('Authentication', [
		'$log',
		'$state',
		'$timeout',
		'$q',
		'$http',
		'$rootScope',
		'DS',
		'ipCookie',
		'UserModel',
		'VertxClient',
		'config',
		'toastMessages',
		'$window',
		function ($log, $state, $timeout, $q, $http, $rootScope, DS, ipCookie, UserModel, VertxClient, config, toastMessages, $window) {
			var url = "";


			function Authentication(attrs) {
				ng.extend(this, attrs);
				this.init();
			}

			ng.extend(Authentication.prototype, {
				_isLoggedIn: null,
				lastVisited: {},

				/**
				 * Upon initialisation of this service, there are 3 possible states:
				 *
				 * 1. User is not logged in
				 *    ipCookie is empty. Do not open a socket connection.
				 *
				 * 2. User has a ipCookie
				 *    Open a socket and use the cookie to authenticate.
				 *    If authentication fails, close the session and redirect to the login page
				 */
				init: function () {
					var me = this;
					me.authenticateToken();

					// Allow the VertxClient to authenticate using a token when the websocket is closed for whatever reason
					VertxClient.authenticateToken = this.authenticateToken.bind(me);
				},
				/**
				 * Function that logs the user into the system. Based on his/her password and email combination.
				 * This also sets a cookie remembering that the user is logged in (this contains a token)
				 * @param {string} email
				 * @param {string} password
				 * @returns {deferred.promise|{then, always}}
				 */
				doLogin: function (email, password, remember) {
					var me = this;
					var deferred = $q.defer();

					$http.post(config.getAPIUrl('login'), {email: email, password: password, remember: remember})
						.success(function (data, status, headers, config) {
							if (!data.token) {
								deferred.reject();
								return;
							}
							$window.localStorage.token = data.token;
							if (data.rememberToken !== undefined) {
								$window.localStorage.rememberToken = data.rememberToken;
							}
							me._isLoggedIn = true;
							UserModel.set(data.user);
							me.lastVisited = data.lastVisited;
							VertxClient.open().then(function () {
								$rootScope.$emit('Authentication', true, data, UserModel);
								deferred.resolve();
							}, function () {
								deferred.reject();
							});

						})
						.error(function (data, status, headers, config) {
							delete $window.localStorage.token;
							deferred.reject();
						});
					return deferred.promise;
				},
				//onSuccessfulLogin: function(deferred, user) {
				//    me._isLoggedIn = true;
				//    UserModel.set(user);
				//    VertxClient.open().then(function() {
				//        deferred.resolve();
				//    }, function() {
				//        deferred.reject();
				//    });
				//},
				/**
				 * This function causes the user to be logged out and returned back to the /login page.
				 * This function also removes the NB_TOKEN cookie.
				 */
				doLogout: function () {
					var me = this;
					$http.post(config.getAPIUrl('logout'))
						.success(function (data, status, headers, config) {


							me.handleFailedAuthentication();

							// Clear all data - If another user logs in, the cache should be cleared
							var resources = DS.definitions;
							for (var p in resources) {
								if (resources.hasOwnProperty(p)) {
									DS.ejectAll(p);
								}
							}


							VertxClient.close();
							//delete window.localStorage.token;
							//me._isLoggedIn = false;
							$state.go('locale.login');
						});
				},
				/**
				 * Function that determines if the user is logged in.
				 * The _isLoggedIn boolean can represent 3 states:
				 *
				 * null - We're busy trying to log.
				 * true - We have successfully logged in
				 * false - Login has failed
				 *
				 * Else if he is not logged in false is returned and the user is redirected to the /login page.
				 * @returns {deferred.promise|{then, always}}
				 */
				isLoggedIn: function () {
					var me = this;
					var deferred = $q.defer();

					//user is logged in resolve immediately
					if (me._isLoggedIn === true) {
						deferred.resolve(true);
						return deferred.promise;
					}

					// User is not logged in: send him to the login page
					if (me._isLoggedIn === false || window.localStorage.token == null) {
						//send the user to the login page!
						$timeout(function () {
							$state.go('locale.login', {Locale: config.getUrlPrefix()});
						});
						deferred.resolve(false);
						return deferred.promise;
					}

					// Wait for the authentication to go through
					var handler = $rootScope.$on('Authentication', function (event, success) {
						if (success) {
							deferred.resolve(true);
						} else {
							deferred.resolve(false);
							$timeout(function () {
								$state.go('locale.login', {Locale: config.getUrlPrefix()});
							});
						}
						handler();
					});
					return deferred.promise;
				},

				/**
				 * Authenticates the user by sending the JWT token.
				 * Requires an open websocket.
				 * If authentication fails, the token (cookie) is removed and the socket is closed.
				 */
				authenticateToken: function (deferred) {
					var token = $window.localStorage.token,
						me = this;

					var rememberToken = $window.localStorage.rememberToken;
					var sendToken;
					if (rememberToken === undefined) {
						sendToken = token;
					} else {
						sendToken = rememberToken;
					}

					if (token == null) {
						$log.info('Can not authenticate using token; no token available');
						if (deferred != null) deferred.reject();
						return;
					}

					$log.info("Authentication attempt using token");

					$http.post(config.getAPIUrl('login'), {token: sendToken}).success(function (data, status, headers, config) {
						$log.info('Authentication accepted');
						$window.localStorage.token = data.token;

						UserModel.set(data.user);
						me._isLoggedIn = true;
						me.lastVisited = data.lastVisited;
						VertxClient.open().then(function () {
							console.log('socket open');
							$rootScope.$emit('Authentication', true, data, UserModel);
						}, function () {
							me.handleFailedAuthentication(deferred);
						});
					}).error(function (data, status, headers, config) {
						$log.info('Authentication refused!');
						me.handleFailedAuthentication(deferred);
						VertxClient.close();
					});

					//VertxClient.open().then(function() {
					//
					//    VertxClient.send("/auth", {token: token}).then(function(data) {
					//
					//        if(data.failureCode == null) {
					//            // Succesful login
					//            UserModel.set(data.body);
					//            me._isLoggedIn = true;
					//            if(deferred != null) deferred.resolve();
					//            $rootScope.$broadcast('Authentication', true);
					//        } else {
					//            // Failed login
					//            me.handleFailedAuthentication(deferred);
					//        }
					//    }, function() {
					//        // Error occurred
					//        me.handleFailedAuthentication(deferred);
					//    });
					//
					//}, function() {
					//    // Could not open socket
					//    me.handleFailedAuthentication(deferred);
					//});
				},
				handleFailedAuthentication: function (deferred) {
					this._isLoggedIn = false;
					delete window.localStorage.token;
					delete window.localStorage.rememberToken;
					VertxClient.close();
					if (deferred != null) deferred.reject();
					$rootScope.$broadcast('Authentication', false);
				},
				/**
				 * Function that determines if the user has the specified role.
				 * If he/she does not false is returned else true is returned.
				 * @param {string|string[]} role
				 * @returns {boolean}
				 */
				hasRole: function (role) {
					if (UserModel === null) return false;
					if (_.isArray(role)) {
						for (var i = 0; i < role.length; i++) {
							if (role[i] === UserModel.role) {
								return true;
							}
						}
					}
					return (role === UserModel.role);
				},
				/**
				 * Function that returns a promise of the hasRole function.
				 * This promise gets resolved soon after.
				 * @param role
				 * @returns {deferred.promise|{then, always}}
				 */
				hasRolePromise: function (role) {
					var me = this;
					var deferred = $q.defer();

					UserModel.isLoaded().then(function () {
						if (me.hasRole(role)) deferred.resolve();
						else {
							deferred.reject();
							$timeout(function () {
								toastMessages.add(toastMessages.T_DANGER, 'Authentication.ToastMessages.NoAccessTitle', 'Authentication.ToastMessages.NoAccessContent', 8000);
								$state.go('locale.app.overview', {Locale: config.getUrlPrefix()});
							});
						}
					});

					return deferred.promise;
				}
			});

			return new Authentication();
		}
	]);
});
