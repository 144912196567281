/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/form/controller',[
    'angular',
    'lodash',
    'model/account-resource', //account resource
    'model/dossier-account-resource', //dossier account resource
    'model/dossier-resource', //dossier resource
    'model/company-resource', //company resource
    'model/dossier-template-resource', //dossier template resource
    'model/dossier-template-definition-resource', //dossier template resource
    'collection/toast-messages' //toast messages collection
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.DossierFormController', [
            'Twensoc.Config',
            'NabesFront.collection.ToastMessagesCollection',
            'DossierResource',
            'CompanyResource',
            'DossierTemplateResource',
            'DossierAccountResource',
            'ui.bootstrap',
            'NabesFront.utils.Authentication'
        ]
    ).controller(
        'DossierFormController', [
            '$state',
            '$stateParams',
            '$scope',
            '$q',
            '$parse',
            '$timeout',
            '$compile',
            '$rootScope',
            'config',
            'DossierResource',
            'CompanyResource',
            'DossierTemplateResource',
            'DossierAccountResource',
            'toastMessages',
            'Authentication',
            function ($state, $stateParams, $scope, $q, $parse, $timeout, $compile, $rootScope, config, DossierResource, CompanyResource, DossierTemplateResource, DossierAccountResource, toastMessages, Authentication) {
                $scope.Config = config;
                $rootScope.Title = "Dossier pagina";
                $rootScope.BodyClass = "dossier";

                $scope.header = 'Nieuw dossier';
                $scope.model = {data: {}, dossierTplId: 1};
                //$scope.origModel = {};
                $scope.dossier = {};
                $scope.template = {};
	            $scope.templateDef = {};

                $scope.additionalData = {
                    templates: []
                };
                $scope.showDebug = false;
                $scope.chapter = {};
                $scope.page = {};

                $scope.fields = {};
                $scope.isChecklistPage = false;

                $scope.checklistItemCount = -1;

                $scope.handleFailure = function(err) {
                    if(err == null) err = 'unknown-error';
                    if(err.message) err = err.message.toLowerCase().replace(/\s/g, "-");
                    toastMessages.add(toastMessages.T_DANGER, '', 'failure.'+err, 4000);
                };
                $scope.failureHandler = $scope.handleFailure.bind($scope);

                /**
                 * Some watchers for JSData since they do not create proper getAll() functions.
                 * Also never forget to remove this listener its not mentioned on their documentation so.
                 * But internally its just a $scope.$watch() function that is returned.
                 */
                var watchDossierTemplates = DossierTemplateResource.bindAll({}, $scope, 'additionalData.templates');

                $scope.dossierActionListener = $scope.$on('dossierAction', function(src, data) {
                    var color, me = this;
                    if(data.action == 'reportBankAccount') {
                        color = data.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;

                        $scope.dossier.terminateService(data.item).then(function() {
                            toastMessages.add(color, 'info.bankAccountTerminationSuccessTitle', 'info.bankAccountTerminationSuccess', 8000, data.item);
                        }, $scope.handleFailure);

                    } else if(data.action == 'terminateService') {
                        color = data.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;

                        $scope.dossier.terminateService(data.item).then(function() {
                            toastMessages.add(color, 'info.terminationSuccessTitle', 'info.terminationSuccess', 8000, data.item);
                        }, $scope.handleFailure);

                    } else if(data.action == 'terminatedServiceMyself') {
                        color = data.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;

                        $scope.dossier.terminateService(data.item).then(function() {
                            toastMessages.add(color, 'info.terminatedMyselfSuccessTitle', 'info.terminatedMyselfSuccess', 8000, data.item);
                        }, $scope.handleFailure);

                    } else if(data.action == 'suggestService') {
                        color = data.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;

                        $scope.dossier.suggestService(data.item).then(function() {
                            toastMessages.add(color, 'info.suggestServiceTitle', 'info.suggestService', 8000, data.item);
                        }, $scope.handleFailure);
                    } else if(data.action == 'confirmServiceTermination') {
                        color = data.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;

                        $scope.dossier.confirmServiceTermination(data.item).then(function() {
                            toastMessages.add(color, 'info.terminationConfirmedTitle', 'info.terminationConfirmed', 8000, data.item);
                        }, $scope.handleFailure);
                    } else {


                        $scope.dossier.sendMessage('/dossier/'+data.action, data).then(function(result) {
                            color = result.success === true ?  toastMessages.T_SUCCESS : toastMessages.T_DANGER;
                            toastMessages.add(color, 'info.'+data.action+'Title', 'info.'+data.action, 8000, data.item);

                            delete(result.success);
                            for(var p in result) {
                                _.setByString($scope.dossier, p, result[p]);
                            }

                        }, $scope.handleFailure);
                    }
                });

                /**
                 * Function that navigates the user back to the list view this can happen for various reasons.
                 * Generally a toast message should be added before the navigation to display the reason.
                 */
                $scope.navigateToList = function () {
                    $state.go('locale.app.dossier.overview', {uuid: $scope.dossier.id});
                };

                /**
                 * Find all input fields in the current page and store the corresponding dossier values
                 * (So changed fields can be easily detected).
                 */
                $scope.storeOriginalFieldValues = function() {
                    var flds = $scope.findVariabelesInPage($scope.page),
                        i = flds.length,
                        map = $scope.fields,
                        v;

                    while(i--) {
                        v = _.getByString($scope, flds[i]);
                        if(_.isArray(v)) {
                            map[flds[i]] = _.clone(v, true);
                        } else {
                            map[flds[i]] = v;
                        }
                    }
                };

                /**
                 * Detect changes by comparing the current dossier values for the fields on the screen against the previously
                 * saved dossier values.
                 *
                 * @return {object} Map with changed fields and the new values
                 */
                $scope.getChangedFields = function() {
                    var changes = {}, v, map = $scope.fields;
                    for(var p in $scope.fields) {
                        if(map.hasOwnProperty(p)) {
                            v = _.getByString($scope, p);
                            if(_.isArray(v)) {
                                v = _.clone(v, true);
                                // Remove 'empty' entries from arrays
                                var i = v.length, isEmpty, o;
                                while(i--) {
                                    o = v[i];
                                    delete o.$$hashKey;
                                    isEmpty = true;
                                    for(var ap in o) {
                                        if(o.hasOwnProperty(ap) && o[ap] != null && o[ap] !== '') {
                                            isEmpty = false;
                                            break;
                                        }
                                    }
                                    if(isEmpty) {
                                        v.splice(i,1);
                                    }
                                }
                                // Check difference against cloned array
                                if(_.arrayDifference(map[p], v)) {
                                    _.setByString(changes, p.replace('dossier.', '').replace('dd.', 'data.'), v);
                                    changes.hasChanges = true;
                                }
                            } else  if(v !== map[p]) {
                                _.setByString(changes, p.replace('dossier.', '').replace('dd.', 'data.'), v);
                                changes.hasChanges = true;
                            }
                        }
                    }
                    if(!$scope.dossier.id) {
                        // Create new dossier, incl. these fields
                        changes.dossierTplId = $scope.dossier.dossierTplId;
                        changes.dossierTplDefId = $scope.dossier.dossierTplDefId;
                    }
                    return changes;
                };

                /**
                 * Function that makes sure the current page slug is correct.
                 * And if its not it updates the slug accordingly. For example if the chapter slug and page slug are
                 * not specified these will get updated here to accommodate this fact. This makes sure the url does not look weird.
                 */
                $scope.checkSlugs = function() {
                    var me = this;
                    var changed = false;

                    //get first chapter and set the slug.
                    if(_.isEmpty($stateParams.chapterSlug)) {
                        var chapter = me.templateDef.getFirstChapter();
                        if(chapter !== null) {
                            changed = true;
                            $stateParams.chapterSlug =  chapter.properties.slug;
                        }
                    }

                    //get first page of chapter is chapter slug is defined
                    if(!_.isEmpty($stateParams.chapterSlug) && _.isEmpty($stateParams.pageSlug)) {

                        // Need to check dynamically which page is the first page
                        $scope.chapter = $scope.templateDef.getChapter($stateParams.chapterSlug);
                        var page = $scope.findNextPage($stateParams.chapterSlug);
                        if (page !== null) {
                            changed = true;
                            $stateParams.pageSlug = page.properties.slug;
                        }
                    }

                    // fix the URL
                    // TODO: This url change is not detected in the app.js and not logged as event...
                    if(changed) {
                        $state.go('locale.app.dossier.overview.edit', {uuID: $stateParams.uuID, chapterSlug: $stateParams.chapterSlug, pageSlug: $stateParams.pageSlug});
                    }

                    //check if page slug + chapter slug combination exists.
                    if($scope.templateDef.chapterPageExists($stateParams.chapterSlug, $stateParams.pageSlug)) {
                        $scope.chapter = $scope.templateDef.getChapter($stateParams.chapterSlug);
                        $scope.page = $scope.templateDef.getCurrentItem($stateParams.chapterSlug, $stateParams.pageSlug);

                        $scope.isChecklistPage = $scope.chapter && $scope.chapter.properties.slug == 'checklist';

                        DossierResource.setActive($scope.model, $scope.chapter.properties.slug, $scope.page.properties.slug);

                    } else {
                        toastMessages.add(toastMessages.T_DANGER, 'Page.Dossiers.Form.ToastMessages.NotFoundTitle', 'Page.Dossiers.Form.ToastMessages.NotFoundChapterPageContent', 8000);
                        $scope.navigateToList();
                    }
                };

                /**
                 * Function that adds the HTML to the form and compiles it through Angular.
                 * @private
                 */
                $scope.updateHtml = function() {
                    var html = '<form name="form" novalidate>'; //ng-submit="submit(dossier)
                    html += $scope.templateDef.getHTML($stateParams.chapterSlug, $stateParams.pageSlug);
                    html += '</form>';
                    $timeout(function() {
                        //add page class to body class
                        var item = $scope.templateDef.getCurrentItem($stateParams.chapterSlug, $stateParams.pageSlug);
                        var additionalClass = item.properties.state !== null ? item.properties.state : "";

                        $rootScope.BodyClass = "dossier " + additionalClass;
                        console.error($scope);
                        var htmlTag = document.getElementById('dossier-form-wrapper'),
                            compiled = $compile(html)($scope),
                            el = ng.element(htmlTag);

                        el.children().remove();
                        el.append(compiled);
                    });
                };

                $scope.chapterProgress = function() {
                    if($scope.dossier == null) return 0;
                    return $scope.dossier.chapterProgress($scope.chapter, $scope.templateDef);
                };

                /**
                 * Function that checks if the current page is the first page.
                 * @returns {boolean}
                 */
                $scope.isFirstPage = function() {
                    var me = this;

                    // Wheter this is the first page or not may depend on conditions (even on the current page)
                    // Need to check dynamically if the current page is the last page in the current chapter

                    if(_.isEmpty(me.templateDef)) return true;
                    return me.findPrevPage() === null;
                };

                /**
                 * Function that checks if the current page is the last page.
                 * @returns {boolean}
                 */
                $scope.isLastPage = function() {
                    var me = this;

                    // Wheter this is the last page or not may depend on conditions (even on the current page)
                    // Need to check dynamically if the current page is the last page in the current chapter

                    if(_.isEmpty(me.templateDef)) return true;
                    return me.findNextPage() === null;
                };

                /**
                 * Go to the next page button.
                 */
                $scope.nextPage = function(form) {
	                var me = this;
                    form.$submitted = true;
                    if(!$scope.nextBtnEnabled(form)) {
                        return;
                    }
                    //if(!$scope.isValid(form)) return;

                    form.$submitted = false;
                    $scope.saveChanges(form).then(function(result) {
                        var page = me.findNextPage(),
                            id = (result && result.id) || me.model.id;
	                    if(page) {
		                    $state.go('locale.app.dossier.overview.edit', {uuID: id, pageSlug: page.properties.slug});
	                    }
                    });
                };

                /**
                 * Go to the previous page button
                 */
                $scope.prevPage = function(form) {
	                var me = this;
                    if(!$scope.isValid(form)) {
                        $scope.saveChanges(form).then(function (result) {
                            if (!me.dossier.id && result.id != null) {
                                // we've inserted a new record. Redirect back to the list
                                $scope.navigateToList();
                                return;
                            }

                            var page = me.findPrevPage();
                            if (page) {
                                $state.go('locale.app.dossier.overview.edit', {uuID: me.model.id, pageSlug: page.properties.slug});
                            }
                        });
                    } else {
                        var page = me.findPrevPage();
                        if (page) {
                            $state.go('locale.app.dossier.overview.edit', {uuID: me.model.id, pageSlug: page.properties.slug});
                        }
                    }
                };

                $scope.prevBtnEnabled = function(form) {
                    return !$scope.isFirstPage();
                };

                $scope.nextBtnEnabled = function(form) {
                    //console.log(form && !(form.$invalid || $scope.isLastPage()));
                    return form && !(form.$invalid || $scope.isLastPage());
                };

                /**
                 * Go to the overview page. Do nothing if something went wrong.
                 */
                $scope.toOverview = function(form) {
                    var dossierId = this.dossier.id;

                    if($scope.isValid(form)) {
                        $scope.saveChanges(form).then(function (result) {
                            dossierId = (result && result.id) || dossierId;
                            $state.go('locale.app.dossier.overview', {uuID: dossierId});
                        });
                    } else {
                        $state.go('locale.app.dossier.overview', {uuID: dossierId});
                    }
                };

                /**
                 * Finds the next page (after the current page) taking page conditions into account
                 */
                $scope.findNextPage = function() {
                    var me = this,
                        p = me.page,
                        index;

                    if(me.chapter == null) return null;

                    index = p == null ? -1 : _.indexOf(me.chapter.items, p);
                    while(++index < me.chapter.items.length) {
                        p = me.chapter.items[index];
                        if(me.pageVisible(p)) {
                            return p;
                        }
                    }
                    return null;
                };

                /**
                 * Finds the next page (after the current page) taking page conditions into account
                 */
                $scope.findPrevPage = function() {
                    var me = this,
                        p = me.page,
                        index;

                    if(me.chapter == null) return null;

                    index = _.indexOf(me.chapter.items, p);
                    while(--index >= 0) {
                        p = me.chapter.items[index];
                        if(me.pageVisible(p)) {
                            return p;
                        }
                    }
                    return null;
                };

                $scope.pageVisible = function(page) {
                    return page.properties.showIf === true || $parse(page.properties.showIf)($scope);
                };

                /**
                 * Function that resets the values on the form reverting the model to the original state.
                 */
                //$scope.reset = function () {
                //    //$scope.model = ng.copy($scope.origModel);
                //    $scope.dossier = $scope.model;      // We want to work with 'dossier' in the template
                //    $scope.dd = $scope.dossier.data;    // Shortcut for dossier.data = dd to simplify formula's in template
                //};

                /**
                 * Function that is called when the user clicks on the cancel button.
                 * This should revert the model to the original state. (???)
                 */
                $scope.cancel = function() {
                    $scope.navigateToList();
                };

                /**
                 * Function that checks if the form has been changed in anyway, returning a boolean variable.
                 * @returns {boolean}
                 */
                $scope.hasChanges = function () {
                    return !angular.equals($scope.model, $scope.origModel);
                };

                /**
                 * Function that checks if the form is still valid, returning a boolean variable.
                 * @param form
                 * @returns {boolean}
                 */
                $scope.isValid = function (form) {
                    return !form.$invalid;
                };

                /**
                 * Saves the form if there are changes.
                 *
                 * @param form
                 * @return {propise}
                 */
                $scope.ok = function() {
                    $scope.toOverview();
                    //$scope.saveChanges();

                    //$scope.save(form).then(function() {
                    //    $scope.navigateToList();
                    //}, function(data) {
                    //    // Nothing yet
                    //});
                };

	            /**
                 * Function that saves the changes to the model. Afterwards resetting the form to its prsitine untouched state.
                 * @param {*} form
                 * @returns {*}
	             */
                $scope.saveChanges = function(form) {
	                var deferred = $q.defer(),
						changes = this.getChangedFields();

                    // Returns all checklist states for a new dossier, changed states for an update
                    changes.checklistState = $scope.model.getChangedChecklistStates($scope.templateDef);

                    $scope.model.saveChanges(changes).then(function(result) {
                        deferred.resolve(result);
                        form.$setPristine();
                        form.$setUntouched();
                    }, function(err) {
                        $scope.handleFailure(err);
                        deferred.reject(err);
                    });

                    return deferred.promise;
                };

                /**
                 * Function that retrieves or creates the dossier object.
                 * This is then used to fetch the dossier template and render it.
                 */
                $scope.getOrCreateDossier = function() {
                    //create a new dossier if the UUID is not specified.
                    //if($stateParams.uuID === 0) {
                    //    $scope.model = DossierResource.createInstance();
                    //
                    //    DossierResource.setActive($scope.model);
                    //    $scope.dossier = $scope.model;      // We want to work with 'dossier' in the template
                    //    $scope.dd = $scope.dossier.data;
                    //    $scope.fetchTemplate();
                    //    return;
                    //}

                    // Load method loads all relevant data and returns the dossier, the dossierTpl and the dossierTplDef
                    DossierResource.load($stateParams.uuID).then(function(data) {

                        $scope.model = data.dossier;
                        //DossierResource.setActive($scope.model);  // Dossier is set active on checkPageSlugs
                        $scope.header = 'Dossier ' + $scope.model.getFullName();
                        $scope.dossier = $scope.model;              // We want to work with 'dossier' in the template
                        $scope.dd = $scope.dossier.data;
                        //$scope.fetchTemplate();

                        $scope.template = data.dossierTpl;
                        $scope.templateDef = data.dossierTplDef;

                        $scope.model.dossierTplId = $scope.template.id;
                        $scope.model.dossierTplDefId = $scope.templateDef.id;

                        $scope.checkSlugs();
                        $scope.updateHtml();
                        if($scope.page) {
                            $scope.storeOriginalFieldValues();
                        }

                        CompanyResource.find($scope.dossier.companyId).then(function(company) {
                            $scope.informationText = company.information;
                        });
                    }, function(err) {
                        $scope.handleFailure(err);
                        $scope.navigateToList();
                    });
                };

                //start retrieving dossier after the html has been rendered
                $timeout(function() {
                    $scope.getOrCreateDossier();
                });

                /**
                 * Show the debug btn toggle only to the ADMIN
                 */
                $scope.showDebugToggleBtn = function() {
                    return Authentication.hasRole('ADMIN');
                };

                /**
                 * Show/hide the debug information for this input.
                 */
                $scope.toggleDebug = function() {
                    $scope.showDebug = !$scope.showDebug;
                    $scope.$broadcast('showDebugToggle', $scope.showDebug);
                };

                /**
                 * Recursively find properties.value values in every page item
                 * @param page
                 */
                $scope.findVariabelesInPage = function(p) {
                    var fields = [];
                    if(!p || !p.items) return fields;

                    var i=p.items.length, item, props;
                    while(i--) {
                        item = p.items[i];
                        props = item.properties;
                        if(props && props.value) {
                            if(item.type == 'CheckboxInput' && !_.isEmpty(props.options)) {
                                // Checkbox contains multiple variabeles
                                var j = props.options.length;
                                while(j--) {
                                    fields.push(props.value+"."+props.options[j].name);
                                }
                            } else if(item.type === 'Row') {
                                fields.push(props.value);
                                continue;
                            } else {
                                fields.push(props.value);
                            }
                        }
                        if(item.items) {
                            var f = $scope.findVariabelesInPage(item);
                            if(f.length > 0) {
                                fields = fields.concat(fields, f);
                            }
                        }
                    }
                    //console.log(fields);
                    return fields;
                };

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                    watchDossierTemplates();
                    //DossierResource.unsetActive();
                    $scope.dossierActionListener();
                    $scope.failureHandler = null;
                });
            }
        ]
    );
});
