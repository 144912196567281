/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 27-7-2015
 * Time: 10:37
 * For Project: nabes-front
 * @version : 0.1
 */

define('components/dossier/upload/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base',
    'angularFileUpload', //file upload
    'angularCookie', //angular cookie
    'components/dossier/base', //base components
	'model/dossier-document-resource' //dossier resource
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Upload', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent',
            'ngFileUpload',
            'DossierDocumentResource',
            'ipCookie'
        ]
    ).directive('nbUpload', [
        'config',
        'Authentication',
        '$uiViewScroll',
        '$rootScope',
        'Upload',
        'DossierDocumentResource',
        'ipCookie',
        function(config, Authentication, $uiViewScroll, $rootScope, Upload, DossierDocumentResource, ipCookie) {
            return {
                restrict: "E",
                transclude: true,
                scope: {
                    dossier: '=data',
                    State: '@state',
                    ShowIf: '=showIf',
                    docType: '@docType',
                    allowSelectType: '@allowSelectType',
                    title: '@title',
                    templateId: '@templateId',
                    id: '@id',
                    Name: '@name',
                    Label: '@label',
                    PlaceHolder: '@placeholder',
                    Required: '=required',
                    Explanation: '@explanation'
                },
                templateUrl: "app/components/dossier/upload/upload.html",
                require: '^form',
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.showIfText = attr.showIf;
                    scope.showDebug = false;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;
                    if(scope.State === undefined) scope.State = 'important';
                    scope.allowSelectType =  attr.allowSelectType === 'true';


                    scope.token = ipCookie('NB_TOKEN');
                    scope.downloadUrl = config.getAPIUrl('/document/download/');

                    scope.docTypes = DossierDocumentResource.docTypes;
                    scope.docTypeObject = "";
                    if(scope.docType != null) scope.docTypeObject = DossierDocumentResource.getDocType(scope.docType);

                    scope.file = undefined;
                    scope.showUpload = false;

                    scope.upload = function(file) {
                        DossierDocumentResource.upload(file, scope.dossier.id, scope.docType).then(function() {
                            scope.showUpload = false;
                            scope.picFile = null;
                            formCtrl.$setPristine();
                            formCtrl.$setUntouched();

                            // Note: Upon a successful upload, another document may have been removed by the server
                            // because for some docTypes only one document may exist. A previously uploaded document
                            // with the same docyType may have been removed. Thus, reload the complete list of documents.
                            DossierDocumentResource.loadDocumentsForDossier(scope.dossier.id).then(function() {
                                scope.getFileByDocType();
                            });
                        }, function(data) {
                            console.log("Upload failed");
                            console.log(data);
                        });
                    };

                    scope.getFileByDocType = function() {
	                    DossierDocumentResource.getByDocType(scope.dossier.id, scope.docType).then(function (item) {
                            if(item) {
                                scope.file = item;
                            } else {
                                scope.file = undefined;
                                scope.showUpload = true;
                            }
                        }, function(err) {
                            scope.file = undefined;
                            scope.showUpload = true;
                        });
                    };
                    scope.getFileByDocType();

                    scope.cancelUpload = function() {
                        scope.showUpload = false;
                        scope.picFile = null;
                    };

                    scope.docTypeDescription = function(document) {
                        return DossierDocumentResource.docTypeDescription(document);
                    };

                    scope.deleteDocument = function(document) {
                        DossierDocumentResource.destroy(document.id).then(function() {
                            scope.file = undefined;
                        }, function(data) {
                            console.log("Delete failed");
                            console.log(data);
                        });
                    };

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleShowUpload = function() {
                        scope.showUpload = !scope.showUpload;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                    });
                }
            };
        }
    ]);
});
