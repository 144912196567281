define('components/dossier/button/directive',[
    'angular',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Button', [
            'Twensoc.Config',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbButton', [
        'config',
        '$parse',
        'DossierResource',
        function(config, $parse, DossierResource) {
            return {
                restrict: "E",
                scope: {
                    Dossier: '=data',
                    Name: '@name',
                    Label: '@label',
                    Icon: '@icon',
                    ShowIf: '=showIf',
                    EnabledIf: '=enabledIf',
                    Action: '@action'
                },
                require: '^form',
                templateUrl: "app/components/dossier/button/button.html",
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.Label = 'Hello world';
                    scope.Style = attr.state || 'blue';

                    scope.onClick = function($event) {
                        $event.preventDefault();
                        formCtrl.$submitted = true;

                        if (!scope.buttonEnabled()) return;
                        if(formCtrl.$valid !== true) return;

                        var data = {
                            action: scope.Action,
                            dossierId: scope.Dossier.id,
                            rev: scope.Dossier.rev
                        };
                        data[scope.Name] = _.getByString(scope.Dossier, scope.Name);
                        scope.$emit('dossierAction', data);
                        formCtrl.$submitted = false;
                    };

                    scope.buttonEnabled = function () {
                        return scope.EnabledIf === undefined || scope.EnabledIf === true || $parse(scope.EnabledIf)(scope.Dossier) === true;
                    };

                    scope.buttonVisible = function () {
                        return scope.ShowIf === undefined || scope.ShowIf === true || $parse(scope.ShowIf)(scope.Dossier) === true;
                    };



                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {

                    });
                }
            };
        }
    ]);
});

