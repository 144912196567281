/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-1-2015
 * Time: 11:38
 * For Project: fairplayV2
 * @version : 0.1
 */

/**
 * Require JS Config options.
 */
require.config({
    baseUrl: 'app',
    paths: {
        'domReady': '../bower_components/requirejs-domready/domReady',
        'angular': '../bower_components/angular/angular',
        'angularUIRouter': '../bower_components/angular-ui-router/release/angular-ui-router',
        'angularTouch': '../bower_components/angular-touch/angular-touch',
        'angularSanitize': '../bower_components/angular-sanitize/angular-sanitize',
        'angularTranslate': '../bower_components/angular-translate/angular-translate',
        'angularTranslateStaticFiles': '../bower_components/angular-translate-loader-static-files/angular-translate-loader-static-files',
        'angularCookie': '../bower_components/angular-cookie/angular-cookie',
        'angularAnimate': '../bower_components/angular-animate/angular-animate',
        'angularAria': '../bower_components/angular-aria/angular-aria',
        'angularUIBootstrap': '../bower_components/angular-ui-bootstrap-bower/ui-bootstrap-tpls',
        'angularFileUpload': '../bower_components/ng-file-upload/ng-file-upload',
        'angularFileUploadShim': '../bower_components/ng-file-upload/ng-file-upload-shim',
        'angular-toasty': '../bower_components/angular-toasty/dist/angular-toasty',
        'uiCodemirror':'../bower_components/angular-ui-codemirror/ui-codemirror',
        'js-data': '../bower_components/js-data/dist/js-data-debug',
        'js-data-angular': '../bower_components/js-data-angular/dist/js-data-angular',
        'lodash': '../bower_components/lodash/lodash',
        'sockjs': '../bower_components/sockjs-client/dist/sockjs.min',
        'flashDetect': '../bower_components/flashdetect/FlashDetect',
        'config': 'utils/config',
        'template': 'utils/template',
        'vertx-eventbus': '../lib/vertx-eventbus'
    },
    packages: [{
        name: "codemirror",
        location: "../bower_components/codemirror",
        main: "lib/codemirror"
    }],
    shim: {
        'angular': {
            exports: 'angular'
        },
        'template': {
            deps: ['angular']
        },
        'angularUIRouter': {
            deps: ['angular']
        },
        'angularUIBootstrap': {
            deps: ['angular']
        },
        'angularTouch': {
            deps: ['angular']
        },
        'angularSanitize': {
            deps: ['angular']
        },
        'angularTranslate': {
            deps: ['angular']
        },
        'angularTranslateStaticFiles': {
            deps: ['angular', 'angularTranslate']
        },
        'angularCookie': {
            deps: ['angular']
        },
        'angularAria': {
            deps: ['angular']
        },
        'angularAnimate': {
            deps: ['angular']
        },
        'angularFileUploadShim': {
            deps: ['angular']
        },
        'angularFileUpload': {
            deps: ['angular', 'angularFileUploadShim']
        },
        'lodash': {
            exports: '_'
        },
        'angular-toasty': {
            deps: ['angular']
        },
        'codemirror': {
            exports: 'CodeMirror'
        },
        'uiCodemirror': {
            deps: ['angular', 'codemirror']
        },
        'vertx-eventbus': {
            deps: ['sockjs']
        }
    }
});

var MAX_INT = 4294967295;

/**
 * Start the application
 */
require([
    'angular',
    'angularUIRouter',
    'angularTouch',
    'angularSanitize',
    'angularTranslate',
    'angularTranslateStaticFiles',
    'angularUIBootstrap',
    'domReady',
    'lodash',
    'config',
    'template',
    'app',
    'js-data',
    'js-data-angular'
],
function(ng, ng_ui_router, ng_touch, ng_sanitize, ng_translate, ng_translate_static_files, ng_ui_bootstrap, domReady, _, config, template, Twensoc, jsData, jsDataAngular) {
    'use strict';

    if('file:' === document.location.protocol) {
        document.addEventListener("deviceready", function() { deviceReady(true); }, false);
    } else {
        domReady(function() { deviceReady(false); });
    }

    /**
     * The device ready function this is the initialization function of the whole application.
     * This is called when the dom is ready and everything is loaded and ready to go.
     * It gets passed two parameters a isPhone and a flashEnabled these variables are two booleans
     * Determining which type of browser the user has / the capabilities of his/her browser.
     * @param {boolean} phoneGapApp
     */
    function deviceReady(phoneGapApp) {
        templateConfig.phoneGapApp = phoneGapApp;

        /**
         * Pre angular configuration since the document is fully loaded at this point
         * This should be kept to a minimal since its not very clean.
         */
        ng.module('underscore', [])
            .factory('_', function() {
                return _;
            });

        /**
         * Add the isInteger functionality to underscore js which is quite useful to have
         * @param {*} nVal
         * @returns {boolean}
         */
        _.isInteger = function isInteger (nVal) {
            return typeof nVal === "number" && isFinite(nVal) && nVal > -9007199254740992 && nVal < 9007199254740992 && Math.floor(nVal) === nVal;
        };

        _.guid = function() {
            function _p8(s) {
                var p = (Math.random().toString(16)+"000000000").substr(2,8);
                return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
            }
            return _p8() + _p8(true) + _p8(true) + _p8();
        };

        /**
         * Retrieve the boolean form of any type of data input.
         * @param {*} data
         * @returns {boolean}
         */
        _.castToBoolean = function(data) {
            if (typeof data === 'string') data = data.toLowerCase();
            return (data === 'true' || data === 1 || data === true || data === 'y');
        };

        /**
         * Function that gets the Objects value by String dot separated
         * Allowing you to quickly access it by string
         * @param {{}} object
         * @param {string} string
         * @returns {*}
         */
        _.getByString = function(object, string) {
            if(string == null) return string;
            var array = string.split('.');
            for (var i = 0, n = array.length; i < n; ++i) {
                var key = array[i];
                if (key in object) {
                    object = object[key];
                    if(object == null) return;
                }
                else return;
            }
            return object;
        };

        /**
         * Function that sets the Object value by String dot separated
         * @param {{}} object
         * @param {String} string
         * @param {*} value
         */
        _.setByString = function(object, string, value) {
            var schema = object;  // a moving reference to internal objects within obj
            var array = string.split('.');
            var length = array.length;
            for(var i = 0; i < length - 1; i++) {
                var elem = array[i];
                if( !schema[elem] ) schema[elem] = {}; //create new object if it does not exist
                schema = schema[elem];
            }
            schema[array[length -1]] = value;
        };

        /**
         * Deeply compare two arrays.
         *
         * @param one Array
         * @param two Array
         * @returns {Boolean} True if any difference between the arrays has been found. False otherwise
         */
        _.arrayDifference = function(one, two) {
            if(one == null && two == null) return false;
            if(one == null || two == null) return true;
            if(one.length !== two.length) return true;

            // Neither array is null, both have the same length
            // Compare properties
            var i=one.length, a, b, p;
            while(i--) {
                a = one[i];
                b = two[i];
                for(p in a) {
                    if(a.hasOwnProperty(p) && p !== '$$hashKey') {
                        if(a[p] !== b[p]) return true;
                    }
                }
                for(p in b) {
                    if(b.hasOwnProperty(p) && p !== '$$hashKey') {
                        if(a[p] !== b[p]) return true;
                    }
                }
            }
            return false;
        };

        /**
         * Function that returns a date object from the specified format.
         * @param {string} dateString
         * @returns {Date}
         */
        _.getDateFromFormat = function(dateString) {
            if(_.isEmpty(dateString)) {
                return new Date();
            }

            return new Date(dateString);
        };

        /**
         * Function that converts a date into the default format used throughout the application.
         * @param {Date} date
         * @returns {string}
         */
        _.formatDate = function(date) {
            var month = (date.getMonth() + 1 >= 10) ? date.getMonth() + 1: "0" + (date.getMonth() + 1);
            var day = (date.getDate() >= 10) ? date.getDate() : "0" + date.getDate();
            return date.getFullYear() + '-' + month + '-' + day;
        };

        /**
         * https://gist.github.com/spoeken/4705863
         * @param start
         * @param end
         * @return {{years: number, months: number, days: number, hours: *, minutes: *, seconds: *}}
         */
        _.dateDiff = function(start, end) {
            //today, now!
            //Get the diff
            var diff = end - start;
            //Create numbers for dividing to get hour, minute and second diff
            var units = [
                1000 * 60 * 60 *24,
                1000 * 60 * 60,
                1000 * 60,
                1000
            ];

            var rv = []; // h, m, s array
            //loop through d, h, m, s. we are not gonna use days, its just there to subtract it from the time
            for (var i = 0; i < units.length; ++i) {
                rv.push(Math.floor(diff / units[i]));
                diff = diff % units[i];
            }

            //Get the year of this year
            var thisFullYear = end.getFullYear();
            //Check how many days there where in last month
            var daysInLastMonth = new Date(thisFullYear, end.getMonth(), 0).getDate();
            //Get this month
            var thisMonth = end.getMonth();
            //Subtract to get differense between years
            thisFullYear = thisFullYear - start.getFullYear();
            //Subtract to get differense between months
            thisMonth = thisMonth - start.getMonth();
            //If month is less than 0 it means that we are some moths before the start date in the year.
            // So we subtract one year, and add the negative number (month) to 12. (12 + -1 = 11)
            var subAddDays = daysInLastMonth - start.getDate();
            var thisDay = end.getDate();
            thisMonth = thisMonth - 1;
            if(thisMonth < 0){
                thisFullYear = thisFullYear - 1;
                thisMonth = 12 + thisMonth;
                //Get ends day of the month
            }
            //Subtract the start date from the number of days in the last month, add add the result to todays day of the month
            subAddDays = daysInLastMonth - start.getDate();
            subAddDays = thisDay + subAddDays;


            if(subAddDays >= daysInLastMonth){
                subAddDays = subAddDays - daysInLastMonth;
                thisMonth++;
                if (thisMonth > 11){
                    thisFullYear++;
                    thisMonth = 0;
                }
            }
            //console.log( {
            //    years: thisFullYear,
            //    months: thisMonth,
            //    days: subAddDays,
            //    hours: rv[1],
            //    minutes: rv[2],
            //    seconds: rv[3]
            //});
            return {
                years: thisFullYear,
                months: thisMonth,
                days: subAddDays,
                hours: rv[1],
                minutes: rv[2],
                seconds: rv[3]
            };
        };

        //first set the language before bootstrapping the application this prevents any errors down the line.
        ng.bootstrap(document, ['Twensoc']);
    }
});
define("main", function(){});

