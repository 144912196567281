/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/account/form/controller',[
	"angular", "lodash", "model/account-resource", //account resource
	"model/dossier-resource", //dossier resource
	"collection/toast-messages", //toast messages collection
	"utils/authentication", // authentication
	"utils/vertx-client" // vertx client
], function (ng, _) {
	"use strict";

	return ng.module("NabesFront.controllers.AccountFormController", [
		"Twensoc.Config",
		"NabesFront.collection.ToastMessagesCollection",
		"AccountResource",
		"NabesFront.model.UserModel",
		"DossierResource",
		"NabesFront.utils.Authentication",
		"Twensoc.utils.VertxClient"
	]).controller("AccountFormController", [
		"$log",
		"$state",
		"$stateParams",
		"$scope",
		"$rootScope",
		"config",
		"AccountResource",
		"toastMessages",
		"UserModel",
		"DossierResource",
		"Authentication",
		"VertxClient",
		function ($log,
			$state,
			$stateParams,
			$scope,
			$rootScope,
			config,
			AccountResource,
			toastMessages,
			UserModel,
			DossierResource,
			Authentication,
			VertxClient
		) {
			$scope.Config = config;
			$rootScope.Title = "Account form pagina";
			$rootScope.BodyClass = "account";
			$scope.model = {};
			$scope.companyName = "";

			$scope.companyId = $stateParams.companyId;
			$scope.accountId = $stateParams.accountId || 0;

			$scope.dossiers = [];
			$scope.filter = {
				accountId: $scope.accountId,
				pageNo: 1,
				totalItems: MAX_INT,
				pageSize: 100
			};

			$scope.isAdmin = UserModel.hasRole("ADMIN");
			$scope.roles = $scope.isAdmin ? ["USER", "BGO", "ADMIN"] : ["USER"];
			$scope.canChange = $scope.isAdmin || $scope.accountId === UserModel.id || $scope.accountId === 0;

			$scope.hasChanges = function () {
				return true;//!angular.equals($scope.model, $scope.origModel);
			};

			$scope.isValid = function (form) {
				return !form.$invalid;
			};

			/**
			 * Function that checks if this input has a specific error.
			 * If this is the case the error is displayed to the user.
			 * @param {string} fieldName
			 * @param {string} errorName
			 * @returns {boolean}
			 */
			$scope.hasError = function (fieldName, errorName) {
				var value = _.getByString($scope.form[fieldName].$error, errorName);
				return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
			};

			$scope.isReadOnly = function () {
				return $scope.canChange;
			};

			$scope.ok = function (form) {
				if (form.$invalid) {
					return;
				}
				if (!$scope.hasChanges()) {
					$scope.navigateToList();
					return;
				}
				$scope.model.locale = "nl_NL";

				if ($scope.model.id == null) {
					AccountResource.create($scope.model).then(function (data) {
						$scope.navigateToList();
						toastMessages.add(toastMessages.T_INFO, "", "info.newAccountNeedsVerification", 8000);
					}, $scope.failureHandler);

				} else {
					var newEmail = $scope.model.email;
					AccountResource.update($scope.model.id, $scope.model).then(function (data) {
						if (data.emailChanged === true) {
							toastMessages.add(toastMessages.T_INFO,
								"info.newEmailNeedsVerificationTitle",
								"info.newEmailNeedsVerification",
								8000,
								{email: newEmail}
							);
							delete(data.emailChanged);
						}
						ng.merge($scope.model, data);
						$scope.navigateToList();
					}, $scope.failureHandler);
				}

			};

			$scope.handleFailure = function (err) {
				if (err == null) err = "unknown-error";
				if (err.message) err = err.message.toLowerCase().replace(" ", "-");
				toastMessages.add(toastMessages.T_DANGER, "", "failure." + err, 4000);
			};
			$scope.failureHandler = $scope.handleFailure.bind($scope);

			$scope.reset = function () {
			};

			$scope.cancel = function () {
				$scope.navigateToList();
			};

			$scope.navigateToList = function () {
				if ($scope.companyId > 0) {
					$state.go("locale.app.company.edit.account", {
						Locale: config.getUrlPrefix(),
						companyId: $scope.companyId
					});
					return;
				}
				$state.go("locale.app.account", {Locale: config.getUrlPrefix()});
			};

			$scope.setParent = function () {
				if (_.isNumber($stateParams.companyId)) {
					$scope.model.companyId = $stateParams.companyId;
					// TODO: Load company name
				}
			};

			$scope.getCompanyName = function () {
				return $scope.companyName;
			};

			if ($scope.accountId === 0) {
				$scope.model = AccountResource.createInstance();
				$scope.setParent();
			} else {
				AccountResource.find($scope.accountId).then(function (account) {
					$scope.model = account;
					$scope.companyName = "(" + account.companyName + ")";
				}, function (msg) {
					$scope.navigateToList();
					$scope.handleFailure(msg);
				});
			}

			DossierResource.findAll($scope.filter, {bypassCache: true}).then(function (dossiers) {
				$scope.dossiers = dossiers;
			});

			$scope.editLink = function (dossierId, event) {
				if (event == null || event.target.closest("div.receivesNotifications") != null) return;

				if (Authentication.hasRole("BGO")) {
					$state.go("locale.app.dossier.overview.edit", {uuID: dossierId});
				} else {
					$state.go("locale.app.dossier.overview", {uuID: dossierId});
				}
			};

			$scope.changeEmailSubscription = function (dossierId, change) {
				var action = (change === 1) ? "subscribe/dossierupdate/mail"
					: "unsubscribe/dossierupdate/mail";
				VertxClient.send("/notification/settings", {
					payload: {
						data: {
							action: action,
							user: $scope.accountId,
							dossier: dossierId
						}
					}
				}).then(function () {
					toastMessages.add(
						toastMessages.T_SUCCESS,
						"",
						"dossierList.sendMailChangedSuccessfully",
						4000
					);
				}, function (err) {
					toastMessages.add(toastMessages.T_DANGER, "", "failure." + err, 8000);
				});
			};

			/**
			 * Time for some cleaning up of listeners! this is very important!
			 */
			$scope.$on("$destroy", function () {
				$scope.failureHandler = null;
			});
		}
	]);
});
