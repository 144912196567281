/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 5-2-2015
 * Time: 15:08
 * For Project: nabes
 * @version : 0.1
 */

define('components/tabs-panel/directive',[
    'angular'
], function (ng) {
    'use strict';

    return ng.module(
        'Nabes.components.tabsPanel', []
    ).directive('tabsPanel', function() {
        return {
            restrict: "E",
            transclude: true,
            templateUrl: "app/components/tabs-panel/tabs-panel.html",
            controller: [
                '$scope',
                function($scope) {
                    $scope.panes = [];

                    /**
                     * Function that selects one pane to become active this is also the pane that is shown to the user at this point.
                     * @param {{}} pane
                     */
                    $scope.select = function(pane) {
                        ng.forEach($scope.panes, function(pane) { pane.selected = false; });
                        pane.selected = true;
                    };

                    /**
                     * Function that adds a pane to the scope.panes array.
                     * If the length is 0 it also selects the pane as the default panel.
                     * @param {{}} pane
                     */
                    this.addPane = function(pane) {
                        if($scope.panes.length === 0) $scope.select(pane);
                        $scope.panes.push(pane);
                    };

                    $scope.currentTabIndex = function() {
                        var i = $scope.panes.length;
                        while(i--) {
                            if($scope.panes[i].selected) return i;
                        }
                    };
                }
            ]
        };
    })
    .directive('tabPane', [
        function() {
            return {
                restrict: 'E',
                templateUrl: "app/components/tabs-panel/tab-pane.html",
                require: '^tabsPanel',
                transclude: true,
                scope: {
                    title: '@'
                },
                link: function(scope, element, attrs, tabsCtrl) {
                    tabsCtrl.addPane(scope);
                }
            };
        }
    ]);
});
