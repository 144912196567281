/**
 * Created by Schippie.
 * Author URL: http://schippie.com/
 * Date: 15-Sep-2015 - 10:16
 * Project: nabes-front -
 */

/**
 * This directive allows you to inject ng-repeat scope variables into the transcluded items
 * This is useful for when you are trying to render a template for exmpale and need to set properties within an array
 * based on the $index of the ng-repeat like:
 *
 * ng-model="data.somearray[$index].value"
 *
 * Any HTML within the div containing the transclude-inject directive is appended or prepended at the end of the transclusion.
 * This is based on if the transclude-inject property contained the word prepend or not (<div transclude-inject="prepend"></div>) for example
 *
 * Thanks to: https://github.com/angular/angular.js/issues/7874#issuecomment-47647528
 */
define('utils/transclude-inject',[
    'angular'
], function (ng) {
    'use strict';

    return ng.module(
        'Twensoc.utils.TranscludeInject', []
    ).directive('transcludeInject', [
        function () {
            return {
                link: function($scope, $element, $attrs, controller, $transclude) {
                    if (!$transclude) {
                        throw minErr('ngTransclude')('orphan',
                            'Illegal use of ngTransclude directive in the template! ' +
                            'No parent directive that requires a transclusion found. ' +
                            'Element: {0}',
                            startingTag($element));
                    }
                    var innerScope = $scope.$new();
                    var preprend = ($attrs.transcludeInject === 'prepend');

                    $transclude(innerScope, function(clone) {
                        if(preprend === true) $element.prepend(clone);
                        else $element.append(clone);

                        $element.on('$destroy', function() {
                            innerScope.$destroy();
                        });
                    });
                }
            };
        }
    ]);
});

