/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/dossier-invite/controller',[
	'angular',
	'lodash',
	'components/header/directive', //header directive
	'components/footer/directive', //footer directive
	'utils/authentication', //authentication service
	'model/dossier-resource', //dossier resource
	'config',
	'collection/toast-messages' //toast messages collection
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.controllers.DossierInviteController', [
			'Twensoc.Config',
			'Twensoc.components.tsHeader',
			'Twensoc.components.tsFooter',
			'DossierResource',
			'NabesFront.collection.ToastMessagesCollection'
		]
	).controller(
		'DossierInviteController', [
			'$state',
			'$stateParams',
			'$timeout',
			'$scope',
			'$rootScope',
			'config',
			'DossierResource',
			'$http',
			'toastMessages',
			function ($state, $stateParams, $timeout, $scope, $rootScope, config, DossierResource, $http, toastMessages) {
				$scope.Config = config;
				// $rootScope.Title = "Nabes - Uitnodiging";
				$rootScope.BodyClass = "dossier-invite";

				var input = $stateParams.data || {};
				$scope.account = {
					firstName: input.firstName || '',
					infix: input.infix || '',
					surname: input.surname || '',
					gender: input.gender || '',
					phone: input.phone || ''
				};
				$scope.MaxEmailLength = 60;
				$scope.MaxFirstNameLength = 40;
				$scope.MaxInfixLength = 10;
				$scope.MaxSurnameLength = 60;
				$scope.MaxPhoneLength = 25;
				$scope.MaxPasswLength = 80;

				$scope.msgSend = false;
				$scope.token = $stateParams.data.token;

				$scope.hasToken = function () {
					return !_.isEmpty($scope.token);
				};

				/**
				 * Function that checks if this input has a specific error.
				 * If this is the case the error is displayed to the user.
				 * @param {string} fieldName
				 * @param {string} errorName
				 * @returns {boolean}
				 */
				$scope.hasError = function(fieldName, errorName) {
					var value = _.getByString($scope.inviteForm[fieldName].$error, errorName);
					return (($scope.inviteForm.$submitted === true || $scope.inviteForm[fieldName].$dirty === true) && value);
				};

				$scope.hasChanges = function () {
					return !angular.equals($scope.model, $scope.origModel);
				};

				$scope.isValid = function (form) {
					return !form.$invalid;
				};

				$scope.ok = function () {
					var me = this, form = $scope.inviteForm, account = $scope.account;
					//check if the form is valid or is already being submitted if so return and do not do anything
					if (form.$valid === false || form.submitting === true) return;

					//start submitting the form showing the loader to the user.
					form.submitting = true;

					// Additional checks
					// if (_.isEmpty(account.firstName)) {
					// 	form.firstName.$error.required = true;
					// 	form.$valid = false;
					// }
					if (_.isEmpty(account.surname)) {
						form.surname.$error.required = true;
						form.$valid = false;
					}
                    // if (_.isEmpty(account.phone)) {
                    //     form.phone.$error.required = true;
                    //     form.$valid = false;
                    // }
					if (_.isEmpty(account.password)) {
						form.password.$error.required = true;
						form.$valid = false;
					}
					if (_.isEmpty(account.password2)) {
						form.password2.$error.required = true;
						form.$valid = false;
					}
					if ($scope.account.password != $scope.account.password2) {
						form.password2.$error.nonEqual = true;
						form.$valid = false;
					}
					if (!form.$valid) return;


					$http.post(config.getAPIUrl('token'), {
						token: $scope.token,
						firstName: account.firstName,
						infix: account.infix,
						surname: account.surname,
						password: account.password,
						gender: account.gender,
						phone: account.phone
					}).
						then(function (response) {
							form.submitting = false;
							if (response.data) {
								if (response.data.success === false) {
									me.msgSend = true;
									//$state.go('locale.app');

								} else if (response.data.success === true) {
									me.msgSend = true;
									toastMessages.add(toastMessages.T_INFO, '', 'dossierInvite.successMsg', 8000);
									$state.go('locale.login');
								}
							}
						}, function (response) {
							form.submitting = false;
							alert('failed. do something');
						});
				};

				$scope.reset = function () {
					$scope.model = ng.copy($scope.origModel);

					// We want to work with 'account' in the template
					$scope.account = $scope.model;
				};

				$scope.cancel = function () {
					$scope.navigateToList();
				};

				$scope.navigateToList = function () {
					if (_.isEmpty($stateParams.companyId)) {
						$state.go('locale.app.account.list', {Locale: config.getUrlPrefix()});
					} else {
						$state.go('locale.app.company.accountlist', {Locale: config.getUrlPrefix(), companyId: $stateParams.companyId});
					}
				};

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
				});
			}
		]
	);
});
