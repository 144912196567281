/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier-template/form/controller',[
	'angular',
	'lodash',
	'codemirror',
	'codemirror/addon/edit/matchbrackets',
	'codemirror/addon/edit/closetag',
	'codemirror/addon/display/fullscreen',
	'codemirror/addon/dialog/dialog',
	'codemirror/addon/search/searchcursor',
	'codemirror/addon/search/search',
	'codemirror/addon/lint/lint',
	'uiCodemirror',
	'model/dossier-template-resource', //dossier template resource
	'collection/toast-messages' //toast messages collection
], function (ng, _, codeMirror) {
	'use strict';

	window.CodeMirror = codeMirror;
	/* Example definition of a simple mode that understands a subset of
	 * JavaScript:
	 */
	codeMirror.defineMode("bbcode", function (config, parserConfig) {
		var c = null,
			style = null,
			word = null,
			bracketCount = 0;

		function tokenBase(stream, state) {
			c = stream.next();
			if (c == "[") {
				stream.eatWhile(/([a-zA-Z]|\/)/);
				state.tokenize = tokenizeAttributeName;
				style = "tag";
			} else if (c == "]") {
				state.tokenize = null;
				style = "tag";
			} else {
				style = '';
			}
			return style;
		}

		/**
		 * Read the first word directly followed by a semicolon - or a closing bracket
		 * @param stream
		 * @param state
		 * @return {string} The name of the style
		 */
		function tokenizeAttributeName(stream, state) {

			stream.eatSpace();
			stream.eatWhile(/^((?!(:|])).)/);  // http://stackoverflow.com/questions/406230/regular-expression-to-match-line-that-doesnt-contain-a-word

			c = stream.peek();
			if (c == ']') {
				// No attribute found > Close tag
				stream.next();
				state.tokenize = null;
				style = 'tag';
			} else if (c === null || c === undefined) {
				// End of file - Attribute or closing tag expected > Error
				state.tokenize = null;
				style = 'error';
			} else {
				word = stream.current();
				stream.next();
				state.tokenize = tokenizeAttributeValue;
				style = "attr-name";
			}
			return style;
		}

		/**
		 * Read anything until eof, a closing bracket or a word followed by a semicolon.
		 * The last word before a semicolon is the next attribute
		 * In an attribute value, brackets [] are allowed
		 *
		 * @param stream
		 * @param state
		 * @return {string} The name of the style
		 */
		function tokenizeAttributeValue(stream, state) {
			stream.eatSpace();
			bracketCount = 0;
			c = stream.peek();
			while (c !== ':' && c != null && (bracketCount > 0 || (bracketCount === 0 && c !== ']'))) {
				if (c == '[') {
					bracketCount++;
				} else if (c == ']' && bracketCount > 0) {
					bracketCount--;
				}
				stream.next();
				c = stream.peek();
			}

			if (c == ']' && bracketCount !== 0) {
				stream.next();
				state.tokenize = null;
				style = 'error';
			} else if (c == null) {
				// End of line or End of file - A value may span multiple lines
				style = 'attr-value';

			} else if (c == ']') {
				state.tokenize = null;
				style = 'attr-value';
			} else {
				word = stream.current();

				// Move back before word followed by semicolon
				// Anything before that is the value we're looking for
				var s = word.split(' '),
					len = s[s.length - 1].length;

				word = word.substring(0, word.length - len);
				stream.backUp(len);
				state.tokenize = tokenizeAttributeName;
				style = 'attr-value';
			}
			return style;
		}

		return {

			startState: function () {
				return {
					tokenize: null, attr: false
				};
			},

			token: function (stream, state) {
				if (stream.eatSpace()) return null;
				var style = (state.tokenize || tokenBase)(stream, state);
				return style;
			}
		};

	});


	return ng.module(
		'NabesFront.controllers.DossierTemplateFormController', [
			'Twensoc.Config',
			'NabesFront.collection.ToastMessagesCollection',
			'DossierTemplateResource',
			'DossierTemplateDefinitionResource',
			'TemplateInterpreter',
			'ui.codemirror'
		]
	).controller(
		'DossierTemplateFormController', [
			'$log',
			'$state',
			'$stateParams',
			'$scope',
			'$q',
			'$http',
			'$rootScope',
			'config',
			'ipCookie',
			'DossierTemplateResource',
			'DossierTemplateDefinitionResource',
			'toastMessages',
			'TemplateInterpreter',
			function ($log, $state, $stateParams, $scope, $q, $http, $rootScope, config, ipCookie, DossierTemplateResource, DossierTemplateDefinitionResource, toastMessages, templateInterpreter) {
				$scope.Config = config;
				$rootScope.Title = "Template form pagina";
				$rootScope.BodyClass = "dossierTemplate";

				$scope.isSomething = false;
				$scope.tplErrors = [];

				$scope.dossierTemplateDef = {};
				$scope.templateContent = '';


				$scope.editorOptions = {
					lineWrapping: true,
					lineNumbers: true,
					matchBrackets: true,
					autoCloseTags: true,
					mode: 'bbcode',
					
					extraKeys: {
						"F11": function (cm) {
							cm.setOption("fullScreen", !cm.getOption("fullScreen"));
							$scope.updateErrorLayout();
						},
						"Esc": function (cm) {
							if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
							$scope.updateErrorLayout();
						},
						"F9": function (cm) {
							$scope.checkSyntax($scope.form);
						},
						"Ctrl-S": function (cm) {
							$scope.checkSyntax($scope.form);
							$scope.updateErrorLayout();
							$scope.save($scope.form);
							return false;
						},
						"Cmd-S": function (cm) {
							$scope.checkSyntax($scope.form);
							$scope.updateErrorLayout();
							$scope.save($scope.form);
							return false;
						}
					}
				};

				/**
				 * Function that checks if this input has a specific error.
				 * If this is the case the error is displayed to the user.
				 * @param {string} fieldName
				 * @param {string} errorName
				 * @returns {boolean}
				 */
				$scope.hasError = function(fieldName, errorName) {
					var value = _.getByString($scope.form[fieldName].$error, errorName);
					return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
				};

				$scope.updateErrorLayout = function () {
					var cmDiv = angular.element(document.getElementsByClassName('CodeMirror')),
						errDiv = angular.element(document.getElementById('tpl-errors'));

					if ($scope.tplErrors.length > 0) {
						cmDiv.addClass('error');
						errDiv.addClass('error');
					} else {
						cmDiv.removeClass('error');
						errDiv.removeClass('error');
					}

					if (cmDiv.hasClass('CodeMirror-fullscreen')) {
						errDiv.addClass('fullscreen');
					} else {
						errDiv.removeClass('fullscreen');
					}
				};

				$scope.checkSyntax = function (form) {
					return;
					//var result = templateInterpreter.parse($scope.model.tplDefinition);
					//if (result.errors && result.errors.length > 0) {
					//	$scope.tplErrors.splice(0);
					//	$scope.tplErrors = $scope.tplErrors.concat(result.errors);
					//}
				};

				$scope.handleFailure = function(err) {
					if(err == null) err = 'unknown-error';
					if(err.message) err = err.message.toLowerCase().replace(" ", "-");
					toastMessages.add(toastMessages.T_DANGER, '', 'failure.'+err, 4000);
				};
				$scope.failureHandler = $scope.handleFailure.bind($scope);


				// TODO: Fixme
				$scope.hasChanges = function () {
					return true; //!angular.equals($scope.model, $scope.origModel) || $scope.templateContent !== $scope.dossierTemplateDef.template;
				};

				$scope.isValid = function (form) {
					return !form.$invalid;
				};

				$scope.ok = function(form) {
					if(form.$invalid) return;

					$scope.save(form).then(function () {
						$scope.navigateToList();
					}, function (data) {
						// Nothing yet
					});
				};

				/**
				 * Function that saves the form.
				 * Saves the dossierTpl and the dossierTplDef records
				 *
				 * @param {{}} form
				 */
				$scope.save = function (form) {
					$scope.form.$submitted = true;
					var deferred = $q.defer();

					//if the form is invalid or it has not changes return.
					if (form.$invalid) {
						deferred.reject();
						return;
					}

					var dossierTpl = $scope.dossierTemplate,
						dossierTplDef = $scope.dossierTemplateDef;

					if(!dossierTpl.id) {
						DossierTemplateResource.saveIfChanged(dossierTpl).
							then(function(savedDossierTpl) {
								$scope.form.$submitted = false;
								deferred.resolve();
							});

					} else {

						DossierTemplateResource.saveIfChanged(dossierTpl).
							then(function (savedDossierTpl) {
								return DossierTemplateDefinitionResource.createNewRevision(dossierTplDef);
							}).
							then(function (newDossierTplDef) {
								if (newDossierTplDef.id !== dossierTplDef.id) {
									// No need to keep the old one around if a new revision is created
									DossierTemplateDefinitionResource.eject(dossierTplDef);
									$scope.dossierTemplateDef = newDossierTplDef;
								}

                            	toastMessages.add(toastMessages.T_INFO, '', 'info.dossierTemplateSaved', 4000, newDossierTplDef);

								$scope.form.$submitted = false;
								deferred.resolve();
							}).
							catch(function (err) {
								$scope.form.$submitted = false;
								deferred.reject(err);
							});
					}

					return deferred.promise;


					//		// Valid form with changes
					//		// Update dossierTpl AND dossierTplDef in a single call
					//		delete($scope.dossierTemplateDef.items);
					//		var data = {
					//			dossierTpl: $scope.model,
					//			dossierTplDef: $scope.dossierTemplateDef
					//		};
					//
					//		var address = '/dossiertpl/save';
					//		$http.post(config.getAPIUrl(address), {data: data})
					//			.success(function (data, status, headers, config) {
					//				if (data.errors) {
					//					$log.error(address + ": " + JSON.stringify(data.errors));
					//					deferred.reject();
					//					return;
					//				}
					//
					//				angular.merge($scope.origModel, data.dossierTpl);
					//				angular.merge($scope.model, data.dossierTpl);
					//
					//				// Upon save, the template content itself is not returned as we just saved id.
					//				$scope.templateContent = $scope.dossierTemplateDef.template;
					//
					//				$scope.dossierTemplateDef = data.dossierTplDef;
					//				$scope.dossierTemplateDef.template = $scope.templateContent;
					//
					//				$scope.form.$submitted = false;
					//				toastMessages.add(toastMessages.T_SUCCESS, 'Page.DossierTemplates.Form.ToastMessages.Saved', 'Page.DossierTemplates.Form.ToastMessages.SavedContent', 3000);
					//				deferred.resolve();
					//			})
					//			.error(function (data, status, headers, config) {
					//				toastMessages.add(toastMessages.T_DANGER, 'Page.DossierTemplates.Form.ToastMessages.UpdateFailedTitle', 'Page.DossierTemplates.Form.ToastMessages.UpdateFailedContent', 8000);
					//				deferred.reject();
					//			});
					//	}
					//return deferred.promise;
				};


				$scope.cancel = function () {
					$scope.navigateToList();
				};

				$scope.navigateToList = function () {
					$state.go('locale.app.dossiertemplate', {Locale: config.getUrlPrefix()});
				};


				if ($stateParams.uuID === 0) {
					// TODO
					$scope.dossierTemplate = DossierTemplateResource.createInstance();
					$scope.dossierTemplateDef = DossierTemplateDefinitionResource.createInstance();

				} else {

					DossierTemplateResource.load($stateParams.uuID, 0).then(function(data) {
						$scope.dossierTemplate = data.dossierTpl;
						$scope.dossierTemplateDef = data.dossierTplDef;

						DossierTemplateDefinitionResource.interpretDefinition(data.dossierTplDef, 0);

						$scope.isSomething = true;

					}, function(err) {
						$scope.handleFailure(err);
						$scope.navigateToList();
					});


					//DossierTemplateResource.find($stateParams.uuID).then(function (dossierTemplate) {
					//
					//	// Always fetch the latest
					//	DossierTemplateDefinitionResource.load(dossierTemplate.id, 0, true).then(function (dossierTemplateDef) {
					//		DossierTemplateDefinitionResource.interpretDefinition(dossierTemplateDef);
					//
					//		$scope.dossierTemplate = $scope.model;
					//		$scope.dossierTemplateDef = dossierTemplateDef;
					//
					//	}, function () {
					//		toastMessages.add(toastMessages.T_DANGER, 'Page.DossierTemplate.Form.ToastMessages.NotFoundTitle', 'Page.DossierTemplat.Form.ToastMessages.NotFoundContent', 8000);
					//		$scope.navigateToList();
					//	});
					//}, function (msg) {
					//	$scope.navigateToList();
					//	alert('DossierTemplate not found: ' + msg);
					//});
				}

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
					$scope.failureHandler = null;
				});
			}
		]
	);
});
