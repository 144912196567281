/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/company/form/controller',[
	"angular",
	"lodash",
	"angularFileUpload",
	"model/company-resource", //company resource
	"codemirror",
	"codemirror/addon/edit/matchbrackets",
	"codemirror/addon/edit/closetag",
	"codemirror/addon/display/fullscreen",
	"codemirror/addon/dialog/dialog",
	"codemirror/addon/search/searchcursor",
	"codemirror/addon/search/search",
	"codemirror/addon/lint/lint",
	"codemirror/mode/htmlmixed/htmlmixed",
	"utils/file-upload-directive",
	"uiCodemirror"
], function (ng, _) {
	"use strict";

	return ng.module("NabesFront.controllers.CompanyFormController", [
		"Twensoc.Config",
		"NabesFront.collection.ToastMessagesCollection",
		"CompanyResource",
		"NabesFront.model.UserModel",
		"NabesFront.utils.Authentication",
		"ui.codemirror",
		"NabesFront.components.FileUploadDirective",
		"ngFileUpload",
		"ipCookie"
	]).controller("CompanyFormController", [
		"$log",
		"$q",
		"$timeout",
		"$state",
		"$stateParams",
		"$scope",
		"$rootScope",
		"config",
		"CompanyResource",
		"UserModel",
		"toastMessages",
		"Authentication",
		"Upload",
		"ipCookie",
		function ($log,
			$q,
			$timeout,
			$state,
			$stateParams,
			$scope,
			$rootScope,
			config,
			CompanyResource,
			UserModel,
			toastMessages,
			Authentication,
			Upload,
			ipCookie
		) {
			$scope.Config = config;
			$rootScope.Title = "Company form pagina";
			$rootScope.BodyClass = "company";

			$scope.model = {};
			$scope.origModel = {};
			$scope.isAdmin = UserModel.hasRole("ADMIN");
			$scope.companyId = $stateParams.companyId;
			$scope.MaxSubDomainLength = 19;
			$scope.editorOptions = {
				lineWrapping: true,
				lineNumbers: true,
				matchBrackets: true,
				autoCloseTags: true,
				mode: "htmlmixed",
				theme: "eclipse",
				height: 200,
				extraKeys: {
					"F11": function (cm) {
						cm.setOption("fullScreen", !cm.getOption("fullScreen"));
					},
					"Esc": function (cm) {
						if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);

					},
					"Ctrl-S": function (cm) {
						$scope.ok($scope.form);
						return false;
					},
					"Cmd-S": function (cm) {
						$scope.ok($scope.form);
						return false;
					}
				}
			};

			$scope.styleEditorOptions = {
				lineWrapping: true,
				lineNumbers: true,
				matchBrackets: true,
				autoCloseTags: true,
				mode: "css",

				extraKeys: {
					"F11": function (cm) {
						cm.setOption("fullScreen", !cm.getOption("fullScreen"));
						$scope.updateErrorLayout();
					},
					"Esc": function (cm) {
						if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
						$scope.updateErrorLayout();
					},
					"F9": function (cm) {
						$scope.checkSyntax($scope.form);
					},
					"Ctrl-S": function (cm) {
						$scope.checkSyntax($scope.form);
						$scope.updateErrorLayout();
						$scope.save($scope.form);
						return false;
					},
					"Cmd-S": function (cm) {
						$scope.checkSyntax($scope.form);
						$scope.updateErrorLayout();
						$scope.save($scope.form);
						return false;
					}
				}
			};

			/**
			 * Function that checks if this input has a specific error.
			 * If this is the case the error is displayed to the user.
			 * @param {string} fieldName
			 * @param {string} errorName
			 * @returns {boolean}
			 */
			$scope.hasError = function (fieldName, errorName) {
				var value = _.getByString($scope.companyForm[fieldName].$error, errorName);
				return (($scope.companyForm.$submitted === true || $scope.companyForm[fieldName].$dirty === true) && value);
			};

			$scope.hasChanges = function () {
				return !ng.equals($scope.model, $scope.origModel);
			};

			$scope.isValid = function (form) {
				return !form.$invalid;
			};

			$scope.ok = function (form) {
				if (form.$invalid) {
					return;
				}
				if (!$scope.hasChanges()) {
					$scope.navigateToList();
					return;
				}

				if ($scope.model.id == null) {

					var el = document.getElementById("file");

					CompanyResource.create($scope.model).then(function (data) {
						var deferred = $q.defer();
						deferred.resolve(data.id);
						CompanyResource.inject(data);
						$scope.model.id = data.id;
						return deferred;
					}).then(function (id) {
						if (el.files == null || el.files.length === 0) {
							var deferred = $q.defer();
							deferred.resolve();
							return deferred;
						}
						return $scope.onEmailLogoSelected(el.files);
					}).then(function (data) {
						$scope.navigateToList();
					}).catch($scope.failureHandler);

				} else {
					CompanyResource.update($scope.model.id, $scope.model).then(function (data) {
						ng.merge($scope.origModel, data);
						$scope.navigateToList();
					}, $scope.failureHandler);
				}
			};

			$scope.handleFailure = function (err) {
				if (err == null) err = "unknown-error";
				if (err.message) err = err.message.toLowerCase().replace(" ", "-");
				toastMessages.add(toastMessages.T_DANGER, "", "failure." + err, 4000);
			};
			$scope.failureHandler = $scope.handleFailure.bind($scope);

			$scope.reset = function () {
				$scope.model = ng.copy($scope.origModel);

				// We want to work with 'company' in the template
				$scope.company = $scope.model;
			};

			$scope.cancel = function () {
				$scope.navigateToList();
			};

			$scope.companyAccounts = function () {
				$state.go(
					"locale.app.company.edit.account",
					{
						Locale: config.getUrlPrefix(),
						companyId: $scope.companyId
					}
				);
			};

			$scope.navigateToList = function () {
				if (Authentication.hasRole("ADMIN")) {
					$state.go("locale.app.company", {Locale: config.getUrlPrefix()});
				} else {
					$state.go("locale.app.dossier", {Locale: config.getUrlPrefix()});
				}
			};

			$scope.showAccountsBtn = function () {
				return UserModel.hasRole("ADMIN") && _.isNumber($scope.model.id);
			};

			if ($scope.companyId === 0) {
				$scope.origModel = CompanyResource.createInstance();
				$scope.header = "Nieuw bedrijf";
				$scope.reset();
			} else {
				CompanyResource.find($scope.companyId).then(function (company) {
					company.siteSrc = company.siteSrc + ""; // int to string
					$scope.origModel = company;
					$scope.reset();
				}, function (msg) {
					$scope.navigateToList();
					$scope.handleFailure(msg);
				});
			}

			$scope.onEmailLogoSelected = function (file) {
				if ($scope.model.id == null && file != null) {
					// Preview image Quick'n dirty. Uploading is done on Ok
					var reader = new FileReader();
					reader.onload = function () {
						document.getElementById("emailLogo").src = reader.result;
					};
					reader.readAsDataURL(file[0]);
					return;
				}

				var deferred = $q.defer();

				file.upload = Upload.upload({
					url: config.getAPIUrl("/company/uploadEmailLogo/"),
					data: {
						file: file,
						companyId: $scope.model.id,
						token: ipCookie("NB_TOKEN")
					}
				});

				file.upload.then(function (response) {
					if (response.data.emailLogo) {
						$scope.model.emailLogo = response.data.emailLogo;
					}
					toastMessages.add(toastMessages.T_SUCCESS, "", "companyForm.emailLogoUploadSucceeded", 4000);
					deferred.resolve(response);
				}, function (response) {
					if (response.status > 0) {
						$scope.errorMsg = response.status + ": " + response.data;
					}
					toastMessages.add(toastMessages.T_DANGER, "", "companyForm.emailLogoUploadFailed", 4000);
					deferred.reject(response.status);
				});
				return deferred.promise;
			};

			/**
			 * Time for some cleaning up of listeners! this is very important!
			 */
			$scope.$on("$destroy", function () {
				$scope.failureHandler = null;
			});
		}
	]);
});
