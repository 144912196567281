/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7/30/2015
 * Time: 1:04 PM
 * For Project: nabes-front
 * @version : 0.1
 */

define('collection/changes',[
    'angular',
    'lodash',
    'utils/change-appliers/change-applier', //default change applier
    'collection/base' //base collection
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.collection.ChangesCollection', [
            'Twensoc.collection.BaseCollection',
            'Twensoc.Config',
            'NabesFront.utils.ChangeApplier'
        ]
    ).factory('ChangesCollection', [
        '$q',
        '$log',
        '$http',
        'config',
        '$rootScope',
        'BaseCollection',
        'ChangeApplier',
        function($q, $log, $http, config, $rootScope, BaseCollection, ChangeApplier) {
            function ChangesCollection(attrs) {
                ng.extend(this, attrs);
                this.init();
            }

            ChangesCollection.prototype.$super = BaseCollection.prototype;
            ng.extend(ChangesCollection.prototype, BaseCollection.prototype, {
                /**
                 * Mapping to determine which Change applying is selected for the specific model.
                 */
                _mapping: {},
                /**
                 * Initialization function.
                 */
                init: function() {
                    var me = this;
                    //TODO CHANGE this listener does not have to be removed since its in a singleton and only one exists during the application cycle.
                    /*$rootScope.$on('WebSocket.onMessage.applyChanges', function(event, json) {
                        me.applyMultiple(json.items);
                    });*/
                },
                /**
                 * Create multiple new Change models and apply their changes to the existing models in their respective collection.
                 * This is all handled from within the Change model.
                 * @param {[]} changesList
                 */
                applyMultiple: function(changesList) {
                    var me = this;
                    if(_.isEmpty(changesList)) return;
                    _.each(changesList, function(change) {
                        var type = change.type;
                        if(_.isEmpty(type)) return;
                        var changeApplier = me._getChangeApplier(type);
                        changeApplier.processChange(change);
                    });
                },
                /**
                 * Function that retrieves the change applier based on the type.
                 * If their is a custom change applier specified in the me._mapping object that one is retrieved
                 * Else the default ChangeApplier is returned.
                 * @param {string} type
                 * @returns {{}}
                 * @private
                 */
                _getChangeApplier: function(type) {
                    var me = this;
                    if(_.isEmpty(type)) return null;
                    if(!_.isObject(me._mapping[type])) return ChangeApplier;
                    return me._mapping[type];
                }
            });
            return new ChangesCollection();
        }
    ]);
});
