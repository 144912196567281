/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/scratchpad/controller',[
    'angular',
    'lodash',
    'collection/toast-messages', //toast messages collection
    'model/dossier-resource' //dossier resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.ScratchPadFormController', [
            'Twensoc.Config',
            'ui.bootstrap',
            'DossierResource',
            'NabesFront.collection.ToastMessagesCollection',
            'ipCookie'

        ]
    ).controller(
        'ScratchPadFormController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            '$q',
            '$timeout',
            '$http',
            'config',
            'DossierResource',
            'toastMessages',
            function ($state, $stateParams, scope, rootScope, $q, $timeout, $http, config, DossierResource, toastMessages) {
                scope.Config = config;
                rootScope.Title = "Kladblok";
                rootScope.BodyClass = "dossier";

                scope.dossier = {};

                /**
                 * Function that checks if this input has a specific error.
                 * If this is the case the error is displayed to the user.
                 * @param {string} fieldName
                 * @param {string} errorName
                 * @returns {boolean}
                 */
                scope.hasError = function(fieldName, errorName) {
                    var value = _.getByString(scope.form[fieldName].$error, errorName);
					return ((scope.form.$submitted === true || scope.form[fieldName].$dirty === true) && value);
				};

                scope.handleFailure = function(err) {
                    if(err == null) err = 'unknown-error';
                    if(err.message) err = err.message.toLowerCase().replace(" ", "-");
                    toastMessages.add(toastMessages.T_DANGER, '', 'failure.'+err, 4000);
                };
                scope.failureHandler = scope.handleFailure.bind(scope);



                scope.cancel = function(form) {
                    history.back();
	            };

                scope.submit = function(form) {

                    var deferred = $q.defer(),
                        changes = { //this.getChangedFields();
                            scratchPad: form.scratchPad.$viewValue,
                            hasChanges: true
                        };

                    if(form.scratchPad.$dirty === true) {
                        scope.dossier.saveChanges(changes).then(function (result) {
                            deferred.resolve(result);
                            form.$setPristine();
                            form.$setUntouched();
                            toastMessages.add(toastMessages.T_SUCCESS, '', 'info.dossierScratchPadSaved', 5000);
                        }, function (err) {
                            scope.handleFailure(err);
                            deferred.reject(err);
                        });
                    } else {
                        deferred.resolve(scope.dossier);
                    }

                    return deferred.promise;
                };


                /**
                 * Grab the dossier out of the dossier resource collection.
                 */
                DossierResource.find($stateParams.uuID).then(function (dossier) {
                    scope.dossier = dossier;
                    DossierResource.setActive(scope.dossier, 'document');

                }, function () {
                    console.error('ohoh');
                    $state.go('locale.app.dossier');
                });

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                scope.$on('$destroy', function() {
                    scope.dossier = null;
                    scope.failureHandler = null;
                });


            }
        ]
    );
});
