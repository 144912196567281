/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier-template/list/controller',[
    'angular',
    'lodash',
    'angularUIBootstrap',
    'model/dossier-template-resource' //dossier template resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.DossierTemplateOverviewController', [
            'Twensoc.Config',
            'DossierTemplateResource',
            'ui.bootstrap.pagination',
            'DossierResource'
        ]
    ).controller(
        'DossierTemplateOverviewController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            '$log',
            'DossierTemplateResource',
            'DossierResource',
            function ($state, $stateParams, $scope, $rootScope, config, $log, DossierTemplateResource, DossierResource) {
                $scope.Config = config;
                $rootScope.Title = "DossierTemplate overview pagina";
                $rootScope.BodyClass = "dossierTemplate";

                $scope.templates = [];

                $scope.paging = {
                    filter: $state.params.filter,
                    pageNo: $state.params.pageNo,
                    totalItems: 0,
                    pageSize: 2
                };

                DossierResource.unsetActive();

                /**
                 * Changes the page based on the search, pageNo etc.
                 * This changes the state so its reflected within the URL
                 */
                $scope.changePage = function() {
                    //reset page number if filter is modified as we do not know how many pages the query will result in
                    if($state.params.filter !== $scope.paging.filter) $scope.paging.pageNo = 1;
                    $state.go($state.current, {pageNo: $scope.paging.pageNo, filter: $scope.paging.filter});
                };

                DossierTemplateResource.findAll($scope.paging, {bypassCache: true}).then(function(templates) {
                    $scope.templates = templates;
                });

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
