/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/eventlist/controller',[
    'angular',
    'lodash',
    'model/event-resource', //event resource
    'collection/toast-messages' //toast messages collection
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.DossierEventOverviewController', [
            'Twensoc.Config',
            'DossierResource',
            'EventResource',
            'NabesFront.collection.ToastMessagesCollection'
        ]
    ).controller(
        'DossierEventOverviewController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            '$log',
            '$filter',
            '$timeout',
            'DossierResource',
            'EventResource',
            'toastMessages',
            function ($state, $stateParams, $scope, $rootScope, config, $log, $timeout, $filter, DossierResource, EventResource,toastMessages) {
                $scope.Config = config;
                $scope.events = [];
                $rootScope.BodyClass = "messages";
                $scope.messages = [];
                $scope.selection = {};
                $scope.panes = [{},{},{}];

                $scope.paging = {
                    filter: $state.params.filter,
                    pageNo: $state.params.pageNo,
                    totalItems: MAX_INT,
                    pageSize: 10,
                    eventState: $state.params.eventState || 0,
                    dossierId: $stateParams.uuID
                };

                /**
                 * Changes the page based on the search, pageNo etc.
                 * This changes the state so its reflected within the URL
                 */
                $scope.changePage = function() {
                    //reset page number if filter is modified as we do not know how many pages the query will result in
                    if($state.params.filter !== $scope.paging.filter) $scope.paging.pageNo = 1;
                    $state.go($state.current, {pageNo: $scope.paging.pageNo, filter: $scope.paging.filter, eventState: $scope.paging.eventState});
                };


                $scope.loadMessages = function() {
                    EventResource.findAll($scope.paging, {bypassCache: true}).then(function(messages) {
                        $scope.panes.forEach(function(pane) {
                           pane.selected = false;
                        });
                        $scope.panes[$scope.paging.eventState].selected = true;

                        $scope.messages.splice(0);
                        $scope.messages = $scope.messages.concat(messages);
                    });
                };

                $scope.hasSelectedItems = function() {
                    return (Object.keys(this.selection).length > 0);
                };

                /**
                 * Grab the dossier out of the dossier resource collection.
                 */
                DossierResource.find($stateParams.uuID).then(function (dossier) {
                    $scope.dossier = dossier;
                    DossierResource.setActive($scope.dossier, 'events');

                    $scope.loadMessages();

                }, function () {
                    $scope.dossier = null;
                    $scope.events = null;
                });

                $scope.check = function(msgId) {
                    if($scope.selection[msgId]) {
                        delete $scope.selection[msgId];
                    } else {
                        $scope.selection[msgId] = msgId;
                    }
                };

                $scope.setSelectionState = function(targetState) {
                    var selection = $scope.selection,
                        ids = [];

                    for(var p in selection) {
                        if(selection.hasOwnProperty(p)) {
                            ids.push(p);
                        }
                    }

                    EventResource.toState($scope.dossier.id, ids, targetState).then(function() {
                        $scope.selection = {};
                        var t = ['Inbox','Archive','Trash'][targetState];
                        toastMessages.add(toastMessages.T_SUCCESS , '', 'info.messagesMovedTo'+t, 5000, {count: ids.length});
                        $scope.loadMessages();
                    }, function() {
                        console.error("Ohoh");
                    });
                };

                $scope.tabChangeWatcher = $scope.$watch(function() {
                    if($scope.currentTabIndex !== undefined) {
                        return $scope.currentTabIndex();
                    }
                    return null;
                }, function(newTab, oldTab) {
                    if(oldTab == null || newTab === oldTab) return;
                    $scope.selection = {};
                    $state.go($state.current, {pageNo: 1, filter: $scope.paging.filter, eventState: newTab});
                    }
                );

                $scope.formattedDate = function(date) {
                    return $filter('date')(Date.parse(date), 'dd-MM-yyyy HH:mm', 0);
                };

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                    $scope.dossier = null;
                    $scope.events = null;
                    $scope.tabChangeWatcher();
                });
            }
        ]
    );
});
