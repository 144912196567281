/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/dossier-account-resource',[
    'angular',
    'lodash',
    'js-data'
], function (ng, _) {
    'use strict';

    return ng.module(
        'DossierAccountResource', [
            'js-data'
        ]
    ).factory('DossierAccountResource', [
        '$rootScope',
        '$q',
        '$log',
        'DS',
        function($rootScope, $q, $log, DS) {

            var DossierAccountResource = DS.defineResource({
                name:"DossierAccount",
                endpoint: '/dossieraccount/',
                defaultValues: {
                    id: null,
                    dossierId: null,
                    accountId: null,
                    firstName: '',
                    infix: '',
                    surname: ''
                },
                methods: {
                    fullAccountName: function () {
                        return [this.firstName, this.infix, this.surname].join(" ");
                    },
                    fullDossierName: function() {
                        return [this.dossier_firstName, this.dossier_infix, this.dossier_surname].join(" ");
                    }
                }
            });
            $log.info('DossierAccountResource defined');

            return DossierAccountResource;
        }
    ]);
});
