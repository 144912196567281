/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 8/28/2015
 * Time: 9:12 AM
 * For Project: nabes-front
 * @version : 0.1
 */

define('collection/toast-messages',[
    'angular',
    'lodash'
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.collection.ToastMessagesCollection', [
            'Twensoc.Config'
        ]
    ).factory('toastMessages', [
        '$q',
        '$timeout',
        '$translate',
        'config',
        '$rootScope',
        function($q, $timeout, $translate, config, $rootScope) {
            function ToastMessagesCollection(attrs) {
                ng.extend(this, attrs);
            }

            ng.extend(ToastMessagesCollection.prototype, {
                models: [],
                T_SUCCESS: 'alert-success',
                T_INFO: 'alert-info',
                T_WARNING: 'alert-warning',
                T_DANGER: 'alert-danger',
                /**
                 * Function that adds a new toast message, this causes the message to be instantly displayed to the user.
                 * If a timeout is specified the model is automatically removed after the specified duration.
                 * @param {string} type the contextual css class http://getbootstrap.com/components/#alerts
                 * @param {string} [title] translation ID
                 * @param {string} [content] translation ID
                 * @param {number} [timeout] optional parameter to specify the timeout for how long the message should be visible in milliseconds.
                 * @param {{}} [translationData] optional parameter to specify any additional data that has to replace variables in translation strings.
                 * @param {number} [defer] Wait x milliseconds before showing the toast
                 */
                add: function(type, title, content, timeout, translationData, defer) {
                    var me = this;


                    if(defer == null || defer === 0) {
                       defer = 1;
                    }

                    $timeout(function() {
                        var index = me._createModel(type, title, content, translationData);
                        if(timeout && timeout > 0) {
                            $timeout(function () {
                                me.remove(index);
                            }, timeout);
                        }
                    }, defer);
                },
                /**
                 * Function that removes the item from the this.models array
                 * Hiding it permanently.
                 * @param {number} index
                 */
                remove: function(index) {
                    var me = this;
                    me.models.splice(index, 1);
                },
                /**
                 * Function that creates a new model that is added to the this.models array
                 * It returns the index on which position this was done so the this.addMessage call can add a timeout handler to remove it after a set duration
                 * @param {string} type
                 * @param {string} [title] translation ID
                 * @param {string} [content] translation ID
                 * @param {{}} [translationData]
                 * @returns {number}
                 * @private
                 */
                _createModel: function(type, title, content, translationData) {
                    var me = this;
                    //create model and calculate index of the new element
                    var index = me.models.push({type: type, title: "", content: ""}) - 1;

                    //get translations
                    var idArray = [title];
                    if(content !== undefined) idArray.push(content);
                    me._retrieveTranslation(idArray, translationData).then(function(translations) {
                        _.forEach(idArray, function(value, key) {
                            if(key === 0 && translations[value] !== undefined) me.models[index].title = translations[value];  //first is always the title
                            if(key === 1 && translations[value] !== undefined) me.models[index].content = translations[value];  //second is always the content
                        });
                    });
                    return index;
                },
                /**
                 * Function that translates the list of specified translation IDs
                 * @param {[]} idArray translation IDs
                 * @param {{}} [translationData]
                 * @returns {deferred.promise|{then, always}}
                 * @private
                 */
                _retrieveTranslation: function(idArray, translationData) {
                    return $translate(idArray, translationData);
                }
            });
            return new ToastMessagesCollection();
        }
    ]);
});
