/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 19-3-2015
 * Time: 10:08
 * For Project: fairplay
 * @version : 0.1
 */

define('controllers/contact/controller',[
    'angular',
    'lodash',
    'components/header/directive', //header directive
    'components/footer/directive', //footer directive
    'utils/authentication', //authentication service
    'config',
    'collection/toast-messages' //toast messages collection
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.ContactFormController', [
            'Twensoc.Config',
            'Twensoc.components.tsHeader',
            'Twensoc.components.tsFooter',
            'NabesFront.utils.Authentication',
            'NabesFront.collection.ToastMessagesCollection'
        ]
    ).controller(
        'ContactFormController', [
            '$state',
            '$stateParams',
            '$timeout',
            '$scope',
            '$rootScope',
            'config',
            'Authentication',
            '$http',
            'toastMessages',
            function ($state, $stateParams, $timeout, $scope, $rootScope, config, Authentication, $http, toastMessages) {
                $scope.Config = config;
                $rootScope.Title = "Contact";
                $rootScope.BodyClass = "contact";

                $scope.msgSend = false;

                $scope.submit = function (formData) {
                    var me = this, data = {}, form = me.ContactForm;

                    if($state.params.uuID!==undefined)
                    {
                        formData.dossierId = $state.params.uuID;
                    }

					if($scope.User.id!==undefined)
					{
					    formData.userId = $scope.User.id;
					}


                    //check if the form is valid or is already being submitted if so return and do not do anything
                    if(form.$valid === false || form.submitting === true) return;

                    //start submitting the form showing the loader to the user.
                    form.submitting = true;

                    if(form.$valid) {
                        $http.post(config.getAPIUrl('contact'), formData).
                            then(function(response) {
                            form.submitting = false;
                                if(response.status == 200) {
                                    me.msgSend = true;
                                    toastMessages.add(toastMessages.T_INFO, '', 'contactForm.successMsg', 8000);
                                    $state.go('locale.app.dossier', {Locale: config.getUrlPrefix()});
                                } else {
                                    me.msgSend = true;
                                    $state.go('locale.app.dossier', {Locale: config.getUrlPrefix()});
                                }
                            }, function(response) {
                               alert('failed. do something');
                            });
                    }
                };

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
