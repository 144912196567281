/**
 * Created by Nick Schipper on 14-Feb-2015.
 */

define('collection/base',[
    'angular',
    'lodash'
], function (ng, _) {
    'use strict';

    return ng.module(
        'Twensoc.collection.BaseCollection', [
        ]
    ).factory('BaseCollection', [
        '$q',
        '$rootScope',
        function($q, $rootScope) {
            function BaseCollection(attrs) {
                ng.extend(this, attrs);
            }

            ng.extend(BaseCollection.prototype, {
                _models: [],
                _loaded: false,

                _restUrl: '',
                /**
                 * Add a model to the _models collection. Making sure their is not another one already in the collection with the same ID
                 * If it exist it returns the existing model else it returns the new model which is added to the _models array.
                 * @param {{}} obj
                 */
                add: function(obj) {
                    var me = this;
                    if(_.isEmpty(obj)) return null;

                    me._models.push(obj);
                    return obj;
                },
                /**
                 * Function that removes all the _models on the collection
                 */
                clear: function() {
                    var me = this;
                    me._models = [];
                },
                /**
                 * Function that checks if the model specified by ID is already contained in the collection.
                 * And unlike the non private getByID function this does not wait for the object to be loaded.
                 * Seeing as its normally only used by the function that adds new models meaning its still loading at that point.
                 * @param {string} id
                 * @returns {{}}
                 * @private
                 */
                _getByID: function(id) {
                    return _.findWhere(this._models, {id: id});
                },
                /**
                 * Retrieve a single model from the model collection by the ID specified this function returns a promise
                 * Useful if you are not sure if the data has loaded already.
                 * @param {string} id
                 * @returns {deferred.promise|{then, always}}
                 */
                getByID: function(id) {
                    var me = this;
                    var deferred = $q.defer();

                    if(me._loaded === true) {
                        deferred.resolve(_.findWhere(me._models, {id: id}));
                        return deferred.promise;
                    }

                    var watch = $rootScope.$watch(function() {return me._loaded;}, function(newValue, oldValue) {
                        if(newValue !== true) return;
                        deferred.resolve(_.findWhere(me._models, {id: id}));
                        watch();
                    });
                    return deferred.promise;
                },
                /**
                 * Function that returns a list of _models that were added to this class.
                 * @returns {*}
                 */
                getAll: function() {
                    var me = this;
                    return me._models;
                }

            });
            return BaseCollection;
        }
    ]);
});
