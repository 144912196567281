/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/notificationTpl/form/controller',[
	'angular',
	'lodash',
	'codemirror',
	'codemirror/addon/edit/matchbrackets',
	'codemirror/addon/edit/closetag',
	'codemirror/addon/display/fullscreen',
	'codemirror/addon/dialog/dialog',
	'codemirror/addon/search/searchcursor',
	'codemirror/addon/search/search',
	'codemirror/addon/lint/lint',
	'codemirror/mode/htmlmixed/htmlmixed',
	'uiCodemirror',
	'model/notificationTpl-resource' //notificationTpl resource
], function (ng, _, codeMirror) {
	'use strict';

	window.CodeMirror = codeMirror;

	return ng.module(
		'NabesFront.controllers.NotificationTplFormController', [
			'Twensoc.Config',
			'NabesFront.collection.ToastMessagesCollection',
			'NotificationTplResource',
			'NabesFront.model.UserModel',
			'NabesFront.utils.Authentication',
			'ui.codemirror'
		]
	).controller(
		'NotificationTplFormController', [
			'$log',
			'$state',
			'$stateParams',
			'$scope',
			'$rootScope',
			'config',
			'NotificationTplResource',
			'UserModel',
			'toastMessages',
			'Authentication',
			function ($log, $state, $stateParams, $scope, $rootScope, config, NotificationTplResource, UserModel, toastMessages, Authentication) {
				$scope.Config = config;
				$rootScope.Title = "notificationTpl form pagina";
				$rootScope.BodyClass = "notificationTpl";

				$scope.model = {};
				$scope.origModel = {};
				$scope.MaxNameLength = 60;

				$scope.editorOptions = {

					// parserfile: "lib/parsefreemarker.js",
					// stylesheet: "lib/freemarkercolors.css",
					// // path: "../../js/",
					// continuousScanning: 500,
					// autoMatchParens: true,
					// markParen: function(node, ok) {
					//     node.style.backgroundColor = ok ? "#CCF" : "#FCC#";
					//     if(!ok) {
					//         node.style.color = "red";
					//     }
					// },
					// unmarkParen: function(node) {
					//     node.style.backgroundColor = "";
					//     node.style.color = "";
					// },
					// indentUnit: 4,

					lineWrapping: true,
					lineNumbers: true,
					matchBrackets: true,
					autoCloseTags: true,
					mode: 'htmlmixed',
					theme: 'eclipse',
					height: 200,
					extraKeys: {
						"F11": function (cm) {
							cm.setOption("fullScreen", !cm.getOption("fullScreen"));
						},
						"Esc": function (cm) {
							if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);

						},
						"Ctrl-S": function (cm) {
							$scope.ok($scope.form);
							return false;
						},
						"Cmd-S": function (cm) {
							$scope.ok($scope.form);
							return false;
						}
					}
				};

				/**
				 * Function that checks if this input has a specific error.
				 * If this is the case the error is displayed to the user.
				 * @param {string} fieldName
				 * @param {string} errorName
				 * @returns {boolean}
				 */
				$scope.hasError = function (fieldName, errorName) {
					var value = _.getByString($scope.form[fieldName].$error, errorName);
					return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
				};

				$scope.updateErrorLayout = function () {
					var cmDiv = angular.element(document.getElementsByClassName('CodeMirror'));

					if (cmDiv.hasClass('CodeMirror-fullscreen')) {
						errDiv.addClass('fullscreen');
					} else {
						errDiv.removeClass('fullscreen');
					}
				};


				$scope.handleFailure = function (err) {
					if (err == null) err = 'unknown-error';
					if (err.message) err = err.message.toLowerCase().replace(/\s/g, "-");
					toastMessages.add(toastMessages.T_DANGER, '', 'failure.' + err, 4000);
				};
				$scope.failureHandler = $scope.handleFailure.bind($scope);


				$scope.hasChanges = function () {
					return !ng.equals($scope.model, $scope.origModel);
				};

				$scope.isValid = function (form) {
					return !form.$invalid;
				};

				$scope.isTest = function () {
					var value = $scope.form.testWithDossier;

					return (undefined === value.$modelValue)|| value.$modelValue ==="" ;
				};

				$scope.ok = function (form) {
					if (form.$invalid) return;
					if (!$scope.hasChanges()) {
						$scope.navigateToList();
						return;
					}

					if ($scope.model.id == null) {
						return NotificationTplResource.create($scope.model).then(function (data) {
							NotificationTplResource.inject(data);
							$scope.navigateToList();
						}, $scope.failureHandler);

					} else {
						NotificationTplResource.update($scope.model.id, $scope.model).then(function (data) {
							ng.merge($scope.origModel, data);
							$scope.navigateToList();
						}, $scope.failureHandler);
					}
				};

				$scope.saveAndTest = function (form) {
					if (form.$invalid) return;
					if (!$scope.hasChanges()) {
						// send test
						return;
					}

					NotificationTplResource.update($scope.model.id, $scope.model).then(function (data) {
						ng.merge($scope.origModel, data);

					}, $scope.failureHandler);

				};


				$scope.reset = function () {
					$scope.model = ng.copy($scope.origModel);

					// model.systemType is an integer. Convert to string to let the select work
					$scope.model.systemType = "" + $scope.model.systemType;


					// We want to work with 'notificationTpl' in the template
					$scope.notificationTpl = $scope.model;
				};

				$scope.cancel = function () {
					$scope.navigateToList();
				};

				$scope.navigateToList = function () {
					$state.go('locale.app.notificationTpl', {Locale: config.getUrlPrefix()});
				};

				if ($stateParams.uuID === 0) {
					$scope.origModel = NotificationTplResource.createInstance();
					$scope.reset();
				} else {
					NotificationTplResource.find($stateParams.uuID).then(function (notificationTpl) {
						$scope.origModel = notificationTpl;
						$scope.reset();
					}, function (msg) {
						$scope.navigateToList();
						$scope.handleFailure(msg);
					});
				}


				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
					$scope.failureHandler = null;
				});
			}
		]
	);
});
