/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/messages/list/controller',[
    'angular',
    'lodash',
    'components/tabs-panel/directive' //tabs panel directive
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.MessagesOverviewController', [
            'Twensoc.Config',
            'Nabes.components.tabsPanel'
        ]
    ).controller(
        'MessagesOverviewController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            '$log',
            function ($state, $stateParams, $scope, $rootScope, config, $log) {
                $scope.Config = config;
                $rootScope.Title = "Berichten";
                $rootScope.BodyClass = "messages";

                $scope.messages = [

                ];

                $scope.messagesA = [

                ];

                $scope.messagesT = [

                ];

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
