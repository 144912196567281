define('components/dossier/text/directive',[
    'angular',
    'utils/authentication',
    'components/dossier/base'
], function (ng) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.Text', [
            'Twensoc.Config',
            'NabesFront.utils.Authentication',
            'NabesFront.components.dossier.BaseComponent'
        ]
    ).directive('nbText', [
        'config',
        '$sce',
        '$parse',
        'Authentication',
        'TerminationResource',
        function(config, $sce, $parse, Authentication, TerminationResource) {
            return {
                restrict: "E",
                scope: {
                    dossier: '=data',
                    Name: '@name',
                    NGModelValue: '@value',
                    Content: '@content',
                    Class: '@class',
                    PlaceHolder: '@placeholder',
                    Required: '=required',
                    Explanation: '@explanation',
                    ShowIf: '=showIf',
                    Title: '@title',
                    Record: '@record',
                    RecordId: '=recordId',
                    RecordProperty: '@recordProperty',
                    Sanitize: '@sanitize'
                },
                require: '^form',
                templateUrl: "app/components/dossier/text/text.html",
                link: function(scope, elem, attr, formCtrl) {
                    scope.Config = config;
                    scope.showExplanation = false;
                    scope.showDebug = false;
                    scope.showIfText = attr.showIf;
                    scope.variabeleName = attr.value;
                    scope.showDebugToggle = false;
                    scope.record = {
                        type: attr.record,
                        id: attr.recordId,
                        property: attr.recordProperty,
                        lastId: -1
                    };
                    scope.RecordValue = '';

                    /**
                     * Listen to required changes, specifically for the termination service when
                     * a ref is required depends on the company service to terminate.
                     * @type {any}
                     */
                    var  recordIdWatcher = scope.$watch("RecordId", function(value) {
                        if(value == null) return;
                        scope.RecordId = parseInt(value);
                        scope.getRecordValue();
                    });

                    scope.getRecordValue = function() {
                        if(scope.RecordId == null) return scope.RecordValue;
                        scope.record.lastId = scope.RecordId;
                        TerminationResource.getInformation(scope.RecordId).then(function (termination) {
                            scope.RecordValue = termination[scope.record.property];
                        }, function (err) {
                            scope.RecordValue = 'Fout bij het laden';
                        });

                        return scope.RecordValue;
                    };

                    if(scope.record.id != null) {
                        scope.autocompleteListener = scope.$on('autoComplete.selected', function(src, data) {
                            console.log(data);
                        });
                    }

                    scope.nonSanitizedText = function() {
                        return $sce.trustAsHtml(scope.Content);
                    };

                    /**
                     * Listen to showDebug event, send from dossier form controller to show or hide all debug toggles
                     * @param src Source scope that sends the event
                     * @param v Boolean
                     */
                    scope.showDebugToggleListener = scope.$on('showDebugToggle', function(src, v) {
                        scope.showDebugToggle = v;
                    });

                    /**
                     * Show/hide an optional explanation
                     */
                    scope.toggleExplanation = function() {
                        scope.showExplanation = !scope.showExplanation;
                    };

                    /**
                     * Show/hide the debug information for this input.
                     */
                    scope.toggleDebug = function() {
                        scope.showDebug = !scope.showDebug;
                    };

                    /**
                     * Time for some cleaning up of listeners! this is very important!
                     */
                    scope.$on('$destroy', function() {
                        scope.showDebugToggleListener();
                        if(scope.autocompleteListener != null) scope.autocompleteListener();
                        recordIdWatcher();
                    });
                }
            };
        }
    ]);
});
