/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/dossier-template-resource',[
	'angular',
	'lodash',
	'js-data',
	'model/template-item', //template item model
	'model/base' //base model
], function (ng, _) {
	'use strict';

	return ng.module(
		'DossierTemplateResource', [
			'js-data',
			'DossierTemplateDefinitionResource',
			'NabesFront.model.TemplateItem',
			'Twensoc.Config'
		]
	).factory('DossierTemplateResource', [
			'$rootScope',
			'$q',
			'$log',
			'DS',
			'DossierTemplateDefinitionResource',
			'TemplateItem',
			'$http',
			'config',

			function ($rootScope, $q, $log, DS, DossierTemplateDefinitionResource, TemplateItem, $http, config) {

				var DossierTemplateResource = DS.defineResource({
					name: "DossierTemplate",
					endpoint: '/dossiertpl/',
					//computed: {
					//	items: ['tplDefinition',
					//		function(tplDefinition) {
					//			return [];
					//		}
					//	]
					//},
					defaultValues: {
						id: null,
						name: null,
						//date: new Date().getTime(),
						country: "Netherlands"
						//,
						//items: [],
						//tplDefinition: null
					},
					/**
					 * Loads a given dossierTpl and the (latest) dossierTplDef
					 *
					 * @param dossierTplId
					 * @param latest
					 * @return {deferred.promise|{then, always}}
					 */
					load: function (dossierTplId, dossierTplDefId) {

						var deferred = $q.defer(),
							getDossierTpl = function (dossierTpl) {
								var deferred = $q.defer();

								DossierTemplateResource.find(dossierTplId).then(function (dossierTpl) {
									deferred.resolve({
										dossierTpl: dossierTpl
									});
								}, function (err) {
									deferred.reject(err);
								});
								return deferred.promise;
							},
							getDossierTplDef = function (data) {
								var deferred = $q.defer();
								DossierTemplateDefinitionResource.load(data.dossierTpl.id, dossierTplDefId).then(function (dossierTplDef) {

									// TODO: Update/save dossier when new dossierTplDef is attached
									DossierTemplateDefinitionResource.interpretDefinition(dossierTplDef);

									data.dossierTplDef = dossierTplDef;
									deferred.resolve(data);
								}, function (err) {
									deferred.reject(err);
								});
								return deferred.promise;
							};

						getDossierTpl(dossierTplId).
							then(getDossierTplDef).
							then(function (data) {
								deferred.resolve(data);
							}).
							catch(function (err) {
								deferred.reject(err);
							});

						return deferred.promise;
					},
					/**
					 * Determines whether a dossierTpl has changes
					 *
					 * @param dossierTpl
					 * @return {boolean} True if the dossierTpl has changed fields
					 */
					hasChanges: function(dossierTpl) {
						var resource = DossierTemplateResource,
							changes = resource.changes(dossierTpl.id);

						for(var p in changes) {
							if(changes.hasOwnProperty(p)) {
								return true;
							}
						}
						return false;
					},
					/**
					 * Saves a dossierTpl if any field has changed.
					 *
					 * @param dossierTpl
					 * @return {*} Promise
					 */
					saveIfChanged: function(dossierTpl) {

						if(!dossierTpl.id) {
							return DossierTemplateResource.create(dossierTpl);
						}

						if(this.hasChanges(dossierTpl)) {
							return DossierTemplateResource.update(dossierTpl.id, dossierTpl);
						}
						var deferred = $q.defer();
						deferred.resolve(dossierTpl);
						return deferred.promise;
					},

					methods: {
						/**
						 * Function that returns the value for the select box or radio inputs etc.
						 * @returns {number|null}
						 */
						getValue: function () {
							return this.id;
						},
						/**
						 * Function that returns the label that is shown to the end user as an option in their
						 * select box or in the radio input.
						 * @returns {string}
						 */
						getLabel: function () {
							var string = "";
							if (this.data !== undefined && this.data instanceof Date) string += "[" + this.date.toLocaleString() + "]";
							return string + this.country;
						},

						/**
						 * Function that updates the current model. Saving it to the backend.
						 * New models should use the create method instead !
						 * @returns {deferred.promise|{then, always}}
						 */
						save: function() {
							var me = this;
							var deferred = $q.defer();

							//save only updates existing models.
							if(me.id <= 0) {
								deferred.reject('does-not-exist');
								return deferred.promise;
							}

							// Dont' save items property
							var items =  me.items;
							me.items = null;

							DossierTemplateResource.update(me.id, me).then(function (dossierTemplate) {
								// Upon a successful save, the data property is returned as serialized json (a string).
								// Deserisalize the data property
								// dossier.data = JSON.parse(dossier.data);
								me.items = items;
								deferred.resolve(dossierTemplate);
							}, function (dossierTemplate) {
								me.items = items;
								deferred.reject(dossierTemplate);
							});

							return deferred.promise;
						},
						/**
						 * Function that creates the current model in the backend.
						 * This function is not meant to be used for models that already exist.
						 * @returns {deferred.promise|{then, always}}
						 */
						create: function() {
							var me = this;
							var deferred = $q.defer();

							//create only creates new models and not update existing.
							if(me.id > 0) {
								deferred.reject('already-exists');
								return deferred.promise;
							}

							// The angular app needs an id (=0 for new dossiers), but the js-data store
							//
							me.id = null;

							// Dont' save items property
							var items =  me.items;
							me.items = null;

							DossierTemplateResource.create(me).then(function (dossierTemplate) {
								// Upon a successful create, the data property is returned as serialized json (a string).
								// Deserisalize the data property
								// dossier.data = JSON.parse(dossier.data);

								dossierTemplate.items = items;
								DossierResource.inject(dossierTemplate);
								deferred.resolve(dossierTemplate);
							}, function (errors) {
								dossierTemplate.items = items;
								deferred.reject(errors);
							});

							return deferred.promise;
						}
					}
				});

				$log.info('DossierTemplateResource defined');
				return DossierTemplateResource;
			}
		]);
});
