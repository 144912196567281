/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/company-info/controller',[
    'angular',
    'lodash',
    'collection/toast-messages', //toast messages collection
    'model/dossier-resource' //dossier resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.DossierCompanyInfoController', [
            'Twensoc.Config',
            'ui.bootstrap',
            'DossierResource',
            'NabesFront.collection.ToastMessagesCollection',
		    'NabesFront.model.UserModel'
        ]
    ).controller(
        'DossierCompanyInfoController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            'DossierResource',
            'CompanyResource',
            'toastMessages',
		    'UserModel',
            '$parse',
            function ($state, $stateParams, $scope, $rootScope, config, DossierResource, CompanyResource, toastMessages, UserModel, $parse) {
                $scope.Config = config;
                $rootScope.BodyClass = "dossier";

                $scope.dossier = {};
                $scope.information = '';
                /**
                 * Function that navigates the user back to the list view this can happen for various reasons.
                 * Generally a toast message should be added before the navigation to display the reason.
                 */
                $scope.navigateToList = function () {
                    $state.go('locale.app.dossier');
                };

                if(UserModel.hasRole('BGO')) {
					CompanyResource.find(UserModel.companyId).then(function (company) {
						$scope.information = company.information;
					}, $scope.handleFailure);
                    // $scope.navigateToList();
                    // return;
                }else {
					DossierResource.find($stateParams.uuID).then(function (dossier) {
						$scope.dossier = dossier;
						DossierResource.setActive($scope.dossier, 'extra-info');
						var id = dossier.companyId;
						CompanyResource.find(id)
							.then(function (company) {

							$scope.information = company.information;
						}, $scope.handleFailure);

					}, function (err) {
						$scope.handleFailure(err);
						$scope.navigateToList();

					});
                }

                $scope.handleFailure = function(err) {
                    if(err == null) err = 'unknown-error';
                    if(err.message) err = err.message.toLowerCase().replace(/\s/g, "-");
                    toastMessages.add(toastMessages.T_DANGER, '', 'failure.'+err, 4000);
                };
                $scope.failureHandler = $scope.handleFailure.bind($scope);

                /**
                 * Grab the dossier out of the dossier resource collection.
                 */


                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                    $scope.model = null;
                    $scope.chapters = null;
                    $scope.failureHandler = null;
                });

            }
        ]
    );
});
