/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 27-7-2015
 * Time: 14:48
 * For Project: nabes-front
 * @version : 0.1
 */

var uniqueId = (function() {
  var c = 0;
  return function(prefix) {
      c++;
      return prefix + c;
  };
}());

define('components/dossier/base',[
    'angular',
    'lodash'
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.components.dossier.BaseComponent', []
    ).factory('BaseComponent', [
        '$log',
        function($log) {
            function BaseComponent(attrs) {
                ng.extend(this, attrs);
                this.init();
            }

            ng.extend(BaseComponent.prototype, {
                mapping: {
                    StatusWrapper: {},
                    Page: { tag: 'nb-page' },
                    Block: { tag: 'nb-block' },
                    Title: { tag: 'nb-title' },
                    NextBtn: {
                        tag: 'nb-next-btn',
                        additionalHTML: ['data="dossier"', 'next-page="nextPage()"']
                    },
                    Button: {
                        tag: 'nb-button',
                        additionalHTML: ['data="dossier"']
                    },
                    Text: {
                        tag: 'nb-text',
                        additionalHTML: ['data="dossier"']
                    },
                    AutoComplete: {
                        tag: 'nb-auto-complete',
                        additionalHTML: ['data="dossier"']
                    },
                    // TerminationService: {
                    //     tag: 'nb-termination-service',
                    //     additionalHTML: ['data="dossier"']
                    // },
                    Checklist: {
                        tag: 'nb-checklist',
                        additionalHTML: ['data="dossier"', 'ng-if="isChecklistPage"']
                    },
                    NumberInput: {
                        tag: 'nb-number-input',
                        additionalHTML: ['data="dossier"']
                    },
                    TextInput: {
                        tag: 'nb-text-input',
                        additionalHTML: ['data="dossier"']
                    },
                    TextAreaInput: {
                        tag: 'nb-text-area-input',
                        additionalHTML: ['data="dossier"']
                    },
                    DateInput: {
                        tag: 'nb-date-input',
                        additionalHTML: ['data="dossier"']
                    },
                    RadioInput: {
                        tag: 'nb-radio-input',
                        additionalHTML: ['data="dossier"']
                    },
                    CheckboxInput: {
                        tag: 'nb-checkbox-input',
                        additionalHTML: ['data="dossier"']
                    },
                    SelectInput: {
                        tag: 'nb-select-input',
                        additionalHTML: ['data="dossier"']
                    },
                    Grid: {
                        tag: 'nb-grid',
                        additionalHTML: ['data="dossier"']
                    },
                    Row: {
                        tag: 'nb-row',
                        additionalHTML: ['data="dossier"']
                    },
                    SendAccessInviteInput: {
                        tag: 'nb-send-access-invite',
                        additionalHTML: ['data="dossier"']
                    },
                    MoreInfoLink: {
                        tag: 'nb-more-info-link',
                        additionalHTML: ['data="dossier"']
                    },
                    Upload: {
                        tag: 'nb-upload',
                        additionalHTML: ['data="dossier"']
                    },
                    Spacer: {
                        tag: 'nb-spacer',
                        additionalHTML: ['data="dossier"']
                    }
                },
                /**
                 * Initialization function
                 */
                init: function() {},
                /**
                 * Function that retrieves the opening HTML tag of this Component.
                 */
                getOpeningHTMLTag: function(properties, type) {
                    var me = this;

                    if(me.mapping[type] === undefined) {
                        $log.error("Undefined mapping for component type "+type);
                        return "";
                    }

                    //if the item has no tag it means it should not be rendered
                    if(me.mapping[type].tag === undefined) return "";
                    var html = "<" + me.mapping[type].tag + "";

                    //add the properties
                    _.each(properties, function(value, key) {
                        var convertedKeys = key.split(/(?=[A-Z])/);
                        var keys = [];
                        convertedKeys.forEach(function(value) {
                            keys.push(value.toLowerCase());
                        });
                        key = keys.join('-');
                        if(value instanceof Object) value = me._convertObjectToParseAbleString(value);
                        html += ' ' + key + '="' + value + '"';
                    });

                    //add additional html
                    if(!_.isEmpty(me.mapping[type].additionalHTML)) {
                        _.each(me.mapping[type].additionalHTML, function(addition) {
                            html += ' ' + addition;
                        });
                    }

                    if(type === 'Button') console.log(html + '>');

                    return html += ">";
                },
                /**
                 * Function that retrieves the closing HTML tag of this Component.
                 * It checks the mapping object in this class to determine if the component actually exists.
                 */
                getClosingHTMLTag: function(type) {
                    var me = this;
                    if(me.mapping[type] === undefined) return "";
                    return "</" + me.mapping[type].tag + ">";
                },
                /**
                 * Function that converts the Object to a template parse-able object.
                 * So it first does a JSON.stringify() and then replaces all ["] with [']
                 * This then gets inserted into an attribute on the specified element within the dom.
                 * @param {{}} object
                 * @returns {string}
                 * @private
                 */
                _convertObjectToParseAbleString: function(object) {
                    var string = JSON.stringify(object);
                    return string.replace(new RegExp('"', 'g'), '\'');
                }
            });
            return new BaseComponent();
        }
    ]);
});
