/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/document/controller',[
	'angular',
	'lodash',
	'angularFileUpload',
	'collection/toast-messages', //toast messages collection
	'model/dossier-resource', //dossier resource
	'model/dossier-document-resource' //dossier resource
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.controllers.DossierDocumentController', [
			'Twensoc.Config',
			'ui.bootstrap',
			'DossierResource',
			'ngFileUpload',
			'NabesFront.collection.ToastMessagesCollection',
			'ipCookie',
			'DossierDocumentResource'

		]
	).controller(
		'DossierDocumentController', [
			'$state',
			'$stateParams',
			'$scope',
			'$rootScope',
			'$timeout',
			'$http',
			'config',
			'Upload',
			'DossierResource',
			'DossierDocumentResource',
			'ipCookie',
			'toastMessages',
			function ($state, $stateParams, $scope, $rootScope, $timeout, $http, config, Upload, DossierResource, DossierDocumentResource, ipCookie, toastMessages) {
				$scope.Config = config;
				$rootScope.Title = "Dossier documenten pagina";
				$rootScope.BodyClass = "dossier";

				$scope.dossier = {};
				$scope.picFile = undefined;
				$scope.docType = null;
				$scope.token = ipCookie('NB_TOKEN');

				$scope.downloadUrl = config.getAPIUrl('/document/download/');
				$scope.upload = {};
				$scope.docTypes = DossierDocumentResource.docTypes;
				$scope.documents = [];

				console.log("Document Controller " + angular.toJson($stateParams, true));

				$scope.chooseType = function () {
					return !($scope.docTypes !== undefined && $scope.docTypes.length === 1);
				};
				$scope.showDocs = function () {
					return !($scope.documents !== undefined && $scope.documents.length < 1);
				};
				
				/**
				 * Function that checks if this input has a specific error.
				 * If this is the case the error is displayed to the user.
				 * @param {string} fieldName
				 * @param {string} errorName
				 * @returns {boolean}
				 */
				$scope.hasError = function (fieldName, errorName) {
					var value = _.getByString($scope.form[fieldName].$error, errorName);
					return (($scope.form.$submitted === true || $scope.form[fieldName].$dirty === true) && value);
				};

				$scope.docTypeDescription = function (document) {
					return DossierDocumentResource.docTypeDescription(document);
				};

				$scope.upload = function (file, form) {
					if (form.$invalid) return;
					if ($scope.docTypes.length === 1) {
						$scope.docType = 1;
					}
					DossierDocumentResource.upload(file, $scope.dossier.id, $scope.docType).then(function () {
						// Note: Upon a successful upload, another document may have been removed by the server
						// because for some docTypes only one document may exist. A previously uploaded document
						// with the same docyType may have been removed. Thus, reload the complete list of documents.
						$scope.loadDocuments();
						$scope.picFile = undefined;
						$scope.docType = 0;
						form.$setPristine();
						form.$setUntouched();
					}, function (data) {
						console.log("Upload failed");
						console.log(data);
					});
				};

				$scope.deleteDocument = function (document) {
					DossierDocumentResource.destroy(document.id).then(function () {
						// Note: Upon a successful upload, another document may have been removed by the server
						// because for some docTypes only one document may exist. A previously uploaded document
						// with the same docyType may have been removed. Thus, reload the complete list of documents.
						$scope.loadDocuments();
					}, function (data) {
						console.log("Delete failed");
						console.log(data);
					});
				};

				$scope.cancel = function (form) {
					$scope.picFile = undefined;
					$scope.docType = 0;
				};

				$scope.fileSelected = function () {
					return $scope.picFile === undefined || $scope.docType !== 0;
				};

				$scope.loadDocuments = function () {
					DossierDocumentResource.loadDocumentsForDossier($scope.dossier.id).then(function (documents) {
						// Concatenate the loaded documents to the current scope list that's being watched
						$scope.documents.splice(0);
						$scope.documents = $scope.documents.concat(documents);
					}, function (data) {
						console.log("Upload failed");
						console.log(data);
					});
				};

				/**
				 * Grab the dossier out of the dossier resource collection.
				 */
				DossierResource.find($stateParams.uuID).then(function (dossier) {
					$scope.dossier = dossier;
					DossierResource.setActive($scope.dossier, 'document');

					$scope.loadDocuments();

				}, function () {
					$scope.dossier = null;
					$scope.documents = null;
				});

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
					//DossierResource.unsetActive();
					$scope.dossier = null;
					$scope.documents = null;
				});


			}
		]
	);
});
