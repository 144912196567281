/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 27-7-2015
 * Time: 15:51
 * For Project: nabes-front
 * @version : 0.1
 */

define('model/template-item',[
    'angular',
    'lodash',
    'components/dossier/base',
    'components/dossier/block/directive',
    'components/dossier/page/directive',
    'components/dossier/title/directive',
    'components/dossier/text-input/directive',
    'components/dossier/textarea-input/directive',
    'components/dossier/number-input/directive',
    'components/dossier/text/directive',
    'components/dossier/checklist/directive',
    'components/dossier/radio-input/directive',
    'components/dossier/checkbox-input/directive',
    'components/dossier/next-btn/directive',
    'components/dossier/button/directive',
    'components/dossier/date-input/directive',
    'components/dossier/select-input/directive',
    'components/dossier/grid/directive',
    'components/dossier/row/directive',
    'components/dossier/send-access-invite/directive',
    'components/dossier/more-info-link/directive',
    'components/dossier/upload/directive',
    'components/dossier/autocomplete/directive',
    'components/dossier/spacer/directive',
    'model/base' //base model
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.model.TemplateItem', [
            'Twensoc.model.BaseModel',
            'NabesFront.components.dossier.BaseComponent',
            'NabesFront.components.dossier.Block',
            'NabesFront.components.dossier.Page',
            'NabesFront.components.dossier.Title',
            'NabesFront.components.dossier.TextInput',
            'NabesFront.components.dossier.TextAreaInput',
            'NabesFront.components.dossier.Text',
            'NabesFront.components.dossier.Checklist',
            'NabesFront.components.dossier.RadioInput',
            'NabesFront.components.dossier.CheckboxInput',
            'NabesFront.components.dossier.SelectInput',
            'NabesFront.components.dossier.NextBtn',
            'NabesFront.components.dossier.Button',
            'NabesFront.components.dossier.DateInput',
            'NabesFront.components.dossier.Grid',
            'NabesFront.components.dossier.Row',
            'NabesFront.components.dossier.SendAccessInviteInput',
            'NabesFront.components.dossier.MoreInfoLink',
            'NabesFront.components.dossier.NumberInput',
            'NabesFront.components.dossier.Upload',
            'NabesFront.components.dossier.AutoComplete',
            'NabesFront.components.dossier.Spacer'
        ]
    ).factory('TemplateItem', [
        '$q',
        '$timeout',
        '$rootScope',
        'BaseModel',
        'BaseComponent',
        function($q, $timeout, $rootScope, BaseModel, BaseComponent) {
            function TemplateItem(data) {
                this.init();
                this.set(data);
            }

            TemplateItem.prototype.$super = BaseModel.prototype;
            ng.extend(TemplateItem.prototype, BaseModel.prototype, {
                type: "",
                properties: null,
                options: null,
                items: null,
                component: null,
                /**
                 * Initialization function
                 */
                init: function() {
                    this.$super.init.call(this);
                },
                /**
                 * Function that sets the data on this Dossier template model.
                 * It also loops through the this.items array creating sub-TemplateItem classes in it structure.
                 * @param {{}} data
                 */
                set: function(data) {
                    var me = this;
                    me.type = data.type;
                    me.properties = data.properties;
                    if(me.properties) {
                        if(me.properties.showIf === undefined) me.properties.showIf = true;
                        if(me.properties.enabledIf === undefined) me.properties.enabledIf = true;
                        if(me.properties.readOnlyIf === undefined) me.properties.readOnlyIf = false;
                    }
                    if(me.type == 'Chapter') {
                        if(me.properties.showProgressBar == 'false') me.properties.showProgressBar = false;
                        if(me.properties.slug==="checklist") {
                            me.watchedChecklistItems = data.watchedChecklistItems;
                        }
                    }
                    if(me.type == 'Row') {
                        if(me.filter == null || me.filter === '') me.filter = 'true';
                    } // Show all rows
                    me.items = [];
                    if(data.items !== undefined && data.items.length > 0) {
                        _.each(data.items, function(data) {
                            me.items.push(new TemplateItem(data));
                        });
                    }
                    me.component =  BaseComponent;
                },
                /**
                 * Function that retrieves the HTML of itself and of its child components.
                 * This is done by calling the component getOpeningHTMLTag() then looping through its children calling their getHTML() function.
                 * And then finally calling the getClosingHTMLTag() on the component to close the html tag.
                 * @returns {string} HTML
                 */
                getHTML: function(indent) {
                    var me = this;
                    indent = indent || 0;

                    //console.log(_.repeat(" ", indent)+"["+me.type+" label:"+me.properties.label+"]");
                    if(me.component === null || me.component === undefined) return "";

                    //console.log(me.prettyPrint(me));

                    var html = me.component.getOpeningHTMLTag(me.properties, me.type);
                    if(me.items !== undefined && me.items.length > 0) {
                        _.each(me.items, function(model) {
                            html += model.getHTML(indent + 2);
                        });
                    }
                    //console.log(_.repeat(" ", indent)+"[/"+me.type+"]");
                    //if(indent == 0) {
                    //    console.log(me.prettyPrint(me.items));
                    //}
                    return html += me.component.getClosingHTMLTag(me.type);
                },


                replacer: function(match, pIndent, pKey, pVal, pEnd) {
                    var key = '';
                    var val = '';
                    var str = '';
                    var r = pIndent || '';
                    if (pKey)
                        r = r + key + pKey.replace(/[": ]/g, '') + ': ';
                    if (pVal)
                        r = r + (pVal[0] == '"' ? str : val) + pVal + '';
                    return r + (pEnd || '');
                },
                prettyPrint: function(obj) {
                    var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
                    return JSON.stringify(obj, null, 3)
                        .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
                        .replace(/</g, '&lt;').replace(/>/g, '&gt;')
                        .replace(jsonLine, this.replacer);
                }
            });

            return TemplateItem;
        }
    ]);
});
