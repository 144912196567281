/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/dossier-template-definition-resource',[
	'angular',
	'lodash',
	'js-data'
], function (ng, _) {
	'use strict';

	return ng.module(
		'DossierTemplateDefinitionResource', [
			'js-data',
			'Twensoc.Config',
			'TemplateInterpreter',
			'ipCookie',
			'Twensoc.utils.VertxClient'
		]
	).factory('DossierTemplateDefinitionResource', [
			'$rootScope',
			'$q',
			'$log',
			'DS',
			'config',
			'TemplateInterpreter',
			'$http',
			'ipCookie',
			'VertxClient',
			function ($rootScope, $q, $log, DS, config, templateInterpreter, $http, ipCookie, VertxClient) {

				var DossierTemplateDefinitionResource = DS.defineResource({
					name:"DossierTemplateDefinitionResource",
					endpoint: '/dossiertpldef/',
					defaultValues: {
						id: null,
						dossierTplId: 0,
						published: false,
						timestamp: null,
						template: '',
						revision: 0
					},
					interpretDefinition: function (dossierTemplateDef) {

						dossierTemplateDef.items = dossierTemplateDef.items || [];

						if(dossierTemplateDef.items.length === 0) {

							// Parse tplDefinition BB code to json
							var result = templateInterpreter.parse(dossierTemplateDef.template);

							if(!_.isEmpty(result.errors)) {
								alert('Template contains errors.');
								console.error(result.errors);
							} else {
								templateInterpreter.findChecklistItems(result);
							}

							// Concat, don't create new instance. dossierTemplateDef.items might already be watched
							dossierTemplateDef.items = dossierTemplateDef.items.concat(templateInterpreter.createTemplateItems(result.items));
						}
					},

					/**
					 * Saves a dossierTplDef using a http post. The template is too large to send over a socket.
					 * Saving a dossierTplDef means creating a new record. The server returns the record without the
					 * lazy template field.
					 *
					 * @param dossierTplDef
					 * @return {deferred.promise|{then, always}}
					 */
					createNewRevision: function(dossierTplDef) {
						var deferred = $q.defer(),
							items = dossierTplDef.items;


						delete dossierTplDef.items; // Don't send the deserialized items array

						$http.post(config.getAPIUrl("dossiertpldef/save"), dossierTplDef)
							.success(function (data, status, headers, config) {
								var newDossierTplDef = data;

								newDossierTplDef.items = items;
								newDossierTplDef.template = dossierTplDef.template; // template is not returned from update

								if (data.errors) {
									$log.error(address + ": " + JSON.stringify(data.errors));
									deferred.reject();
									return;
								}

								DossierTemplateDefinitionResource.inject(newDossierTplDef);

								deferred.resolve(newDossierTplDef);
							})
							.error(function (data, status, headers, config) {
								dossierTplDef.items = items;
								deferred.reject();
							});

						return deferred.promise;
					},

					load: function(dossierTplId, dossierTplDefId) {

						//if(dossierTplDefId !== 0) {
						//	// We need to fetch a specific version
						//	return DossierTemplateDefinitionResource.find(dossierTplDefId);
						//}

						// Retrieve the latest version for the dossierTplId
						var deferred = $q.defer();
						VertxClient.send('/dossiertpldef/getlatestrev', {dossierTplId: dossierTplId}).
							then(function(dossierTplDef) {
								dossierTplDef = DossierTemplateDefinitionResource.inject(dossierTplDef);
								DossierTemplateDefinitionResource.interpretDefinition(dossierTplDef);
								deferred.resolve(dossierTplDef);
							}, function(err) {
								deferred.reject(err);
							});

						return deferred.promise;

					},
					methods: {
						/**
						 * Function that retrieves the chapters within this DossierTemplate
						 * @returns {[]|undefined}
						 */
						getChapters: function () {
							return _.where(this.items, {"type": "Chapter"});
						},
						/**
						 * Function that retrieves a chapter specified by the chapterSlug within this DossierTemplate
						 * @param {undefined|string} [chapterSlug]
						 */
						getChapter: function (chapterSlug) {
							return _.findWhere(this.items, {"type": "Chapter", "properties": {"slug": chapterSlug}});
						},
						/**
						 * Function that retrieves the HTML for the specified chapter + page.
						 * This is returned as a HTML string.
						 * @param {string} chapterSlug
						 * @param {string} pageSlug
						 * @returns {string}
						 */
						getHTML: function (chapterSlug, pageSlug) {
							var me = this;
							var html = "";
							if (!_.isEmpty(chapterSlug) && !_.isEmpty(pageSlug)) {
								var item = me.getCurrentItem(chapterSlug, pageSlug);
								if (!_.isEmpty(item)) {
									return item.getHTML();
								}
							}
							return html;
						},
						/**
						 * Retrieve the current page item.
						 * @param {string} chapterSlug
						 * @param {string} pageSlug
						 * @returns {{}|undefined}
						 */
						getCurrentItem: function (chapterSlug, pageSlug) {
							//get chapter
							var chapter = this.getChapter(chapterSlug);

							var item;
							if(chapterSlug === 'checklist') {
								item = chapter.items[0];
							} else {
								item = _.findWhere(chapter.items, {"type": "Page", "properties": {"slug": pageSlug}});
							}
							return item;
						},
						/**
						 * Function that retrieves the current page item index.
						 * @param {String} chapterSlug
						 * @param {string} pageSlug
						 * @returns {number}
						 */
						getCurrentItemIndex: function (chapterSlug, pageSlug) {
							//get chapter
							var chapter = this.getChapter(chapterSlug);
							if (!chapter) return -1;
							//get page index
							var index;
							if(chapterSlug === 'checklist') {
								index = 0;
							} else {
								index = _.findIndex(chapter.items, {"type": "Page", "properties": {"slug": pageSlug}});
							}
							return index;
							//return _.findIndex(chapter.items, {"type": "Page", "properties": {"slug": pageSlug}});
						},
						/**
						 * Function that checks if the current chapter exists and has a child with the specified page slug.
						 * @param {string} chapterSlug
						 * @param {string} pageSlug
						 * @returns {boolean}
						 */
						chapterPageExists: function (chapterSlug, pageSlug) {
							return this.getCurrentItemIndex(chapterSlug, pageSlug) !== -1;
						},
						/**
						 * Function that retrieves the first chapter for this template.
						 * This is used to determine the slug for the first chapter and adjust it accordingly.
						 * @returns {{}|null}
						 */
						getFirstChapter: function () {
							var me = this;
							if (!_.isEmpty(me.items)) {
								var itemOne = me.items[0];
								if (itemOne.type === 'Chapter') return itemOne;
							}
							return null;
						},

						getChecklistChapter: function() {
							return this.getChapter('checklist');
						}
					}
				});
				$log.info('DossierTemplateDefinitionResource defined');

				return DossierTemplateDefinitionResource;
			}
		]);
});
