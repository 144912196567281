/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/dossier/list/controller',[
	'angular',
	'lodash',
	'angularUIBootstrap',
	'model/user',
	'model/dossier-resource' //dossier resource
], function (ng, _) {
	'use strict';

	return ng.module(
		'NabesFront.controllers.DossierListController', [
			'Twensoc.Config',
			'NabesFront.model.UserModel',
			'DossierResource',
			'NabesFront.utils.Authentication',
			'ui.bootstrap.pagination'
		]
	).controller(
		'DossierListController', [
			'$state',
			'$stateParams',
			'$scope',
			'$rootScope',
			'config',
			'$log',
			'UserModel',
			'DossierResource',
			'Authentication',
			function ($state, $stateParams, $scope, $rootScope, config, $log, UserModel, DossierResource, Authentication) {
				$scope.Config = config;
				$rootScope.Title = "Dossier overview pagina";
				$rootScope.BodyClass = "dossier";

				$scope.dossiers = [];
				$scope.display = false;
				$scope.isAdmin = UserModel.hasRole("ADMIN");
				$scope.isBgo = UserModel.hasRole("BGO");
				$scope.defaultList="3";
				if($scope.isBgo||$scope.isAdmin){$scope.defaultList = "1";}
				$scope.selector = {
					value: $state.params.hist || $scope.defaultList,
					options: [
						{
							value: "1",
							label: "dossierList.histOnlyActive"
						}, {
							value: "2",
							label: "dossierList.histInactive"
						}, {
							value: "3",
							label: "dossierList.histBoth"
						}
					]
				};

				$scope.paging = {
					filter: $state.params.filter,
					pageNo: $state.params.pageNo,
					totalItems: MAX_INT,
					pageSize: 10,
					hist: parseInt($scope.selector.value)
				};

				if (Authentication.lastVisited.url !== "" && angular.isNumber(Authentication.lastVisited.dossierId)) {
					var partsOfStr = Authentication.lastVisited.url.split('/');
					var pageSlug = partsOfStr[2];
					var chapterSlug = partsOfStr[3];
					Authentication.lastVisited.url = "";
					$state.go('locale.app.dossier.overview.edit', {
						uuID: Authentication.lastVisited.dossierId,
						chapterSlug: pageSlug,
						pageSlug: chapterSlug
					});
				}


				/**
				 * Changes the page based on the search, pageNo etc.
				 * This changes the state so its reflected within the URL
				 */
				$scope.changePage = function () {
					//reset page number if filter is modified as we do not know how many pages the query will result in
					if ($state.params.filter !== $scope.paging.filter) $scope.paging.pageNo = 1;
					$state.go($state.current, {pageNo: $scope.paging.pageNo, filter: $scope.paging.filter, hist: $scope.selector.value});
				};

				DossierResource.findAll($scope.paging, {bypassCache: true}).then(function (dossiers) {
					//the total items are automatically set as a pass by reference.
					$scope.dossiers = dossiers;

					/**
					 * If the dossiers.length equals to 1 we have to send the user if he has user role USER to the overview page.
					 * All other people / options are not redirected and get to see the dossier list page.
					 */
					if (dossiers.length !== 1 || $scope.Authentication.hasRole(['ADMIN', 'BGO'])) {
						$scope.display = true;
						return;
					}
					$state.go('locale.app.dossier.overview', {Locale: config.getUrlPrefix(), uuID: dossiers[0].id});
				});

				DossierResource.unsetActive();

				$scope.editLink = function (dossierId , hist ) {
					if (Authentication.hasRole("BGO")&& !hist) {
						$state.go('locale.app.dossier.overview.edit', {uuID: dossierId});
					} else {
						$state.go('locale.app.dossier.overview', {uuID: dossierId});
					}
				};

				/**
				 * Time for some cleaning up of listeners! this is very important!
				 */
				$scope.$on('$destroy', function () {
				});
			}
		]
	);
});
