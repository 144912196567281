/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('model/dossier-document-resource',[
	'angular',
	'lodash',
	'js-data',
	'model/base' //base model
], function (ng, _) {
	'use strict';

	return ng.module(
		'DossierDocumentResource', [
			'js-data',
			'Twensoc.model.BaseModel',
			'Twensoc.Config',
			'ngFileUpload',
			'ipCookie'
		]
	).factory('DossierDocumentResource', [
			'$rootScope',
			'$q',
			'$log',
			'$filter',
			'DS',
			'config',
			'Upload',
			'ipCookie',
			function ($rootScope, $q, $log, $filter, DS, config, Upload, ipCookie) {

				var DossierDocumentResource = DS.defineResource({
					name:"DossierDocumentResource",
					endpoint: '/document/',
					defaultValues: {
						id: null,
						dossierId: null,
						docType: 0,
						name: '',
						fileName: '',
						size: '',
						uploadDate: null
					},
					docTypes: [{
						id: 1,
						label: "Akte van overlijden"
					}
						/*,{
						id: 2,
						label: "Testament"
					}, {
						id: 3,
						label: "Verklaring van erfrecht"
					}, {
						id: 4,
						label: "Rouwkaart"
					},{
						id: 10,
						label: "ID partner"
					}*/
					],
					beforeInject: function (resource, obj) {
						var d = null;
						if (_.isArray(obj)) {
							var i = obj.length;
							while (i--) {
								d = obj[i].uploadDate;
								if (d && !_.isDate(d)) {
									obj[i].uploadDate = $filter('date')(new Date(d), 'dd-MM-yyyy  hh:mm', null);
								}
							}
						} else if (obj) {
							d = obj.uploadDate;
							if (d && !_.isDate(d)) {
								obj.uploadDate = $filter('date')(new Date(d), 'dd-MM-yyyy  hh:mm', null);
							}
						}
					},

					getDocType: function(id) {
						id = parseInt(id);
						return _.findWhere(this.docTypes, {id: id});
					},

					docTypeDescription : function(document) {
						if(!document) return "";
						for(var i=0;i<this.docTypes.length;i++) {
							if(this.docTypes[i].id == document.docType) {
								return this.docTypes[i].label;
							}
						}
						return null;
					},
					/**
					 * Filter the documents for a dossier ID
					 * This returns a promise that can be resolved later on.
					 * @param dossierId
					 * @returns {*}
					 */
					findAllForDossier: function(dossierId, bypassCache) {
						var me = this;
						bypassCache = bypassCache === undefined ? false : bypassCache;
						return me.findAll({
							dossierId:  dossierId
						}, {
							bypassCache: bypassCache
						});
					},

					getFileByDocType: function(dossierId, docType) {
						return this.findAll({
							where: {
								dossierId: {
									'==': dossierId
								},
								docType: {
									'==': docType
								}

							}
						}, {
							loadFromAdapter: false
						});
					},

					upload: function(file, dossierId, docType) {
						var me = this,
							deferred = $q.defer();

						file.upload = Upload.upload({
							url: config.getAPIUrl('/document/upload/'), // 'https://angular-file-upload-cors-srv.appspot.com/upload',
							data: {file: file, docType: docType, dossierId: dossierId, token: ipCookie('NB_TOKEN')}
						});

						file.upload.then(function (response) {
							// Note: Upon a successful upload, another document may have been removed by the server
							// because for some docTypes only one document may exist. A previously uploaded document
							// with the same docyType may have been removed.
							file.result = response.data;
							_.each(response.data.items, function(item) {
								me.inject(item);
							});
							deferred.resolve(response.data.items);

						}, function (response) {
							if (response.status > 0) {
								$scope.errorMsg = response.status + ': ' + response.data;
							}
							deferred.reject(response.status);
						}
						//	, function (evt) {
						//	$scope.picFile = undefined;
						//	$scope.docType = 0;
						//	// Math.min is to fix IE which reports 200% sometimes
						//	file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
						//}
						);
						return deferred.promise;
					},
					loadDocumentsForDossier: function(dossierId) {
						var deferred = $q.defer();

						this.findAllForDossier(dossierId, true).then(function(documents) {
							deferred.resolve(documents);
						}, function(data) {
							deferred.reject(data);
						});
						return deferred.promise;
					},
					getByDocType: function(dossierId, docType) {
						var deferred = $q.defer();

						DossierDocumentResource.findAllForDossier(dossierId, false).then(function (files) {
							var item = _.findWhere(files, {docType: parseInt(docType)});
							if(item) {
								deferred.resolve(item);
							} else {
								deferred.resolve(null);
							}
						}, function(err) {
							deferred.reject(err);
						});

						return deferred.promise;
					},
					methods: {
					}
				});
				$log.info('DossierDocumentResource defined');

				return DossierDocumentResource;
			}
		]);
});
