/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 7-13-2015
 * Time: 10:42
 * For Project: Nabes-front
 * @version : 0.1
 */

define('controllers/customer-account/list/controller',[
    'angular',
    'lodash',
    'angularUIBootstrap',
    'model/account-resource', //account resource
    'model/company-resource' //company resource
], function (ng, _) {
    'use strict';

    return ng.module(
        'NabesFront.controllers.CustomerAccountOverviewController', [
            'Twensoc.Config',
            'AccountResource',
            'CompanyResource',
            'NabesFront.utils.Authentication',
            'ui.bootstrap.pagination',
            'DossierResource'
        ]
    ).controller(
        'CustomerAccountOverviewController', [
            '$state',
            '$stateParams',
            '$scope',
            '$rootScope',
            'config',
            '$log',
            'AccountResource',
            'CompanyResource',
            'Authentication',
            'DossierResource',
            function ($state, $stateParams, $scope, $rootScope, config, $log, AccountResource, CompanyResource, Authentication, DossierResource) {
                $scope.Config = config;
                $rootScope.Title = "Account overview pagina";
                $rootScope.BodyClass = "account";

                $scope.accounts = [];
                $scope.company = {};
                $scope.companyId = $stateParams.companyId;
                $scope.isAdmin = Authentication.hasRole('ADMIN');

                DossierResource.unsetActive();

                $scope.paging = {
                    filter: $state.params.accountFilter,
                    pageNo: $state.params.accountPageNo,
                    totalItems: MAX_INT,
                    pageSize: 10,
                    companyId: $stateParams.companyId||0,
                    roles:['USER']
                };

                /**
                 * Changes the page based on the search, pageNo etc.
                 * This changes the state so its reflected within the URL
                 */
                $scope.changePage = function() {
                    //reset page number if filter is modified as we do not know how many pages the query will result in
                    if($state.params.accountFilter !== $scope.paging.filter) $scope.paging.pageNo = 1;
                    $state.go($state.current, {accountPageNo: $scope.paging.pageNo, accountFilter: $scope.paging.filter});
                };

                /**
                 * Retrieves all the accounts by either company id if this has been specified.
                 * Else it retrieves all the accounts.
                 *
                 * When the company ID is specified it also retrieves the company.
                 */
                if($scope.companyId > 0) {
                    //filter accounts by company
                    AccountResource.findAllByCompany($scope.paging, {bypassCache: true}).then(function(records) {
                        $scope.accounts = $scope.accounts.concat(records);
                    });

                    //get company
                    CompanyResource.find($stateParams.companyId).then(function(company) {
                        $scope.company = company;
                    });
                } else {
                    AccountResource.findAll($scope.paging, {bypassCache: true}).then(function(records) {
                        $scope.accounts = $scope.accounts.concat(records);
                    });
                }

                /**
                 * Function that sends the user to the edit url.
                 * This is done because sometimes the company ID is specified and sometimes its not.
                 * @param {number} accountID
                 */
                $scope.gotToEditUrl = function(accountID) {
                    var params = {accountId: accountID};
                    if($scope.companyId > 0) {
                        $state.go('locale.app.company.edit.account.edit', params);
                        return;
                    }
                    $state.go('locale.app.account.edit', params);
                };

                $scope.back = function() {
                    $state.go('locale.app.company', {companyId: $scope.companyId });
                };

                /**
                 * Time for some cleaning up of listeners! this is very important!
                 */
                $scope.$on('$destroy', function() {
                });
            }
        ]
    );
});
