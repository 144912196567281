define('utils/vertx-client',[
	'angular',
	'lodash',
	'vertx-eventbus',
	'config'
], function (ng, _, EventBus) {
	'use strict';

	return ng.module(
		'Twensoc.utils.VertxClient', [
			'Twensoc.Config',
			'NabesFront.collection.ToastMessagesCollection'
		]
	).factory('VertxClient', [
		'$log',
		'$timeout',
		'$rootScope',
		'$q',
		'$window',
		'config',
		'toastMessages',
		function($log, $timeout, $rootScope, $q, $window, config, toastMessages) {
			function VertxClient() {
				this.init();
			}

			ng.extend(VertxClient.prototype, {
				_eventBus: null,
				_reopenCount: 0,
				/**
				 * Initialization function.
				 */
				init: function() {},
				/**
				 * Function that creates the eventBus and the websocket associated with it.
				 * @returns {Promise}
                 */
				keepalive: function() {
					var me = this;
					me.ka = setInterval(function() {
						me.send("/keepalive", {});
					}, 60 * 1000);
				},
				open: function() {
					var me = this;
					var deferred = $q.defer();

					window.vertxclient = me;

					if(me._eventBus == null ) {
						me._eventBus = new EventBus(config.getAPIUrl('ws'), {
							'transports': [ 'websocket', 'xdr-streaming', 'xhr-streaming',
								'iframe-eventsource', 'iframe-htmlfile',
								'xdr-polling', 'xhr-polling', 'iframe-xhr-polling',
								'jsonp-polling']
						});
						me._eventBus.onopen = me._onOpen.bind(me);
						me._eventBus.onclose = me._onClose.bind(me);
						me._eventBus.onerror = me._onError.bind(me);

						var timeout = $timeout(function () {
							$log.error('Socket could not be opened!');
							deferred.reject();
							opened();
						}, 10000);

						var opened = $rootScope.$on('WebSocket.Open', function () {
							deferred.resolve();
							$timeout.cancel(timeout);
							opened();
						});
						return deferred.promise;
					} else if(me._eventBus.state == 1) {
						// Already open
						deferred.resolve();
					}

					//
					return deferred.promise;

				},
				/**
				 * OnOpen function that gets triggered once the web-socket is opened.
				 * @private
                 */
				_onOpen: function() {
					this._reopenCount = 0;
					console.log("_onOpen: "+(new Date()));
					console.log("_reopenCount: "+ this._reopenCount);
					$rootScope.$emit('WebSocket.Open');
					this.keepalive();
				},
				/**
				 * OnClose function that gets triggered once the web-socket is closed
				 * //TODO check if we need to re-connect the user at this point
				 * @private
                 */
				_onClose: function() {
					console.log("_onClose: "+(new Date()));
					var me = this;
					me._eventBus.onopen = null;
					me._eventBus.onclose = null;
					me._eventBus.onerror = null;
					me._eventBus = null;
					if(me.ka) clearInterval(me.ka);
					me.ka = null;

					// If we have a window.localStorage.token, we're not logged out, so reopen the socket immediately
					if(window.localStorage.token) {
						this._reopenCount++;
						console.log("_reopenCount: " + this._reopenCount);
						if(this._reopenCount < 5)
							setTimeout(function() {
								me.open();
							}, 1000);
						else {
							toastMessages.add(toastMessages.T_DANGER, '', 'failure.socketClosed', 0);
						}
					}
				},
				/**
				 * OnError function that gets triggered once the web-socket throws an error.
				 * @private
                 */
				_onError: function(err) {
					$log.error('Error Occurred with the websocket');
					$log.warn(err.body);
				},
				/**
				 * Function through which the user can retrieve the eventBus and execute functions directly on it.
				 * @returns {VertxClient._eventBus|{}}
                 */
				getEventBus: function() {
					return this._eventBus;
				},
				/**
				 * Function that sends a message to the websocket
				 * @param {string} address
                 * @param {{}} data
                 * @returns {Promise}
                 */
				send: function(address, data) {
					var me = this;
					if(me._eventBus) {
						if(me._eventBus.state == 1) {
							// Open, send message
							return me._doSend(address, data);
						} else if(me._eventBus.state === 0) {
							// Connecting, wait a little
							var deferred = $q.defer();
							console.log("Connection is connecting, wait 500ms");
							setTimeout(function() {
								me._doSend(address, data).then(
									function(data) {
										deferred.resolve(data);
									}, function(err) {
										deferred.reject(err);
									});
							}, 500);
							return deferred.promise;
						}
					}

					console.log("Can't send message. Eventbus status = "+ (me._eventBus != null ? me._eventBus.state : 'null'));
					console.log("Message to "+address);
					console.log("Data: "+angular.toJson(data, true));
					return $q.reject();
				},
				_doSend: function(address, data) {
					var me = this;
					var deferred = $q.defer();

					me._eventBus.send(address, data, function (error, data) {
						if (error && error.failureCode != null) {
							if(data) delete data.address;
							deferred.reject(error);
						} else {
							deferred.resolve(data.body);
						}
					});
					return deferred.promise;
				},
				/**
				 * Function that closes the websocket (And won't let it reopen automatically).
				 */
				close: function() {
					if(this._eventBus) {
						this._eventBus.close();
					}
				}
			});

			return new VertxClient();
		}
	]);
});
