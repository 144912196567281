/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 14-4-2015
 * Time: 09:25
 * For Project: fairplay
 * @version : 0.1
 */

define('config',[
	"angular", "lodash", "flashDetect", "angularCookie", "angularTranslate"
], function (ng, _) {
	"use strict";
	return ng.module("Twensoc.Config", [
		"ipCookie", "pascalprecht.translate"
	]).factory("config", [
		"$rootScope",
		"$http",
		"$q",
		"$translate",
		"ipCookie",
		function ($rootScope, $http, $q, $translate, ipCookie) {

			function Config(attrs) {
				ng.extend(this, attrs);
				this.init();
			}

			ng.extend(Config.prototype, {
				isPhone: false,
				flashEnabled: false,
				cordovaApp: false,
				siteUrl: "",
				apiUrl: "",
				assetPath: "",
				environment: "",
				version: "",
				appName: "",
				/**
				 * The constructor function. Of this class.
				 */
				init: function () {
					var me = this;
					me.setIsPhone(me._detectPhone());
					me.setFlashEnabled(me._detectFlash());
					me.setIsCordovaApp(templateConfig.phoneGapApp);
					me.siteUrl = templateConfig.siteUrl;
					me.apiUrl = templateConfig.apiUrl;
					me.assetPath = templateConfig.assetPath;
					me.environment = templateConfig.environment;
					me.version = templateConfig.version;
					me.appName = templateConfig.appName;

					var languageCookie = ipCookie("NB_LANGUAGE");
					if (languageCookie !== undefined && languageCookie.length > 0) {
						me.currentLanguage = languageCookie;
					} else {
						me.currentLanguage = "nl-NL";
					}
					$rootScope.Language = me.getCurrentLanguageInFormat("la");

					//log VITAL INFORMATION :P
					console.log(
						"%c Application: " + me.appName + " - Version: " + me.version + " Author: Twensoc - %c http://twensoc.nl ",
						"font-weight: bold; font-size: 18px; background: #01293c; color: #ffc20e",
						"font-weight: bold; font-size: 18px; background: #ffc20e;"
					);
				},
				/**
				 * Function that sets the cordovaApp variable.
				 * @param {boolean} isCordova
				 */
				setIsCordovaApp: function (isCordova) {
					var me = this;
					me.cordovaApp = isCordova;
				},
				/**
				 * Function that sets the isPhone variable.
				 * @param {boolean} isPhone
				 */
				setIsPhone: function (isPhone) {
					var me = this;
					me.isPhone = isPhone;
				},
				/**
				 * Function that sets the flashEnabled variable.
				 * @param {boolean} flashEnabled
				 */
				setFlashEnabled: function (flashEnabled) {
					var me = this;
					me.flashEnabled = flashEnabled;
				},
				/**
				 * Function that returns the asset url to the specified path.
				 * @param path
				 * @returns {string}
				 */
				getAssetUrl: function (path) {
					var me = this;
					return me.siteUrl + me.assetPath + path;
				},

				getHeaderSiteName: function () {

					// string.includes(substring)

					switch (window.location.origin) {

						case "https://nabes.nl":
						case "https://www.nabes.nl":
							return "Nabes";

						case "https://demo.nabes.nl":
						case "https://www.demo.nabes.nl":
							return "Nabes Demo";

						case "https://test.nabes.nl":
						case "https://www.test.nabes.nl":
							return "Nabes Test";

						case "http://nabes.local":
						case "http://www.nabes.local":
						case "http://test.nabes.local":
							return "Nabes Local";

						default:
							console.log("origin = " + window.location.origin);

							if (window.location.origin.includes("HulpBijAfwikkeling")) {
								return "HulpBijAfwikkeling.nl";
							} else {
								return "Nabes";
							}
					}
				},
				/**
				 * Returns the small header url image path that differs from nabes, demo and production
				 * @returns {*|string}
				 */
				getHeaderSiteSlug: function () {
					if (window.location.origin.toLowerCase().indexOf("nabes") > 0) return "Hulp bij afwikkeling nalatenschap";
					return "";
				},
				/**
				 * Retrieve the current language in a special format that modifies the default
				 * lang-LOCALE to a different one for example nl-NL to nl_NL
				 *
				 * Available formats specified below convert nl-NL to:
				 *
				 * la_LO:   nl_NL
				 * la:      nl
				 *
				 * @param format
				 * @returns {string}
				 */
				getCurrentLanguageInFormat: function (format) {
					var me = this;
					if (format === "la-LO") return me.currentLanguage;
					if (format === "la_LO") return me.currentLanguage.replace("-", "_");
					if (format === "la") return me.currentLanguage.substring(0, 2);
					if (format === "la-lo") return me.currentLanguage.toLowerCase();
					if (format === "la_lo") return me.currentLanguage.toLowerCase().replace("-", "_");
					return me.currentLanguage;
				},
				/**
				 * Function that retrieves the language in the default format, based on a mapping table.
				 * @param {string} language
				 */
				getLanguageInDefaultFormat: function (language) {
					var map = [
						{
							Locale: "en",
							DefaultFormat: "en-GB"
						}, {
							Locale: "en-gb",
							DefaultFormat: "en-GB"
						}, {
							Locale: "en-us",
							DefaultFormat: "en-US"
						}, {
							Locale: "nl",
							DefaultFormat: "nl-NL"
						}, {
							Locale: "nl-nl",
							DefaultFormat: "nl-NL"
						}, {
							Locale: "de",
							DefaultFormat: "de-DE"
						}, {
							Locale: "de-de",
							DefaultFormat: "de-DE"
						}
					];
					return _.findWhere(map, {Locale: language});
				},
				/**
				 * Function that changes the languages
				 * @param {string} newLanguage in la-LO format
				 */
				changeLanguage: function (newLanguage) {
					var me = this;
					//wait for user to load + grab it
					me.currentLanguage = newLanguage;
					$translate.use(newLanguage.replace("-", "_"));
				},
				/**
				 * Function that returns the url prefix used throughout the application to determine the url needed
				 * in various models and controllers.
				 * @returns {string}
				 */
				getUrlPrefix: function () {
					var me = this;
					return me.getCurrentLanguageInFormat("la");
				},
				/**
				 * Function that returns the API url with the supplied suffix.
				 * If the suffix contains a / at the start it is stripped out.
				 * @param {string} [suffix]
				 * @returns {string}
				 */
				getAPIUrl: function (suffix) {
					var me = this;
					//suffix is not required but to prevent problems we make it an empty string if it's undefined.
					if (suffix === undefined) suffix = "";
					//remove first slash if present
					if (suffix.substr(0, 1) === "/") suffix = suffix.substr(1, suffix.length);

					switch (window.location.origin) {
						case "https://www.nabes.nl":
							return "https://api.nabes.nl/" + suffix;
						case "https://www.hulpbijafwikkeling.nl":
							return "https://api.hulpbijafwikkeling.nl/" + suffix;
						default:
							return me.apiUrl + suffix;
					}
				},
				/**
				 * Function that detects if the current browser supports flash yes or no.
				 * @returns {boolean}
				 * @private
				 */
				_detectFlash: function () {
					return FlashDetect.installed;
				},
				/**
				 * Function that detects if the current browser is a mobile browser yet or no.
				 * @returns {boolean}
				 * @private
				 */
				_detectMobile: function () {
					var check = false;
					(function (a) {
						if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|android|ipad|playbook|silk|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
							a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							a.substr(0, 4))) check = true;
					})(navigator.userAgent || navigator.vendor || window.opera);
					return check;
				},
				/**
				 * Function that detects if the current browser is a phone browser yet or no.
				 * @returns {boolean}
				 * @private
				 */
				_detectPhone: function () {
					var check = false;
					(function (a) {
						if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
							a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							a.substr(0, 4))) check = true;
					})(navigator.userAgent || navigator.vendor || window.opera);
					return check;
				}
			});

			return new Config(); //we make it a singleton here.
		}
	]);
});
